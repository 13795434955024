import { Typography } from "@mui/joy"
import { ContentColumn } from "../layout/Content"
import { CancelRounded} from "@mui/icons-material"

const URLInvalidScreen = () => {
    return (
        <ContentColumn className={"w-full pt-5"} style={{maxWidth: '100vw'}}>

            <ContentColumn className="mx-auto my-4 py-6 col-12 col-md-9 md:col-9 shadow-2 border-round-md p-6 bg-white" style={{maxWidth: '70vw'}}>
                <Typography className="text-lg text-center font-bold">NOT AVAILABLE</Typography>
                <Typography className="text-bluegray-400 text-center">The provided link serial number could not be validated, or has already been used</Typography>

                <CancelRounded className="text-8xl text-red-700 mx-auto"/>
                <Typography className="text-center text-bluegray-400">Contact us for assistance: <br />
                <a className="font-bold text-bluegray-600 no-underline" href="mailto:support@reatswelelatravel.co.za">support@reatswelelatravel.co.za</a></Typography>
            </ContentColumn>
        </ContentColumn>
    )
}

export default URLInvalidScreen;