import * as React from "react";
import Table from "@mui/joy/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Input,
  Link,
  ListDivider,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useApi } from "../../utils/useApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  Cancel,
  CancelOutlined,
  Circle,
  CircleRounded,
  Close,
  Delete,
  Edit,
  EditNoteSharp,
  KeyboardArrowRight,
  MoreVert,
  MyLocation,
  Save,
  Search,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from "@mui/joy";

import FilterListIcon from "@mui/icons-material/FilterList";
import useTools from "../../utils/useTools";

import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useFormik } from "formik";
import { appIcons } from "../layout/Layout";
import PaginateComponents from "./PageComponent";
import Content, { ContentEnd, ContentStart } from "../layout/Content";
import appOptions from "../../utils/models/appOptions";
import AppDropdown from "../layout/AppDropdown";
import useExtraActions from "../../utils/models/useExtraActions";
import AppSnackBar from "../layout/AppSnackBar";

import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import TableFilter from "./TableFilter";
import AppFormControl from "../formcontrols/AppFormControl";
import { initialValues, memoValidationSchema } from "../formcontrols/EditForm";
import TableEditCreate from "./TableEditCreate";
import { BsPen, BsPencil } from "react-icons/bs";


export function formatCurrency(value, symbol){
  if(value===null||value===undefined)
  return ''

  if(value === 0)
     return '0.00'

  let [ amount, cents ] = value.split(".")

  let display = []
  amount.toString().split('').reverse().forEach((element, index) => {
      if(index % 3 === 0)
         display.push(',')
      display.push(element)
  });

  display.reverse()
  if(display[display?.length-1] === ',')
  {
     display[display?.length-1] = '.'
  }
  cents = cents?.length > 0 ? (cents?.length > 1 ? cents : `${cents}0`) : '00'
  return `${symbol ? symbol + ' ' : '' }` + display.join("") + cents
}

function getIconByName(iconsName) {
  return appIcons[iconsName] || CircleRounded;
}

const displayBoolean = (option, value) => {
  let status = value;
  let display = ["Active", "Suspended"];

  switch (option) {
    case "status":
      display = ["Active", "Inactive"];
      break;
    case "is_suspended":
      display = ["Is Active", "Is Suspended"];
      status = !value;
      break;
    case "is_revoked":
      display = ["No", "Revoked"];
      status = !value;
      break;
    case "is_superuser":
      display = ["Is super", "Not super"];
      status = value;
      break;
    default:
      display = ["Yes", "No"];
      status = value;
  }

  return (
    <div className="text-xs">
      { status ? (
        <Chip size="sm" variant="solid" color="success">
          {display[0]}
        </Chip>
      ) : (
        <Chip size="sm" variant="soft" color="neutral">
          {display[1]}
        </Chip>
      )}
    </div>
  );
};
/*
     Get Value function
*/
const anyOf = (field, ftype) => {
  if (!field) return null;
  return (
    field.type === ftype ||
    (field?.anyOf && field?.anyOf[0]?.type === ftype) ||
    (field?.anyOf && field?.anyOf[1]?.type === ftype) ||
    (field?.anyOf && field?.anyOf[0]?.format === ftype)
  );
};

const displayRowValue = (row, option, header) => {
  let value = row[option];

  if (option === "icon") {
    const CustomIcon = getIconByName(value);
    return (
      <>
        <Tooltip title={value}>
          <CustomIcon />
        </Tooltip>
      </>
    );
  }
  //console.log(header)
  if (value instanceof Array) {
    return value?.length;
  }

  if (value instanceof Object) {
    let resp = value?.full_name || value?.name || value?.label || value?.number;
    return resp
  }
  //header?.type==='boolean'
  if (header.type === "number" && header.subtype === 'currency') {
    return `${row.currency_symbol} ${formatCurrency(value)}`;
  } else if (header.type === "number" && header.subtype === 'percentage') {
    return `${formatCurrency(value)} %`;
  } 
  else if (header.type === "number") {
    return value;
  }
   else if (anyOf(header, "boolean")||header.list_type==='boolean') {
    return displayBoolean(option, value);
  } else if (anyOf(header, "date-time")) {
    return (
      <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${
        value ? moment(value).format("yyyy-MM-DD HH:mm:ss") : "Never"
      }`}</div>
    );
  } else {
    return (
      <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${
        value ? value : ""
      }`}</div>
    );
  }
};


const DisplayTableRow = ({index, selected, row, objectName, url, options, fieldList, permissions, handleChecked = f => f, onComplete = f => f, onDataChanges = f => f}) => {
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const [editMode, setEditMode] = React.useState(false)

    const [dataRow, setDataRow] = React.useState(row)

    const handleDataCompled = (data) => {
      onDataChanges(Math.random())
        if(data.is_deleted) {
            onComplete(data)
        }
        setDataRow(data)
    }
  
    return (
      editMode ?
      <TableRow className="w-full">
        <td style={{ height: 0, padding: 0 }} colSpan={fieldList.length+2}>
           <TableEditCreate 
              saveOnComplete 
              profile={dataRow}
              createMode={false}
              onComplete={handleDataCompled} 
              objectName={objectName}  
              permissions={permissions} 
              onCancel={setEditMode}
              /> 
        </td>
      </TableRow> 
      :
  <TableRow key={index} className={`font-semibold ${index % 2 === 0 ? "bg-blue-50" : "bg-white"}`}>
    <TableCell
      scope="dataRow"
      className="z-1"
      style={{ width: 20, maxWidth: 20 }}
    >
      <Checkbox
        checked={isSelected(dataRow.id)}
        onChange={(event) => handleChecked(event, dataRow.id)}
        name={dataRow?.id?.toString()}
        slotProps={{
          input: {
            "aria-labelledby": dataRow.id,
          },
        }}
        sx={{ verticalAlign: "top" }}
      />
    </TableCell>
  
    {fieldList?.map((option, index) => {
      let header = options?.properties[option];
      if(header.hidden)
          return

      let total_headings =
        Object.keys(options?.properties)?.length - 1;
  
      return (
        <React.Fragment key={index}>
          {index === 0 ? (
            <TableCell
              className="white-space-nowrap text-overflow-elipsis"
              component={'th'}
              style={{ overflowX: "hidden" }}
            >
              <ContentStart className={"align-items-center"}>
                {["vehicles", "units"].includes(objectName) && (
                  <Tooltip
                    title={`Track ${objectName.slice(0, -1)}`}
                  >
                    <Link
                      href={`/manage/track/${url}/${
                        dataRow.slug || dataRow.id
                      }/`}
                      className="text-gray-900 z-0"
                    >
                      <MyLocation className="mr-3 text-green-700" />
                    </Link>
                  </Tooltip>
                )}
                <Tooltip
                  title={`Manage ${objectName.slice(0, -1)} `}
                >
                  <Link
                    href={`/manage/${url}/${
                      dataRow.slug || dataRow.id
                    }/`}
                    className="text-gray-900 z-0"
                  >
                    {dataRow[option]}
                  </Link>
                </Tooltip>
              </ContentStart>
            </TableCell>
          ) : index === total_headings ? (
            <TableCell className="white-space-nowrap text-overflow-clip text-right">
                  {displayRowValue(dataRow, option, header)}
            </TableCell>
          ) : (
            <TableCell className="white-space-nowrap text-overflow-clip" >
                  {displayRowValue(dataRow, option, header)}
            </TableCell>
          )}
        </React.Fragment>
      );
    })}
  
    { options.related_edit === 'inline' &&  <TableCell sx={{ width: 120 }}>
      <ContentEnd className={"mr-3"}>
        <IconButton size="sm" onClick={() => setEditMode(true)}>
           <EditNoteSharp />
        </IconButton>
      </ContentEnd>
    </TableCell> 
  }
  
  </TableRow>
    )
  }

  export default DisplayTableRow;