import { Table } from "@mui/joy";
import { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { ContentBetween } from "../Content";
import WeekData from "./WeekData";



const DisplayMonth = ({ currentMonth, reminders, onDaySelect= f=>f, onItemSelect= f=>f }) => {

    const [currentDate, setCurrentDate] = useState(null)

    useEffect(() => {
        if(!currentMonth)
           return
        let {year, month, date} = currentMonth;

        setCurrentDate(moment(new Date(year, month, date)))
    },[currentMonth?.year, currentMonth?.month])

    const dayList = useMemo(() => {
      if(!currentMonth?.date)
         return

       let days = []

       let current = currentDate?.startOf('month')
       let week = 0
       let daysInMonth = currentDate?.daysInMonth()

       let day = 1;

       if(current) {
       
       do {
            days.push({
                momentDate: current.calendar(true,{ sameElse: 'YYYY-MM-DD'}),
                week: week
            })
            current = current.add(1, 'days')
            if(current.weekday()===0)
               week+=1
            
            day+=1;
       }
       while(day < (daysInMonth))

       days.push({
        momentDate: current.calendar(true,{ sameElse: 'YYYY-MM-DD'}),
        week: week
       })
       }

    //console.log(days)

       return days
    },[currentDate])

    const weekList = useMemo(() => {
        let weeks = []

        if(dayList?.length > 1)
        dayList.forEach((element, index) => {
            let week = element.week
            if(weeks.indexOf(week)===-1)
               weeks.push(week)
        })
        return weeks
    },[dayList])


    return <ContentBetween>
        <Table className="w-full" >
        <tboddy>
           { weekList.map((week, index) => <WeekData reminders={reminders} index={index} dayList={dayList.filter(w => w.week === week )} onDaySelect={onDaySelect} onItemSelect={onItemSelect} />) }
        </tboddy>
    </Table>
    </ContentBetween>
    
  }

  export default DisplayMonth;