import { Typography } from "@mui/joy";
import Content, { ContentColumn, TitleValue } from "../layout/Content"

const ReviewDataScreen = ({ profile, wizardList }) => {
    const getDisplayValue = (control, value) => {
         if(control.type==='boolean')
         {
            return `${value ? "Yes" : "No"}`
         } else {
            return value
         }
    }

    return <ContentColumn>
              {
                wizardList.map((section, index) => {
                    let row = profile[index]

                    return <ContentColumn key={index}>
                        <Typography className="text-xl font-semibold bg-bluegray-50 p-3 border-round-lg mb-4">{section.title}</Typography>
                        <Content className="row row-cols-4">
                             { section.controls.map((field, index) => <TitleValue loading={false} title={field.title} value={getDisplayValue(field, row[field.name])} key={index} />) }
                        </Content>
                    </ContentColumn>
                })
              }
    </ContentColumn>
}

export default ReviewDataScreen;