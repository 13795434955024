import { Button, Input } from "@mui/joy";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { useApi } from "../utils/useApi";
import useTools from "../utils/useTools";
import { ContentColumn, PageHeader } from "../components/layout/Content";
import CreateForm from "../components/formcontrols/CreateForm";
import GenericList from "../components/lists/GenericList";
import createOptions, { updateOptions } from "../utils/models/createOptions";
import {
  Edit,
  ImportExport,
  MoreVert,
  Settings,
  Upload,
  UploadFile,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import UploadCsvModal from "../components/layout/modals/UploadCsvModal";
import useExtraActions from "../utils/models/useExtraActions";
import AppDropdown from "../components/layout/AppDropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useAppContext } from "../utils/AppContext";
import viewRights from "../utils/models/viewRights";
import * as React from "react";
import PermissionDenied from "../components/layout/PermissionDenied";
import Loading from "../components/Loading";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const GenericListPage = ({ is_admin = false }) => {
  const params = useParams();
  const [options, setOptions] = useState(null);
  const [loadPermissions, setLoadPermissions] = useState(true);

  const [dataChange, setDataChange] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const [selected, setSelected] = useState([]);

  const { state } = useAppContext();
  const { currentUser } = state;

  const { objectName } = params;
  const api = useApi();
  const tools = useTools();

  //Working with permissions from the Database
  const [objectPermissions, setObjectPermissions] = React.useState({});

  const getObjectPermissions = React.useCallback(() => {
    setObjectPermissions({});
    setLoadPermissions(true);
    api
      .get(`modeltables/${objectName}`)
      .then((res) => {
        setObjectPermissions(res.data);
        console.log(res.data);
      })
      .catch(console.log)
      .finally(setLoadPermissions);
  }, [api, objectName]);

  React.useEffect(() => {
    getObjectPermissions();
  }, [objectName, getObjectPermissions, api]);

  const hasPermissions = React.useMemo(() => {
    //The rights here are explicit
    let permission_relations = objectPermissions.permission_relations;

    let has_permission = {
      read: false,
      create: false,
      update: false,
      remove: false,
    };

    if(currentUser)
    permission_relations?.forEach((element) => {
      if (currentUser[element.role]) {
        ["read", "create", "update", "remove"].forEach((permission) => {
          has_permission[permission] =
            has_permission[permission] || element[permission]; //Will favor true
        });
      }
    });

    return has_permission;
  }, [objectPermissions, objectName, api]);

  //End of permissions

  const buildOptions = (fields) =>{
    let props = fields.properties
    let field_list = []
    Object.keys(props).forEach((prop) => {
      field_list.push({ name: prop, ...props[prop] })
    })
    console.log(field_list.sort((a,b) => b.order > a.order))
    return [...field_list].sort((a,b) => a.order > b.order ? 1 : -1)
  }

  useEffect(() => {
    let opts = updateOptions[objectName];
    console.log("The new object is: ",objectName)
    if (!opts) {
      api
        .options(`/${objectName}/create`)
        .then((res) => {
          console.log(res)
          setOptions(buildOptions(res.data));
        })
        .catch(console.log);
    } else {
      console.log(opts)
      setOptions(opts);
    }

    return () => {
      setOptions(null);
      setExpanded(false)
    };
  }, [objectName, api]);

  // const hasRights = useMemo(() => {
  //    return viewRights(objectName, currentUser, hasPermissions)
  // },[objectName, currentUser])

  return loadPermissions ? (
    <Loading>Checking permissions...</Loading>
  ) : (
    <Fade in={!loadPermissions} mountOnEnter unmountOnExit>
      <div className="w-full">
        <PageHeader
          label={`${objectName}`}
          //startDecorator={<Button startDecorator={<BarChart />} variant="plain" color="neutral">Table Statictics</Button>}
          endDecorator={
            <div className="flex justify-content-end align-items-center">
              {hasPermissions?.create && (
                <UploadCsvModal objectName={objectName} />
              )}
              <Button
                variant="solid"
                color="primary"
                size="sm"
                onClick={() => setExpanded(true)}
                disabled={!hasPermissions?.create}
              >
                <Edit className="md:hidden block" />
                <span className="hidden md:block">
                  Create {tools.makeLabel(objectName.slice(0, -1))}
                </span>
              </Button>
            </div>
          }
        />
        <div className="w-full m-0">
          {options && (
            <CreateForm
              objectName={objectName}
              options={options}
              onComplete={setDataChange}
              expanded={expanded}
              onCollapse={setExpanded}
            />
          )}
        </div>

        <div className="w-full m-0 bg-white table-responsive">
          {hasPermissions?.read ? (
            <GenericList
              page_size={10}
              url={is_admin ? `admin/${objectName}` : objectName}
              objectName={objectName}
              dataChange={dataChange}
              onSelectedChanged={setSelected}
            />
          ) : (
            <PermissionDenied currentUser={currentUser} />
          )}
        </div>
      </div>
    </Fade>
  );
};

export default GenericListPage;
