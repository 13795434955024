import * as React from "react";
import Table from "@mui/joy/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Input,
  Link,
  ListDivider,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useApi } from "../../utils/useApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  Cancel,
  CancelOutlined,
  Circle,
  CircleRounded,
  Close,
  Delete,
  KeyboardArrowRight,
  MoreVert,
  MyLocation,
  Save,
  Search,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from "@mui/joy";

import FilterListIcon from "@mui/icons-material/FilterList";
import useTools from "../../utils/useTools";

import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useFormik } from "formik";
import { appIcons } from "../layout/Layout";
import PaginateComponents from "./PageComponent";
import Content, { ContentStart } from "../layout/Content";
import appOptions from "../../utils/models/appOptions";
import AppDropdown from "../layout/AppDropdown";
import useExtraActions from "../../utils/models/useExtraActions";
import AppSnackBar from "../layout/AppSnackBar";

import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import TableFilter from "./TableFilter";
import AppFormControl from "../formcontrols/AppFormControl";
import { initialValues, memoValidationSchema } from "../formcontrols/EditForm";

const TableEditCreate = ({ profile, parent_name, parent_id, createMode=true, objectName, permissions, saveOnComplete=false , onComplete = f => f, setProfileData = f => f, onCancel = f => f}) => {
    
    const [options, setOptions] = React.useState({ })
    const [dataObject, setDataObject] = React.useState(profile)

    const api = useApi()
    const tools = useTools()
  
    const [loading, setLoading] = React.useState(false)
  
    React.useEffect(() => {
        api.options(`${objectName}/create`)
        .then((response) => {
            setOptions(response.data)
            console.log(response)
        }).catch(console.log)
    },[objectName])

    
  
    const handleSystemLog = (data) => {
      api.post(`systemlogs/`, data).then(console.log).catch(console.log)
     } 
  

  const handleDelete = () => {
       api.delete(`${objectName}/${dataObject?.slug||dataObject?.id}`).then((response) => {
           handleSystemLog({ relation_table: `${parent_name}s`, relation_table_id: parent_id ?? 0, action_name: 'deleted related item' })
           onComplete(response.data)
           onCancel(false)
       }).catch(console.log)
  }
  
    const saveEditedData = React.useCallback((data) => {
      let save_data = { ...data, [`${parent_name}_id`] : parent_id}

      console.log(save_data)

      let method;
      if(data?.id) {
          method =  api.put(`${objectName}/${data?.slug||data?.id}`, save_data)
      }
      else {
          method =  api.post(`${objectName}/`, save_data)
      }
  
      method
      .then((response) => {
          if(dataObject?.id)
             setProfileData(response.data) //Update this only when in update mode to prevent multi create bug
          let action_name;
  
          if(dataObject?.id) {
              action_name = "updated"
          } else {
              action_name = "created"
          }
  
          handleSystemLog({ relation_table: `${parent_name}s`, relation_table_id: parent_id ?? 0, action_name: `${action_name} related item` })
            if(createMode) {
                formik.resetForm()
                onComplete(response.data)
            } else {
                handleCancel(false)
                onComplete(response.data)
            }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(setLoading)
    },[api])
  
  
  
    let fieldList = React.useMemo(() => {
        try {
            let data = [];
            let props = options?.properties
            Object.keys(props).forEach((name) => {
              data.push({ name, ...props[name] })
            })
            console.log(data)
            return data
          }  catch {
            return []
          }
    },[objectName, options])


   
    const formik = useFormik({
      initialValues: initialValues(null, fieldList, { }),
      validationSchema:  memoValidationSchema(null, tools, fieldList),
      validateOnBlur: true,
      onSubmit: (data) => {
          setLoading(true)
         //console.log(data)
          if(saveOnComplete) {
              saveEditedData(data)
          } else {
              onComplete(data)
              setLoading(false)
          }
          
      }
  }) 
  
  const handleCancel = () => {
    formik.resetForm()
    onCancel(false)
  }

  React.useEffect(() => {
    console.log(dataObject)
    if(dataObject)
       formik.setValues(dataObject)
  },[])
    
  
    return (
      <Table>
      <TableRow>
         {
            fieldList.map((control, index) => <TableCell key={index} colSpan={control.col_span} className="py-0 bg-gray-50">
                  <AppFormControl control={control} profile={dataObject} noLabel defaultSize={12} permissions={permissions} formik={formik} />
            </TableCell>)
         }
        
         <TableCell sx={{ width: 120 }} className="py-0 bg-gray-50">
             <IconButton size="sm" onClick={() => { formik.handleSubmit(); console.log(formik.values) }}><Save /></IconButton>
             <IconButton disabled={!dataObject?.id||permissions.delete} size="sm" color="danger" onClick={handleDelete}><Delete /></IconButton>
             <IconButton size="sm" onClick={handleCancel}><Close /></IconButton>
         </TableCell>
      </TableRow>
  
      </Table>
  
    )
  }

  export default TableEditCreate;