import * as React from "react";

import {
  IconButton,
  Input,
  Option,
  Select,
  Tooltip,
} from "@mui/joy";

import {
  Close,
  Search,
} from "@mui/icons-material";


import FilterListIcon from "@mui/icons-material/FilterList";
import useTools from "../../utils/useTools";

import { useFormik } from "formik";

const operators = [
  {
    label: "Equals to",
    value: "equals",
  },
  {
    label: "Starts With",
    value: "starts_with",
  },
  {
    label: "Ends With",
    value: "ends_with",
  },
  {
    label: "Contains",
    value: "contains",
  },
  {
    label: "Less than",
    value: "less_than",
  },
  {
    label: "Less than or equals",
    value: "less_than_equals",
  },
  {
    label: "Greater than",
    value: "greater_than",
  },
  {
    label: "Greater than or equals",
    value: "greater_than_equals",
  },
  {
    label: "Is Empty",
    value: "is_empty",
  },
];

const TableFilter = ({
    properties,
    noFilters,
    noSearch,
    doFilter = (f) => f,
    doSearch = (f) => f,
  }) => {
    const [search, setSearch] = React.useState("");
    const [debouncedSearch, setDebouncedSearch] = React.useState("");
    const tools = useTools();
  
    const formik = useFormik({
      initialValues: {
        filter_field: "",
        operator: "",
        value: "",
      },
      validate: (data) => {
        let errors = {};
        if (!data.filter_field) {
          errors.filter_field = "Filter field is required";
        }
        if (!data.operator) {
          errors.operator = "Operator is required";
        }
        return errors;
      },
      onSubmit: (data) => {
        //console.log(data)
        doFilter(data);
      },
    });
  
    React.useEffect(() => {
      const timeoutId = setTimeout(() => {
        doSearch(search);
        //console.log(search)
      }, 500);
  
      return () => clearTimeout(timeoutId);
    }, [search, 500]);
  
    const isFieldInvalid = (name) =>
      !!(formik.touched[name] && !!formik.errors[name]);
    const getFieldErrorMessage = (name) =>
      isFieldInvalid(name) ? (
        <small className="text-xs text-red-700">{formik.errors[name]}</small>
      ) : null;
  
    const doFormClear = () => {
      // formik.setErrors(null)
      // formik.setTouched(null)
      formik.resetForm();
      doFilter(null);
    };
  
    const anyOf = (field, ftype) => {
      if (!field) return null;
      return (
        field.type === ftype || (field?.anyOf && field?.anyOf[0]?.type === ftype)
      );
    };
  
    const checkOptions = React.useMemo(() => {
      /* This method is used as helper function to auto populate fields with predefined options line boolean */
      let options = null;
      formik.setFieldValue("value", "");
      formik.setFieldValue("operator", "");
      if (!formik.values.filter_field) {
        return null;
      }
      if (properties[formik.values.filter_field]) {
        let field = properties[formik.values.filter_field];
        //console.log(field)
        if (anyOf(field, "boolean")) {
          options = [
            { label: "True", value: true },
            { label: "False", value: false },
          ];
          formik.setFieldValue("value", true);
          formik.setFieldValue("operator", "equals");
        } else if (anyOf(field, "integer")) {
          options = "number";
          formik.setFieldValue("value", 1);
        }
      }
      return options;
    }, [formik.values["filter_field"]]);
  
    return (
      <div className="flex justify-content-between md:align-items-center border-bottom-1 border-gray-300 p-2 flex-column md:flex-row">
        {!noFilters ? (
          <form
            className="flex justify-content-start md:align-items-center flex-column md:flex-row w-full"
            onSubmit={formik.handleSubmit}
          >
            <Tooltip title={getFieldErrorMessage("filter_field")} variant="plain">
              <Select
                size="sm"
                className={
                  isFieldInvalid("filter_field")
                    ? "border-red-700 md:w-12rem md:mr-2 mb-2 md:mb-0"
                    : "md:w-12rem md:mr-2 mb-2 mb-md-0"
                }
                defaultValue={""}
                value={formik?.values?.filter_field || ""}
                name="filter_field"
                onChange={(e, new_value) =>
                  formik.setFieldValue("filter_field", new_value || "")
                }
              >
                <Option value={""}>Select field</Option>
                {Object.keys(properties).map((operator, index) => (
                  <Option value={operator} key={index}>
                    {tools.makeLabel(operator)}
                  </Option>
                ))}
              </Select>
            </Tooltip>
            <Tooltip title={getFieldErrorMessage("operator")} variant="plain">
              <Select
                size="sm"
                className={
                  isFieldInvalid("operator")
                    ? "border-red-700 md:w-12rem md:mr-2 mb-2 md:mb-0"
                    : "md:w-12rem md:mr-2 mb-2 mb-md-0"
                }
                defaultValue={""}
                value={formik.values.operator}
                name="operator"
                disabled={anyOf(
                  properties[formik?.values?.filter_field],
                  "boolean"
                )}
                onChange={(e, new_value) =>
                  formik.setFieldValue("operator", new_value)
                }
              >
                <Option value={""}>Select operator</Option>
                {operators.map((operator, index) => (
                  <Option value={operator.value} key={index}>
                    {tools.makeLabel(operator.label)}
                  </Option>
                ))}
              </Select>
            </Tooltip>
  
            <div className="flex justify-content-start align-items-center mb-2 mb-md-0">
              {!checkOptions ? (
                <Input
                  size="sm"
                  placeholder="Match value"
                  className="flex-1 md:w-12rem mr-1 mb-0"
                  value={formik.values.value}
                  name="value"
                  onChange={(e) => formik.setFieldValue("value", e.target.value)}
                />
              ) : ["number", "email", "string"].includes(checkOptions) ? (
                <Input
                  type={checkOptions}
                  size="sm"
                  placeholder=""
                  className="flex-1 md:w-12rem mr-1 mb-0"
                  value={formik.values.value}
                  name="value"
                  onChange={(e) => formik.setFieldValue("value", e.target.value)}
                />
              ) : (
                <Tooltip title={getFieldErrorMessage("operator")} variant="plain">
                  <Select
                    size="sm"
                    className={
                      isFieldInvalid("value")
                        ? "border-red-700 md:w-12rem md:mr-2 mb-2 mb-md-0"
                        : "md:w-12rem mr-md-2 mb-2 mb-md-0"
                    }
                    defaultValue={true}
                    value={formik.values.value}
                    name="operator"
                    onChange={(e, new_value) =>
                      formik.setFieldValue("value", new_value)
                    }
                  >
                    {checkOptions.map((operator, index) => (
                      <Option value={operator.value} key={index}>{operator.label}</Option>
                    ))}
                  </Select>
                </Tooltip>
              )}
  
              <Tooltip title="Apply filter">
                <IconButton
                  type="submit"
                  variant="soft"
                  size="sm"
                  className="mr-1"
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
  
              {(formik.values.filter_field ||
                formik.values.value ||
                formik.values.operator) && (
                <Tooltip title="Clear filter">
                  <IconButton
                    variant="clear"
                    size="sm"
                    onClick={() => doFormClear()}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </form>
        ) : (
          <div></div>
        )}
  
        {!noSearch && (
          <Input
            className="my-1"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="sm"
            placeholder="search..."
            startDecorator={<Search />}
            endDecorator={
              search?.length > 0 ? (
                <Close
                  fontSize="10px"
                  onClick={() => setSearch("")}
                  className="clickable"
                />
              ) : (
                <span className="text-xs">&nbsp;&nbsp;&nbsp;&nbsp;</span>
              )
            }
          />
        )}
      </div>
    );
  };
  

export default TableFilter;