import { ContactPage, FlightClass } from "@mui/icons-material";
import Person2 from "@mui/icons-material/Person2";

export const personalInfo = [
    {
        name: 'title',
        title: 'Title',
        type: 'select',
        choices: ["Mr", "Mrs", "Miss", "Ms", "Sir", "Prof", "Dr", "Hon", "Adv", "Rev", "Cllr", "Lady",],
        size: 1,
        required: true
    },
    {
        name: 'initials',
        title: 'Initials',
        type: 'text',
        size: '2',
        required: true
    },
    {
        name: 'first_name',
        title: 'Firstname',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'last_name',
        title: 'Lastname',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'capacity_in_organization',
        title: 'Capacity in organization',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'organization_name',
        title: 'Organization name',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'email',
        title: 'Email address',
        type: 'email',
        size: '3',
        required: true
    },
    {
        name: 'contact_number',
        title: 'Contact number',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'cellphone_number',
        title: 'Cellphone number',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'home_address',
        title: 'Home address',
        type: 'textarea',
        required: true,
        helptext: 'This is the address where shuttle services can collect you.',
        size: 10
     },
     {
        name: 'wheelchair_needed',
        title: 'Wheelchair needed',
        type: 'boolean',
        size: 6
     },
     {
        name: 'non_smoking',
        title: 'Non smoking',
        type: 'boolean',
        size: 6
     },
]

export const identityInformation = [
    {
        name: 'id_number',
        title: 'ID number',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'passport_number',
        title: 'Passport number',
        type: 'text',
        size: '3',
        required: true
    },
    {
        name: 'passport_issue_date',
        title: 'Password Issue Date',
        type: 'date',
        size: '3',
    },
    {
        name: 'passport_expiry_date',
        title: 'Password Expiry Date',
        type: 'date',
        size: '3',
    },
    {
        name: 'has_drivers_licence',
        title: 'Has drivers licence',
        type: 'boolean',
        size: '12',
     },
     {
        name: 'drivers_licence_number',
        title: 'Drivers licence number',
        type: 'text',
        depends: 'has_drivers_licence'
     },
     {
        name: 'issuing_authority',
        title: 'Issuing authority',
        type: 'text',
        depends: 'has_drivers_licence'
     }
   
]

export const travellerPreferences = [
    {
        name: 'preferred_airline',
        title: 'Preferred Airline',
        type: 'text',
        required: true
    },
    {
        name: 'preferred_airline_seat',
        title: 'Preferred Seat',
        type: 'select',
        choices: ['Isle','Window','Middle','Any'],
        required: true
    },
    {
        name: 'preferred_airline_seating',
        title: 'Location on plane',
        type: 'select',
        choices: ['Front','Middle','Tail','Any'],
        required: true
    },
    {
        name: 'travel_class',
        title: 'Travel Class',
        type: 'select',
        choices: ['First class','Business class','Economy','Any'],
        required: true
    },
    {
        name: 'voyager_number',
        title: 'Voyager number',
        type: 'text',
        required: true
    },
    {
        name: 'preferred_hotel',
        title: 'Preferred hotel/s',
        type: 'text',
        required: true
    },
    {
        name: 'hotel_rating',
        title: 'Hotel rating',
        type: 'select',
        choices: ['1 star','2 star','3 star','4 star','5 star','Any'],
        required: true
    },
    {
        name: 'preferred_car_rental',
        title: 'Preferred car rental',
        type: 'text',
        required: true
    },
    {
        name: 'preferred_vehicle_group',
        title: 'Preferred vehicle group',
        type: 'select',
        choices: ['Group A','Group B','Group C','Group E','Group F', 'Group H','Any'],
        required: true
        
    },
    
    {
        name: 'vehicle_transmission',
        title: 'Vehicle transmission',
        type: 'select',
        choices: ['Automatic','Manual','Any'],
        required: true
    },
    {
        name: 'agency_charge_card',
        title: 'Agency charge card number',
        type: 'text',
        required: true
    },
    {
        name: 'additional_information',
        title: 'Additional information',
        type: 'textarea',
        size: 6,
        required: true
    },
]


const travelWizard = [
     {
        title: "Personal Information",
        icon: <Person2 />,
        controls: personalInfo
     },
     {
        title: "Travel Documents",
        icon: <ContactPage />,
        controls: identityInformation
     }
     ,
     {
        title: "Traveller Preferences",
        icon: <FlightClass />,
        controls: travellerPreferences
     }
     
]

export default travelWizard;

export const importFileShape = {
    traveller_profiles : [ ...personalInfo, ...identityInformation, ...travellerPreferences ]
}