import * as React from 'react';
import { Button, Input } from "@mui/joy";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { useApi } from "../utils/useApi";
import useTools from "../utils/useTools";
import Content, { ContentColumn, PageHeader } from "../components/layout/Content";
import CreateForm from "../components/formcontrols/CreateForm";
import GenericList from "../components/lists/GenericList";
import createOptions, { updateOptions } from "../utils/models/createOptions";
import { Edit, ImportExport, MoreVert, Settings, Upload, UploadFile } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import UploadCsvModal from "../components/layout/modals/UploadCsvModal";
import useExtraActions from "../utils/models/useExtraActions";
import AppDropdown from "../components/layout/AppDropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useAppContext } from "../utils/AppContext";
import viewRights from "../utils/models/viewRights";
import PermissionDenied from "../components/layout/PermissionDenied";
import PermissionList from "../components/lists/PermissionList";
import ModelTableList from "../components/lists/ModelTableList";
import NoData from "../components/layout/NoData";
import Loading from "../components/Loading";

import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';



const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const PermissionsPage = ({ is_admin = false}) => {
    const params = useParams()
    const [options, setOptions] = useState(null)

    const [dataChange, setDataChange] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const [selectedPermission, setSelectedPermission] = useState(null)
    const [loadPermissions, setLoadPermissions] = useState(true)

    const { state } = useAppContext()
    const { currentUser } = state

    const objectName  = 'permissions'
    const api = useApi();
    const tools = useTools()

    const [serialNumber, setSerialNumber] = useState(0)

    const handleSelectPermission = (profile) => {
        setSelectedPermission(profile)
        //console.log(profile)
    }

    const handleUpdateRoles = (role) => {
        let permission_relations = selectedPermission.permission_relations.filter(r => r.table_id !== role.table_id);
        permission_relations.push(role)

        //console.log(selectedPermission)

        setSelectedPermission(prev => ({ ...prev, permission_relations }))
    }

    useEffect(() => {
        let opts = updateOptions[objectName]
       //console.log(objectName)
        if(!opts) {
            api.options(`/${objectName}/create`).then(res => {
               //console.log(res)
                setOptions(res.data)
            }).catch(console.log)
        }
        else {
           //console.log(opts)
            setOptions(opts)
        }
    
        return () => {
          setOptions(null)
        }
         
      },[objectName, api])

     //Working with permissions from the Database
     const [objectPermissions, setObjectPermissions] = React.useState({})

     const getObjectPermissions = React.useCallback(() => {
       setObjectPermissions({})
       setLoadPermissions(true)
       api.get(`modeltables/${objectName}`)
       .then(res => {
           setObjectPermissions(res.data)
           console.log(res.data)
       })
       .catch(console.log)
       .finally(setLoadPermissions)
 
   },[api, objectName])
 
   React.useEffect(() => {
       getObjectPermissions();
   },[objectName, getObjectPermissions, api])
 
   const hasPermissions = React.useMemo(() => {
       //The rights here are explicit 
       let permission_relations = objectPermissions.permission_relations;
 
       let has_permission = { read: false, create: false, update: false, remove: false }
 
       permission_relations?.forEach(element => {
           if(currentUser[element.role]) {
               ['read', 'create', 'update', 'remove'].forEach((permission) => {
                   has_permission[permission] = has_permission[permission] || element[permission] //Will favor true
               })
           }
       })
 
       return has_permission
   }, [objectPermissions, objectName, api])
 
   //End of permissions

    // const hasRights = useMemo(() => {
    //    return viewRights(objectName, currentUser)
    // },[objectName, currentUser])

     useEffect(()=>{
       //console.log(selectedPermission)
    },[selectedPermission])

    return (
      loadPermissions ? <Loading>Checking permissions...</Loading> :
        <Grow in={!loadPermissions}>

        <div className="w-full">
            <PageHeader
                  label={`${objectName}`}
                  //startDecorator={<Button startDecorator={<BarChart />} variant="plain" color="neutral">Table Statictics</Button>}
                  endDecorator={
                  <div className="flex justify-content-end align-items-center">
                  {/* <CreateObjectComponent label={objectName} /> */}
                    
                     { hasPermissions?.create && <UploadCsvModal objectName={objectName} /> }
                     <Button variant="solid" color="primary" size='sm' onClick={() => setExpanded(true)} disabled={!hasPermissions?.create}>
                        <Edit className="md:hidden block"/>
                        <span className="hidden md:block">
                           Create {tools.makeLabel(objectName.slice(0, -1))}
                        </span>
                    </Button>


                  </div>}
                  />
                  <div className="w-full m-0">
                   { options && <CreateForm objectName={objectName} options={options} onComplete={setDataChange} expanded={expanded} onCollapse={setExpanded}/> }
                  </div>

                  <div className="w-full m-0 bg-white table-responsive">
                     { hasPermissions?.update ? 
                     <Content className="row row-cols-2 m-0">

                        <Content className="col p-0 border-right-1 border-300" style={{ minHeight: '85vh'}}>
                            <PermissionList noSearch objectName={objectName} url={objectName} onSelectPermission={handleSelectPermission} selectedPermission={selectedPermission} serialNumber={serialNumber} />
                        </Content>

                        <Content className="col p-0">
                            { !selectedPermission ? <NoData message="Select role in order to view tables" /> :
                            <ModelTableList noFilters objectName={`modeltables`} url={`modeltables`} selectedPermission={selectedPermission} onPermissionChange={handleUpdateRoles} onDataBaseCommit={() => setSerialNumber(serialNumber+(Math.random()))} /> 
                            }
                        </Content>
                     </Content>
                     :
                     <PermissionDenied currentUser={currentUser} /> }
                  </div>

              

</div>
</Grow>


    )
  };
  
export default PermissionsPage;