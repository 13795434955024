import { Badge, Chip, Skeleton, Typography } from "@mui/joy"
import useTools from "../../utils/useTools"
import { Album, AlbumOutlined, CircleOutlined } from "@mui/icons-material"

export const ContentColumn = ({children, className,...props}) => 
{
    return <div className={`flex flex-column ${className}`} {...props} >{children}</div>
}

export const ContentBetween = ({children,className, ...props}) => 
{
    return <div className={`flex justify-content-between ${className}`} {...props} >{children}</div>
}

export const ContentAround = ({children,className, ...props}) => 
{
    return <div className={`flex justify-content-around ${className}`} {...props} >{children}</div>
}

export const ContentStart = ({children,className, ...props}) => 
{
    return <div className={`flex justify-content-start ${className}`} {...props} >{children}</div>
}

export const ContentEnd = ({children, className, ...props}) => 
{
    return <div className={`flex justify-content-end ${className}`} {...props} >{children}</div>
}

export const ContentCenter = ({children, className, ...props}) => 
{
    return <div className={`flex justify-content-center ${className}`} {...props} >{children}</div>
}

export const HeaderContent = ({header, subheading, className, darkMode, endDecorator}) => {
    return (
        <ContentColumn className={className}>
            <ContentBetween className={"align-items-center"}>
            <Content>
            <Typography className={`text-lg font-semibold ${darkMode ? 'text-white' : ''}`}>{header}</Typography>
            { subheading && <Typography className={`text-sm ${darkMode ? 'text-white' : 'text-gluegray-600'}`}>{subheading}</Typography> }
            </Content>
            {endDecorator}
            </ContentBetween>
        </ContentColumn>
    )
}

export const FieldDisplay = ({fieldName, value, className}) => {
    return (
        <ContentColumn className={className}>
            <Typography className="text-sm font-bold">{fieldName}</Typography>
            { value && <Typography className="text-sm text-gray-700">{value}</Typography> }
        </ContentColumn>
    )
}

export  const TitleValue = ({title, value, loading}) => (
    <ContentColumn className="mb-3">
            <Typography className="text-sm">
                  <Skeleton loading={loading}>{title||"Untitled"}</Skeleton>
            </Typography>
            <Typography className="text-lg font-semibold">
                 <Skeleton loading={loading}>{value||"Value not set"}</Skeleton>
            </Typography>
    </ContentColumn>
)

export const RowValuePair = ({className, loading, labeSize="text-body", valueSize="text-body", value, label, vsx, lsx }) => (
    <ContentBetween className={className}>
            <Typography className={labeSize} sx={lsx}>
                <Skeleton loading={loading}>
                    {label||"Untitled"}
                </Skeleton>
            </Typography>
            <Typography className={`${valueSize} font-semibold text-right`} sx={vsx}>
                <Skeleton loading={loading}>
                    {value||"Value not set"}
                </Skeleton>
            </Typography>
    </ContentBetween>
)

export const ColumnValuePair = ({className, loading, labeSize="text-body", valueSize="text-body", value, label, badge }) => (
    <ContentColumn className={className}>
               <Typography className={labeSize}>
                 <Skeleton loading={loading}>
                    {label||"Untitled"}
                 </Skeleton>
               </Typography>
        
            <ContentStart className={"flex align-items-center"}>
                { badge && <Album fontSize="6px" className={`text-${badge}-600 mr-1`} /> }
                <Typography className={`${valueSize} font-semibold`}>
                    <Skeleton loading={loading}>
                            {value||"Value not set"}
                    </Skeleton>
                </Typography>
            </ContentStart>
           
    </ContentColumn>
)

export const PageHeader = ({label, subtitle, startDecorator, endDecorator, uppercase, darkMode }) => {
    const { camelCase, makeLabel } = useTools()
    return <ContentStart className={"bg-white py-2 border-bottom-1 border-gray-300 align-items-center w-full" }>
              <Content className="px-3">
              <HeaderContent header={uppercase ? makeLabel(label).toUpperCase() : makeLabel(label)} subheading={subtitle} darkMode={darkMode}/>
              </Content>
              <div className="flex flex-1 justify-content-between align-items-center md:mb-0 mx-3">
                <>{startDecorator||<div></div>}</>
                <>{endDecorator}</>
              </div>
        </ContentStart>

};

export const Spacer = ({ className }) => {
    return <div className={className}></div>
}

export default function Content({children, ...props})
{
    return <div {...props} >{children}</div>
}