const workNotesList = {
    properties: {
        relation_table : { title: "Table name", type: "relation_table" }, 
        id : { title: "Email", type: "id" }, 
        is_deleted : { title: "Is Deleted", type: "is_deleted" }, 
        slug : { title: "Slug", type: "slug" }, 
        created_at : { title: "Email", type: "created_at" }, 
        work_notes : { title: "Worknotes", type: "work_notes" },
    }
}
const inviteList = {
    properties: {
        email: { title: "Email", type: "email" },
        title: { title: "Title", type: "text", width: 50 },
        first_name: { title: "First name", type: "text" },
        last_name: { title: "Last name", type: "text" },
        is_used: { title: "Is used", type: "boolean", hidden: true },
        created_at: { title: "Created at", type: "datetime" },
        updated_at: { title: "Updated at", type: "datetime" },
    }
}

const travellerList = {
    properties: {
        number: { title: "Number", type: "text" },
        name: { title: "Name", type: "text" },
        first_name: { title: "First name", type: "text" },
        last_name: { title: "Last name", type: "text" },
        email: { title: "Email address", type: "email" },
        cellphone_number: { title: "Cellphone number", type: "text" },
        contact_number: { title: "Phone number", type: "text" },
    },
    related_models: ['traveller_preferences']
}

const retalsList = {
    properties: {
        agency_name: { title: "Agency name", type: "text" },
        requested_by: { title: "Requested by", type: "text" },
        owner: { title: "Owner", type: "text" },
        collect_date: { title: "Collect time", type: "datetime" },
        return_date: { title: "Returm time", type: "datetime" },
        vehicle_group: { title: "Vehicle Group", type: "text" },
    }
}

const preferences = {
    properties: {
        preferred_car_rental: { title: "Car rental", type: "text" },
        preferred_vehicle_group: { title: "Vehicle Group", type: "text" },
        vehicle_transmission: { title: "Vehicle Transmission", type: "text" },
        preferred_airline: { title: "preferred Airline", type: "text" },
        travel_class: { title: "Airline Class", type: "text" },
        preferred_airline_seat: { title: "preferred Seat", type: "text" },
        preferred_airline_seating: { title: "Airline Seat Area", type: "text" },
        preferred_hotel:  { title: "Preferred hotel", type: "text" },
        hotel_rating: { title: "Hotel rating", type: "text" },
    },
}

const menuItemList = {
    properties: {
        name: { title: "Name", type: "text" },
        path: { title: "Path", type: "text" },
        manages: { title: "Manages", type: "text" },
        order: { title: "Order", type: "number" },
        icon: { title: "Icon", type: "text" },
        divider: { title: "Is divider", type: "boolean" },
    }
}

const tenants = {
    properties: {
        name: { title: "Name", type: "text" },
        number: { title: "Account number", type: "text" },
        address: { title: "Address", type: "text" },
        registration_number: { title: "Order", type: "text" },
        vat_registered: { title: "Vat registered", type: "boolean" },
        vat_number: { title: "Vat number", type: "text" },
        currency: { title: "Currency", type: "text" },
    },
    related_models: ['customers','invoices','quotations','credit_notes','payments']

}

const invoice_items = {
    related_edit : "inline",
    properties: {
        "quantity": {
            "title": "Quantity",
            "type": "number"
        },
        "item": {
            "title": "Item",
            "type": "string"
        },
        "description": {
            "col_span": 3,
            "title": "Description",
            "type": "text"
        },
        "unit_price": {
            "default": 0,
            "order": 1,
            "size": 3,
            "step": [
                0.01
            ],
            "subtype": "currency",
            "title": "Unit Price",
            "type": "number"
        },
        "discount": {
            "default": 0,
            "order": 1,
            "size": 3,
            "step": [
                0.01
            ],
            "subtype": "percentage",
            "title": "% Discount",
            "type": "number"
        },
        "vat_amount": {
            "default": 0,
            "order": 1,
            "size": 3,
            "step": [
                0.01
            ],
            "subtype": "currency",
            "title": "Vat Amount",
            "type": "number"
        },
        "subtotal": {
            "default": 0,
            "order": 1,
            "size": 3,
            "step": [
                0.01
            ],
            "subtype": "currency",
            "title": "Subtotal",
            "type": "number"
        },
       
        
    }
}


const appOptions = {
    traveller_invites : inviteList,
    traveller_profiles: travellerList,
    traveller_preferences: preferences,
    worknotes: workNotesList,
    rentals: retalsList,
    menu_items: menuItemList,
    tenants,
    invoice_items,
    quotation_items: invoice_items,
}

export default appOptions;
