import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, LinearProgress, Option, Select, Stack, Textarea } from "@mui/joy";
import Content, { ContentBetween, ContentColumn, ContentEnd } from "../../components/layout/Content";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../../utils/useApi";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useTools from "../../utils/useTools";
import AssignSettingWidget from "./AssignSettingWidget";
import moment from "moment";
import AppSnackBar from "../layout/AppSnackBar";
import PasswordMeterInput from "./PasswordField";
import CreateWorknote from "../layout/modals/CreateWorknote";

import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AppDateField, AppDateTimeField } from "./JoyDatePicker";
import AppAutocomplete from "./AppAutocomplete";
import SlushBucket from "./SlushBucket";


const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss'
const dateFormat = 'YYYY-MM-DD'

const func = f => f


const AppFormControl = ({ control, objectName, profile, permissions, formik, defaultSize=4, ...props }) => {

            const formatDate = (value, control) => {
                if(!value)
                    return ''
                if(control.type == 'date')
                {
                    if(value)
                    return moment(value).format('YYYY-MM-DD')
                }
                if(!value)
                    return moment(value.split(".")[0]).format('YYYY-MM-DD hh:mm:ss')
            }


            const no_permissions = useMemo(() => {
                    //console.log(permissions)
                return profile?.id ? !permissions?.update : !permissions?.create
            },[profile, permissions])
     
            let visual_error 
            if(['related','autocomplete'].includes(control.type))
            {
                visual_error = formik?.errors && formik?.errors[`${control?.name}_id`] ? " border-red-600 " : ""
            } else {
                visual_error = formik?.errors && formik?.errors[control?.name] ? " border-red-600 " : ""
            }

            let read_only = control.read_only || no_permissions


            return (
         <Fragment >
          { control?.new_line && <Content className={`col-${control?.new_line} my-0 py-0`}></Content>}

          
           { ['string','text','email'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <Input size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formik?.values[control?.name]||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['number', ].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <Input 
                    size="sm" 
                    sx={{ minWidth: 120 }}
                    startDecorator={ control.currency ? props.currency : null }
                    disabled={read_only} 
                    className={visual_error} 
                    name={control?.name} 
                    type={control?.type} 
                    placeholder={control?.title} 
                    value={formik?.values[control?.name]===undefined||formik?.values[control?.name]===null ? '' : formik?.values[control?.name]} 
                    onChange={formik?.handleChange}
                    slotProps={{
                        input: {
                          min: control.min,
                          step: control.step
                        },
                      }}
                    />
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['password'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <PasswordMeterInput size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formik?.values[control?.name]||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['date'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <Input size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formatDate(formik?.values[control?.name], control)||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['datetime'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                    <AppDateTimeField views={['year', 'day', 'hours', 'minutes']} size="sm" disabled={read_only} className={"w-full " + visual_error} value={formatDate(formik?.values[control?.name], control)||''} name={control?.name} type={control?.type} placeholder={control?.title} onChange={(e) => { console.log(e.$d); formik?.setFieldValue(control?.name, moment(e.$d).format(dateTimeFormat) )}} />
                    {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

        {
         ['related','autocomplete'].includes(control.type) &&
         <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}>
             { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
             <AppAutocomplete
                 getOptionLabel={option => option.name||option}  
                 type={'text'} 
                 size="sm"
                 field={control}
                 className={visual_error} 
                 placeholder={control.title||control.name} 
                 name={control.name} 
                 onChange={(e,v) =>  { 
                    formik?.setFieldValue(control.name, v);
                    if(v instanceof Object) {
                        formik?.setFieldValue(`${control.name}_id`, v?.id);
                        formik?.setFieldValue(`${control.name}_email`, v?.email);
                    }
                    console.log(formik?.values)
                }} 
                 value={formik?.values[control?.name]}
                 disabled={read_only}
             />
             {formik?.errors[`${control?.name}_id`] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[`${control?.name}_id`]}</small>) : null}
          </FormControl>
       }
 
 
 
 
      {
         ['multiselect'].includes(control.type) && 
         <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}>

         <SlushBucket 
         onChange={(v) => formik?.setFieldValue(control.name, v)} 
         field={control} 
         profile={profile} 
         objectName={objectName} 
         value={formik?.values[control?.name]||[]}
         />
         </FormControl>

 
       }


        { ['textarea',].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <Textarea
                size="sm"
                    className={visual_error}
                    name={control?.name}
                    disabled={read_only}
                    placeholder={control?.title}
                    required={control?.required}
                    value={formik?.values[control?.name]||control?.default||''} 
                    onChange={formik?.handleChange}
                    minRows={3}
                    maxRows={5}
                />
                { control?.helptext && <FormHelperText>{control?.helptext}</FormHelperText> }
               {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> }

           

            { ['select'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`} >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                <Select
                       size="sm"
                       className={visual_error}
                        onChange={(e, newValue) => formik?.setFieldValue(control?.name, newValue)}
                        value={formik?.values[control?.name]!=null ? formik?.values[control?.name] : control?.default}
                        placeholder={control?.title}
                        name={control?.name}
                        required={control?.required}
                        disabled={read_only}
                        sx={{ minWidth: 50 }}
                        >
                       { control?.choices.map((option,  key) => <Option  key={key} value={ option.value==null ? option : option.value }>{option.label == null ? option : option.label }</Option>) } 
                    
                </Select>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> }

            { ['boolean'].includes(control?.type) && <FormControl className={`mb-0 pt-1 col-12 col-md-${control?.size||defaultSize} md:col-${control?.size||defaultSize}`}>
               <FormLabel className="font-semibold">&nbsp;</FormLabel>
               <Checkbox label={control?.title} checked={formik?.values[control?.name]||false} onChange={(e) => formik?.setFieldValue(control?.name, e.target.checked )} />
            </FormControl> }

            </Fragment> )

}

export default AppFormControl;