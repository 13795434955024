import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogContent from '@mui/joy/DialogContent';
import webdesk_logo from '../../../assets/logo.png';
import { Typography } from '@mui/joy';
import moment from 'moment';
import { useFormik } from "formik";
import useTools from '../../../utils/useTools';
import { ContentCenter, ContentColumn } from '../Content';

const otp_fields = [0,1,2,3,4,5]

export default function OTPConfirmationModal({ 
    otp, 
    title="Verify your Webdesk OTP",
    subtitle="We have sent an otp to your cellphone number, please verify.",
    original_request=null,
    test_mode=false,
    onCompleted = f => f, 
    onExpired = f => f
}) {

  const [layout, setLayout] = React.useState(undefined);
  const [expires, setExpires] = React.useState(null)
  const [nowTime, setNowTime] = React.useState(null)
  const [iotp, setIotp] = React.useState(null)

  const [verifying, setVerifying] = React.useState(false)
  const [requesting, setRequesting] = React.useState(false)

  const fieldRef = React.useRef()
  const tools = useTools()

  React.useEffect(() => {
       setIotp(otp)
       if(otp) {
          setLayout('fullscreen')
       }
  },[otp])

  React.useEffect(() => {
    //console.log(iotp)
    if(iotp?.id)
      setExpires((moment(iotp?.expires).add(2, "hours")))
      //The server sends utc timestamp
  },[iotp])

  React.useEffect(() => {
    if(!expires)
    return //Don't run if the OTP data is incomplete
  
       fieldRef.current = 0
       focusNext(0) //We focus the first input

      let now = moment()
      let interval;

      if((expires?.diff(now, 'seconds') > 0))  //Don't run if already expired
      {
       interval = setInterval(() => {
         console.log(moment(expires).isAfter(nowTime))
         let now = moment()

         if((expires?.diff(now, 'seconds') > 0))
             setNowTime(now)
         else
             setNowTime(expires)
      },1000)
     }

      return () => {
        clearInterval(interval)
      }

  },[expires])

  const requestNewOTP = () => {
    //We request a new OTP only if the original url is supplied
    if(!original_request)
        return
    tools.dataFromUrl(original_request, setRequesting)
    .then((data) => {
        setIotp(data);
        setExpires(moment(data?.expires).add(2, "hours"))
    })
    .catch(console.log)
  }

  const formik = useFormik({
        initialValues: {
            field_0: '',
            field_1: '',
            field_2: '',
            field_3: '',
            field_4: '',
            field_5: '',
            verification_failed: false
        },
        validate: (data) => {
            let errors = {};
            if(!(data.field_0||data.field_1||data.field_2||data.field_3||data.field_4||data.field_5))
                errors.fields = "The OTP is incomplete"
            return errors
        },
        onSubmit: (data) => {
            doConfirm(data)
        }
  })

  const performConfirmedActionAndClose = () => {
    tools.postToUrl(`otp_routes/${iotp.id}/action/confirmed`, { id: iotp.id }, setVerifying)
    .then((data) => {
        if (data.completed == 200)
        {
            setIotp(null)
            setExpires(null)
            onCompleted(true)
            formik.setFieldValue('verification_failed', false)
        } else {
            formik.setFieldValue('verification_failed', true) //We will check this when verification fails
        }
    }).catch((e) => { 
        console.log(e)
        formik.setFieldValue('verification_failed', true)
    })
    
  }


  const doConfirm = (data) => {
    let authorized_otp ='';
    otp_fields.forEach((f) => {
        authorized_otp += data[`field_${f}`].toString()
        //Create OTP like 123456
    })

    tools.postToUrl(`otp_routes/${iotp.id}/confirm`, { authorized_otp, id: iotp.id }, setVerifying) 
    .then((data) => {
        if (data.confirmed == 200)
        {
           performConfirmedActionAndClose()
        } else {
            formik.setFieldValue('verification_failed', true) //We will check this when verification fails
        }
    }).catch((e) => { 
        console.log(e)
        formik.setFieldValue('verification_failed', true)
    })
  }

  let when_open = React.useMemo(() => {
    //This value renders the counter as str
        let seconds = expires ? expires?.diff(nowTime, 'seconds') : 0
        return moment("2024-01-01").startOf('day').seconds(seconds).format('mm:ss')
  },[nowTime])

  let token_expired = React.useMemo(() => {
    //Checks if token has expired
    return expires ? !(expires?.diff(nowTime, 'seconds') > 0) : true
   },[nowTime])

   let post_disabled = React.useMemo(() => {
    //To disable submit button when any field is empty or token has expired
            let empty = true;
            otp_fields.forEach((f) => {
               empty = empty && !!formik.values[`field_${f}`]
                //Create OTP like 123456
            })
          return !empty || token_expired // Remove ! from token_expired after test
   },[formik.values, token_expired])

    const focusNext = (current) => {
        let nextField = document.querySelector(`input[name="field_${current}"]`)
        if(nextField!=null)
        {
            nextField.focus()
        }
    }

   const handleChange = (e) => {
    const { maxLength, value, name } = e.target;

    formik.setFieldValue(name, value)

    if(value.length >= maxLength) {
        let current = fieldRef.current;
        if(current == otp_fields.length)
        { 
            current = 0;
        } else {
            current +=1
        }
        focusNext(current)
        fieldRef.current = current
    }

   }
   if(!iotp?.expires && !test_mode)
   return

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1}>
       { test_mode &&  <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            setLayout('fullscreen');
            setIotp({is_used: false, id: 15, expires: '2024-01-19 18:14:02.418554'})
          }}
        >
          Test modal
        </Button> }
      </Stack>
      <Modal open={!!layout} onClose={() => setLayout(undefined)}>
        <ModalDialog layout={layout}>
          <ModalClose />
          <DialogContent>
                <ContentColumn className={"justify-content-center align-items-center"} style={{ height: '70vh'}}>
                          <img src={webdesk_logo} alt='Webdesk Logo' className='w-12rem'/>
                          <Typography className="mt-3 text-2xl font-semibold">{title}</Typography>
                          <Typography className="text-gray-600">{subtitle}</Typography>

                         { original_request && <Typography className="text-gray-600 my-3 text-center">Did not receive token?  <Button onClick={() => requestNewOTP()}  variant='plain' size='sm' className='font-bold'>{`Request new token`}</Button></Typography> }
                        <form onSubmit={formik.handleSubmit} className='flex flex-column justify-content-center'>

                          <ContentCenter className={"my-5"}>
                            { otp_fields.map((field, index) => 
                               <input 
                                    name={`field_${field}`} 
                                    autoComplete={false}
                                    onChange={handleChange} 
                                    autoFocus={field==fieldRef?.current} 
                                    style={{ width: 10 }} 
                                    maxLength={1} 
                                    className='w-2rem py-2 text-xl mx-1 text-center border-1 border-300 border-round-md' 
                                    key={index}
                                />
                            )}
                          </ContentCenter>
                          <ContentCenter>
                              <Button type='submit' disabled={post_disabled} loading={verifying}>Verify & Continue</Button>
                          </ContentCenter>
                        </form>

                          <Typography className="text-gray-600 my-3 text-center">The OTP we have sent will expire in:  <span className='font-bold'>{`${when_open}`}</span></Typography>
                          { token_expired && <Typography className="text-sm text-red-600">The OTP has now expired, please request a new one.</Typography> }
                          { formik?.values?.verification_failed && <Typography className="text-sm text-red-600">OTP verification failed!!!</Typography> }
                </ContentColumn>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}