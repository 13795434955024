import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContext from './utils/AppContext';

import Layout from './components/layout/Layout';
import Dashboard from './pages/Dashboard';
import WizardPage from './pages/WizardPage';
import LoginPage from './pages/LoginPage';
import Public from './components/layout/Public';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PasswordResetPage from './pages/PasswordResetPage';
import NotFoundPage from './pages/NotFoundPage';
import GenericListPage from './pages/GenericListPage';
import EditPage from './pages/EditPage';
import RemindersPage from './pages/RemindersPage';
import PermissionsPage from './pages/PermissionsPage';
import PreviewPdf from './pages/PreviewPdf';

function App() {
  return (
    <AppContext>
      <BrowserRouter>
          <Routes>
              <Route path='/' element={<Public />}>
                  <Route path='login' element={<LoginPage />} />
                  <Route path='forgot-password' element={<ForgotPasswordPage />} />
                  <Route path='reset-password/:validToken' element={<PasswordResetPage />} />

                  <Route path='wizard/:wizardName/:wizardId' element={<WizardPage />} />
              </Route>
              <Route path='/' element={<Layout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="dashboard" element={<Dashboard />} />

                  <Route path='manage/reminders' element={<RemindersPage />} />
                  <Route path='manage/permissions' element={<PermissionsPage />} />
                  <Route path='manage/:objectName' element={<GenericListPage />} />

                  <Route path='manage/:objectName/:objectSlug' element={<EditPage />} />
                  <Route path='manage/:objectName/:objectSlug/preview' element={<PreviewPdf />} />
              </Route>

              <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
    </AppContext>

  );
}

export default App;

