import * as React from 'react';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import useTools from '../../../utils/useTools';
import Papa from "papaparse";
import { Upload } from '@mui/icons-material';
import { Typography } from '@mui/joy';
import { importFileShape } from '../../../utils/models/travelWizard';
import Content, { ContentStart } from '../Content';
import { useApi } from '../../../utils/useApi';
import AppSnackBar from '../AppSnackBar';

export default function UploadCsvModal({ test, fieldList, fileData, objectName,  show}) {
  const [layout, setLayout] = React.useState(show);
  const [rows, setRows] = React.useState([])

  const [fieldsInfile, setFieldsInFile] = React.useState()

  const uploadRef = React.useRef()

  const headers = importFileShape[objectName]


  React.useEffect(() => {
    setLayout(show)
  },[show])

  const handleFileUpload = (e) => {
    uploadRef.current = e.target.files[0];
   //console.log(file.type)
   //console.log(file.size)
   Papa.parse(uploadRef.current, {
       header: true,
       skipEmptyLines: true,
       complete: (results) => {
           console.log(results)
           setRows(results)
       },
       error: (g) => console.log(g)
   })
   setLayout('fullscreen')

 }

  const tools = useTools()
  const api = useApi()


  const doDataImport = () => {
      api.post(`${objectName}/data-import/`, rows?.data)
      .then(response => {
        showToast(response.data.message)
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        showToast(error.message, "danger")
      })
  }

  const [triggerToast, setTriggerToast] = React.useState({
    message: '',
    counter: 0,
    color: 'success'
})


const showToast = (message, type='primary') => {
    setTriggerToast(prev => ({ message: message, counter: prev.counter+1, color: type }))
}

const hideToast = () => {
    setTriggerToast(prev => ({ message: null, counter: 0 }))
}


  return (
    <React.Fragment>
         { triggerToast?.message && <AppSnackBar
                   openCount={triggerToast.counter} 
                   message={triggerToast.message} 
                   type={triggerToast.color} 
                   onClose={hideToast}
         /> }

      <Stack direction="row" spacing={1} className="align-items-center">
      <Button 
        color="neutral" 
        variant="outlined"
        size="sm" 
        startDecorator={<Upload className="md:hidden block"/>} 
        className="mr-2" 
        role={undefined} 
        tabIndex={-1}
        component="label">
        <span className="hidden md:block">
            Upload CSV
        </span>
        <input ref={uploadRef} className="hidden" type="file" id="csv" onChange={handleFileUpload} accept="text/csv"/>
      </Button>
                     
        <Button
          className={ test ? null : 'hidden' }
          variant="outlined"
          color="neutral"
          onClick={() => {
            setLayout('fullscreen');
          }}
        >
          Full screen
        </Button>
      </Stack>
      <Modal open={!!layout} onClose={() => setLayout(undefined)}>
        <ModalDialog layout={layout}>
          <ModalClose />
          <DialogTitle>Import {tools.makeLabel(objectName)}</DialogTitle>
          <DialogContent>
            <Typography>This is the expected object shape for {objectName} uploads</Typography>
            <ContentStart>
               <Button size='sm' onClick={doDataImport}>Import data</Button>
            </ContentStart>
            <Content className="oveflow-scroll">
            <table className='w-full border-1 p-0' style={{ borderCollapse: 'collapse' }}>
                <tr>
                   {headers?.map((header, index) => <th key={index} className={rows?.meta?.fields.includes(header.name) ? 'bg-green-100 border-1 m-0 px-2':'bg-red-100 border-1 m-0 px-2'}>{header.name}</th> )}
                </tr>
                <tbody>
                    { rows?.data?.map((row, index) => 
                        <tr key={index} >
                          {headers?.map((column, index) => <td key={index} className='bg-green-50 border-1 m-0 px-2 white-space-nowrap overflow-hidden text-overflow-ellipsis'>{row[column.name]}</td>)}
                    </tr>
                    )}
                </tbody>
            </table>
            </Content>

          </DialogContent>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}