import { Typography } from '@mui/joy';
import image from '../../assets/no_data.png';
import Content, { ContentColumn } from './Content';

const NoData = ({ objectName='page', message }) => {
    return <ContentColumn className={"w-full justify-content-center align-items-center py-6"}>
        <img src={image} alt='No Data image' className='h-10rem'/>
        <Typography className="text-lg font-semibold">{ message ? message : `No ${objectName} data found` }</Typography>
    </ContentColumn>
}

export default NoData;