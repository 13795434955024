import { IconButton, Typography } from "@mui/joy";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ContentBetween, ContentEnd } from "../Content";


const MonthPaginate = ( { currentDate, handleChangePage = f => f }) => {

    const months = ['January','February','March','April','May','June','July','August','September','October','November','December']

    return <ContentBetween className={"p-2 border-bottom-1 border-300"}>
        <Typography className="font-bold">
          {months[currentDate?.month]} {currentDate?.year}
        </Typography>
        <ContentEnd className={"align-items-center"}>
              <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    className={"mx-1"}
                    onClick={() => handleChangePage(-1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    className={"mx-1"}
                    onClick={() => handleChangePage(1)}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
        </ContentEnd>
    </ContentBetween>
  }


  export default MonthPaginate;