import * as React from 'react';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import EditForm from './EditForm';
import useTools from '../../utils/useTools';
import { ContentBetween, HeaderContent } from '../layout/Content';
import { useApi } from '../../utils/useApi';
import { useAppContext } from '../../utils/AppContext';

export default function CreateForm({ showCreate, objectName, profile, options, onComplete = f => f, expanded , onCollapse = f => f}) {
  const [index, setIndex] = React.useState(-1);
  const tools = useTools();
  const api = useApi()

  const { state } = useAppContext()
  const { currentUser } = state

  //Working with permissions from the Database
  const [objectPermissions, setObjectPermissions] = React.useState({})

  const getObjectPermissions = React.useCallback(() => {
    api.get(`modeltables/${objectName}`)
    .then(res => {
        setObjectPermissions(res.data)
        console.log(res.data)
    })
    .catch(console.log)

},[api, objectName])

React.useEffect(() => {
    getObjectPermissions();
},[])

const hasPermissions = React.useMemo(() => {
    //The rights here are explicit 
    let permission_relations = objectPermissions.permission_relations;

    let has_permission = { read: false, create: false, update: false, remove: false }

    permission_relations?.forEach(element => {
        if(currentUser[element.role]) {
            ['read', 'create', 'update', 'remove'].forEach((permission) => {
                 has_permission[permission] = has_permission[permission] || element[permission] //Will favor true
            })
        }
    })

    return has_permission
}, [objectPermissions?.id, objectName])

//End of permissions
  return (
   expanded && <AccordionGroup>
      <Accordion
        expanded={expanded}
        onChange={(event, expandIndex) => {
          setIndex(expandIndex ? -1 : null);
        }}
      >
        <AccordionDetails className="bg-white mb-0 p-3 border-bottom-1 border-300">
            <ContentBetween className={"col-md-12 "}>
                <HeaderContent className={"p-2 block"} header={`Create ${tools.titleCase(objectName).slice(0,-1)}` } subheading={`The form displayed below must be filled to create new ${tools.titleCase(objectName).slice(0,-1)}.`}/>
            </ContentBetween> 

          { options &&  expanded && <EditForm createMode={true} profile={profile||{}} objectName={objectName} options={options}  rowCols={4} display="flex-column" onComplete={onComplete} onCancel={onCollapse} permissions={hasPermissions} /> }
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
}