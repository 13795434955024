import * as React from "react";
import Table from "@mui/joy/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Input,
  Link,
  ListDivider,
  Option,
  Select,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useApi } from "../../utils/useApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  Cancel,
  CancelOutlined,
  Circle,
  CircleRounded,
  Close,
  Delete,
  Edit,
  KeyboardArrowRight,
  MoreVert,
  MyLocation,
  Save,
  Search,
  SellRounded,
} from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from "@mui/joy";

import FilterListIcon from "@mui/icons-material/FilterList";
import useTools from "../../utils/useTools";

import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useFormik } from "formik";
import { appIcons } from "../layout/Layout";
import PaginateComponents from "./PageComponent";
import Content, { ContentEnd, ContentStart } from "../layout/Content";
import appOptions from "../../utils/models/appOptions";
import AppDropdown from "../layout/AppDropdown";
import useExtraActions from "../../utils/models/useExtraActions";
import AppSnackBar from "../layout/AppSnackBar";

import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import TableFilter from "./TableFilter";
import AppFormControl from "../formcontrols/AppFormControl";
import { initialValues, memoValidationSchema } from "../formcontrols/EditForm";
import TableEditCreate from "./TableEditCreate";
import { BsPen, BsPencil } from "react-icons/bs";
import DisplayTableRow from "./DisplayTableRow";


const operators = [
  {
    label: "Equals to",
    value: "equals",
  },
  {
    label: "Starts With",
    value: "starts_with",
  },
  {
    label: "Ends With",
    value: "ends_with",
  },
  {
    label: "Contains",
    value: "contains",
  },
  {
    label: "Less than",
    value: "less_than",
  },
  {
    label: "Less than or equals",
    value: "less_than_equals",
  },
  {
    label: "Greater than",
    value: "greater_than",
  },
  {
    label: "Greater than or equals",
    value: "greater_than_equals",
  },
  {
    label: "Is Empty",
    value: "is_empty",
  },
];

export function formatCurrency(value, symbol){
  if(value === 0)
     return '0.00'

  let [ amount, cents ] =  value?.toString()?.split(".") || ["0","0"]

  let display = []
  amount.toString().split('').reverse().forEach((element, index) => {
      if(index % 3 === 0)
         display.push(',')
      display.push(element)
  });

  display.reverse()
  if(display[display.length-1] === ',')
  {
     display[display.length-1] = '.'
  }
  cents = cents?.length > 0 ? (cents?.length > 1 ? cents : `${cents}0`) : '00'
  return `${symbol ? symbol + ' ' :''}` + display.join("") + cents
}

function getIconByName(iconsName) {
  return appIcons[iconsName] || CircleRounded;
}

const displayBoolean = (option, value) => {
  let status = value;
  let display = ["Active", "Suspended"];

  switch (option) {
    case "status":
      display = ["Active", "Inactive"];
      break;
    case "is_suspended":
      display = ["Is Active", "Is Suspended"];
      status = !value;
      break;
    case "is_revoked":
      display = ["No", "Revoked"];
      status = !value;
      break;
    case "is_superuser":
      display = ["Is super", "Not super"];
      status = value;
      break;
    default:
      display = ["Yes", "No"];
      status = value;
  }

  return (
    <div className="text-xs">
      { status ? (
        <Chip size="sm" variant="solid" color="success">
          {display[0]}
        </Chip>
      ) : (
        <Chip size="sm" variant="soft" color="neutral">
          {display[1]}
        </Chip>
      )}
    </div>
  );
};
/*
     Get Value function
*/
const anyOf = (field, ftype) => {
  if (!field) return null;
  return (
    field.type === ftype ||
    (field?.anyOf && field?.anyOf[0]?.type === ftype) ||
    (field?.anyOf && field?.anyOf[1]?.type === ftype) ||
    (field?.anyOf && field?.anyOf[0]?.format === ftype)
  );
};

const displayRowValue = (row, option, header) => {
  let value = row[option];

  if (option === "icon") {
    const CustomIcon = getIconByName(value);
    return (
      <>
        <Tooltip title={value}>
          <CustomIcon />
        </Tooltip>
      </>
    );
  }
  //console.log(header)
  if (value instanceof Array) {
    return value?.length;
  }

  if (value instanceof Object) {
    let resp = value?.full_name || value?.name || value?.label || value?.number;
    return resp
  }
  //header?.type==='boolean'
  if (header.type === "number" && header.subtype === 'currency') {
    return `${row.currency_symbol} ${formatCurrency(value)}`;
  } else if (header.type === "number" && header.subtype === 'percentage') {
    return `${formatCurrency(value)} %`;
  } 
  else if (header.type === "number") {
    return value;
  }
   else if (anyOf(header, "boolean")||header.list_type==='boolean') {
    return displayBoolean(option, value);
  } else if (anyOf(header, "date-time")) {
    return (
      <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${
        value ? moment(value).format("yyyy-MM-DD HH:mm:ss") : "Never"
      }`}</div>
    );
  } else {
    return (
      <div className="white-space-nowrap overflow-hidden text-overflow-ellipsis">{`${
        value ? value : ""
      }`}</div>
    );
  }
};

const displayOrdering = (option) => {
  if (option.startsWith("-")) {
    return (
      <div className="flex flex-column">
        <FaCaretUp style={{ marginBottom: -3 }} className="p-0" />
        <FaCaretDown style={{ marginTop: -3 }} className="text-gray-200  p-0" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-column">
        <FaCaretUp style={{ marginBottom: -3 }} className="text-gray-200 p-0" />
        <FaCaretDown style={{ marginTop: -3 }} className="p-0" />
      </div>
    );
  }
};

const TableHeader = ({ children, justify = "start" }) => {
  return (
    <div className={`flex justify-content-${justify} align-items-center`}>
      {children}
    </div>
  );
};

/* 
   This is the start of the main functiion
*/
export default function GenericList({
  related_field = null,
  noFilters = false,
  parent_id = null,
  parent_name,
  page = 1,
  page_size = 5,
  can_paginate = true,
  url = "",
  objectName,
  dataChange,
  onDataChanges = f => f,
  permissions,
  createRelated,
  serialNumber,
  onSelectedChanged = (f) => f,
}) {
  const [size] = React.useState("sm");
  const [loading, setLoading] = React.useState(false);

  const [selected, setSelected] = React.useState([]);

  const [options, setOptions] = React.useState({ properties: {} });

  const [rows, setRows] = React.useState([]);
  const [dataObject, setDataObject] = React.useState();

  const [searchParams, setSearchParams] = useSearchParams({});
  const [slideDirection, setSlideDirection] = React.useState("left")

  const getParam = (param, default_value = null) => {
    let value = searchParams.get(param);
    if (param !== "ordering") {
      if (!isNaN(value) && value !== null) {
        //console.log(`Is not NAN ${value}`)
        return value;
      } else {
        //console.log(`Is NAN ${value}`)
        return default_value;
      }
    } else {
      if (value === null) {
        return default_value;
      } else {
        return value;
      }
    }
  };

  const normalNumber = (value, default_value = null, param = null) => {
    if (param !== "string") {
      if (!isNaN(value)) {
        return value;
      } else {
        return default_value;
      }
    } else {
      return value;
    }
  };

  const [triggerToast, setTriggerToast] = React.useState({
    message: "",
    counter: 0,
    color: "success",
  });

  const showToast = (message, type = "primary") => {
    setTriggerToast((prev) => ({
      message: message,
      counter: prev.counter + 1,
      color: type,
    }));
  };


  const hideToast = () => {
    setTriggerToast((prev) => ({ message: null, counter: 0 }));
  };

  const [pageObject, setPageObject] = React.useState({
    page: page,
    page_size: page_size,
    limit: page_size,
    skip: 0,
    ordering: "-id",
    query: ''
  });

  const setOrdering = (option) => {
    let ordering = pageObject?.ordering;
    if (ordering === option) {
      setPageObject((e) => ({ ...e, ordering: `-${option}` }));
    } else {
      setPageObject((e) => ({ ...e, ordering: `${option}` }));
    }
  };

  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { camelCase, makeLabel } = useTools();

  const refreshData = () => {
    //let params = getParams(['page_size','ordering','page','limit','skip','query'], pageObject)
    let page_obj = pageObject;
    if (parent_id && related_field) {
      page_obj = { ...page_obj, parent_id, related_field }; //Include these if they are set
    }
    setLoading(true);
    api
      .get(`/${url}/?page_object=${JSON.stringify(page_obj)}`)
      .then((response) => {
        //console.log(response)
        setRows(response.data.results);
        setDataObject(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { pathTo: location.pathname } });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    //console.log("Page options : ", pageObject)
    let params = pageObject;

    Object.keys(pageObject).map((element) => {
      if (searchParams.get(element))
        params = { ...params, [element]: searchParams.get(element) };
      return params;
    });
    let { skip, page } = params
    if(skip > parseInt(dataObject?.count)) {
      skip = parseInt(dataObject?.count) - pageObject.limit
    } else if(skip < 0) {
      skip = 0
    }

    if(page < 1)
    {
      page = 1
    } else if(page > (parseInt(dataObject?.count)/page_size)) {
      page = Math.ceil((parseInt(dataObject?.count)/page_size))
    }
    //console.log("Params : ", params)
    //  if(params !== {})
    setPageObject({ ...params, skip, page });
  }, []);


  React.useEffect(() => {
    let opts = appOptions[url];
    if (!opts)
      api
        .options(`/${url}/`)
        .then((res) => {
          //console.log(res.data)
          setOptions(res.data);
        })
        .catch(console.log);
    else {
      setOptions(opts);
    }

    return () => {
      //console.log("I am returning", pageObject)
      //console.log("Search params : ", searchParams.get('page_size'))

      setOptions({ properties: {} });

      setPageObject({
        query: "",
        page: getParam("page", page),
        page_size: getParam("page_size", page_size),
        limit: getParam("limit", page_size),
        skip: getParam("skip", 0),
        ordering: getParam("id", "-id"),
      });
    };
  }, [objectName]);

  React.useEffect(() => {
    setSearchParams(pageObject);
  },[pageObject])

  React.useEffect(() => {
    /* This is how we do pagination and searches by watching the url and pageObject we reload everytime it changes */
    refreshData();
    //
    return () => {
      setRows([]);
    };
  }, [pageObject, url, dataChange, serialNumber]);

  React.useEffect(() => {
    /* This was put here for testing purposes and can be kept for future test */
    //console.log("The URL has changed")
  }, [url]);

  const doSearch = React.useCallback((term) => {
    setPageObject((e) => ({
      ...e,
      query: term,
    }));
  });

  const doFilter = (filter_object) => {
    let pageDefaults = {
      page: page,
      page_size: pageObject.page_size,
      limit: pageObject.limit,
      skip: 0,
      ordering: pageObject.ordering,
    };

    if (filter_object)
      setPageObject({
        ...pageDefaults,
        ...filter_object,
      });
    else setPageObject(pageDefaults);
  };

  const handleChangeRowsPerPage = (event, newValue) => {
    let p_size = normalNumber(
      parseInt(newValue?.toString(), page_size),
      page_size
    );
    setPageObject((p) => ({
      ...p,
      page: 1,
      page_size: p_size,
      skip: 0,
      limit: p_size,
    }));
  };

  const handleChangePage = (newPage) => {
    let page_size = pageObject?.page_size;
    let page = parseInt(newPage);

    let skip = (page-1) * parseInt(page_size)

    if(page < 1){
      page = 1
    } 
    
    else if(page > (parseInt(dataObject?.count)/page_size)) {
      page = Math.ceil((parseInt(dataObject?.count)/page_size))
    }

    if(skip > parseInt(dataObject?.count)) {
       skip = parseInt(dataObject?.count) - pageObject.limit
    } else if(skip < 0) {
      skip = 0
    }


    setPageObject((p) => ({ ...p, page: newPage, skip, page  }));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChecked = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  React.useEffect(() => {
    onSelectedChanged(selected);
  }, [selected]);

  const numSelected = React.useMemo(() => {
    return selected.length;
  }, [selected]);

  const onSelectedActioned = (value) => {
    if (value) {
      setSelected([]);
      refreshData();
      showToast(
        `${value.label}, ${selected.length} ${makeLabel(
          selected.length == 1 ? objectName.slice(0, -1) : objectName
        )} affected.`
      );
    } else {
      showToast("Action could not be complted", "warning");
    }
  };

  const getTdWidth = (label) => {
    const labels = {
      make: 100,
      name: 160,
      full_name: 150,
      first_name: 100,
      last_name: 100,
      email: 200,
      slug: 250,
      description: 200,
      date_joined: 180,
      last_login: 180,
      last_seen: 180,
      hashed_password: 140,
      phone_confirmed: 140,
      email_confirmed: 140,
      is_account_admin: 140,
      is_staff: 60,
      is_admin: 80,
      id: 50,
    };

    let length = labels[label] ?? 160;
    if (!length) length = 8 * label.length;

    return length;
  };


  const [extraActions] = useExtraActions();

  const actionList = React.useMemo(() => {
    return extraActions[objectName];
  }, [objectName]);

  const exclude_fields = ['id','customer_id','tenant_id','slug','created_by_id','updated_by_id','is_deleted','terms']

  const fieldList = React.useMemo(() => {
     let list = Object.keys(options?.properties).filter(e => !exclude_fields.includes(e))
     if(list.includes('number')) {
          list = list.filter(e => e !== 'number')
          return list.length > 0 ? [ 'number' , ...list] : list
     }
     return list
          
  },[options])

  return (
      <div className="flex flex-column ">
        {triggerToast?.message && (
          <AppSnackBar
            openCount={triggerToast.counter}
            message={triggerToast.message}
            type={triggerToast.color}
            onClose={hideToast}
          />
        )}

        <TableFilter
          properties={options?.properties}
          doFilter={doFilter}
          doSearch={doSearch}
          noFilters={noFilters}
        />

        <div className="hidden md:block border-bottom-1 border-gray-300">
          {can_paginate && (
            <div className="p-2 flex justify-content-between">
              {selected.length > 0 ? (
                <AppDropdown
                  label={""}
                  onActionComplete={onSelectedActioned}
                  startIcon={<MoreVert className="" />}
                  menuItems={actionList}
                  selected={selected}
                  confirm_action={true}
                  className={"mr-2"}
                />
              ) : (
                <div className="h-2rem pb-1">&nbsp;</div>
              )}
              <PaginateComponents
                dataObject={dataObject}
                pageObject={pageObject}
                rows={rows}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          )}
        </div>
    <Slide in={!loading&&rows} direction={slideDirection}>
        <TableContainer className="hidden md:block overflow-x-scroll table-responsive w-full">
          <Table
            aria-label="table sizes"
 
            size={size}
            className="m-0 text-sm"
             sx={{
              "& TableRow > *:first-of-type": {
                position: "sticky",
                left: 0,
              },
              "& TableRow > *:nth-child(2)": {
                position: "sticky",
                backgroundColor: "inherit",
                zIndex: 1024,
                left: 40,
              },
            }}
          >
            <TableHead>
              <TableRow className="bg-white">
                <TableCell scope="row" className="w-2rem max-w-max">
                  <Checkbox
                    indeterminate={
                      numSelected > 0 && numSelected < rows?.length
                    }
                    checked={rows?.length > 0 && numSelected === rows?.length}
                    onChange={handleSelectAllClick}
                    slotProps={{
                      input: {
                        "aria-labelledby": "name",
                      },
                    }}
                    sx={{ verticalAlign: "top" }}
                  />
                </TableCell>

                {fieldList?.map((option, index) => {
                  let header = options?.properties[option];
                  if(header.hidden)
                      return

                  let total_headings =
                    Object.keys(options?.properties)?.length - 1;
                  let current_sort = pageObject?.ordering?.startsWith("-")
                    ? pageObject?.ordering?.slice(1)
                    : pageObject?.ordering;

                  let width = getTdWidth(option);

                  return (
                    <React.Fragment key={index}>
                      {index === 0 ? (
                        <TableCell
                          component={'th'}
                          className="clickable max-w-min"
                          style={{ width, flex: 1 }}
                          onClick={() => setOrdering(option)}
                        >
                          <TableHeader>
                            {camelCase(header?.title)}&nbsp;
                            {current_sort === option &&
                              displayOrdering(pageObject?.ordering, option)}
                          </TableHeader>
                        </TableCell>
                      ) : index === total_headings ? (
                        <TableCell
                          style={{  width, flex: 1 }}
                          className="clickable text-center min-w-max"
                          onClick={() => setOrdering(option)}
                        >
                          <TableHeader justify="end">
                            {camelCase(header.title)}&nbsp;
                            {current_sort === option &&
                              displayOrdering(pageObject?.ordering, option)}
                          </TableHeader>
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{  width, flex: 1 }}
                          className="clickable min-w-max"
                          onClick={() => setOrdering(option)}
                        >
                          <TableHeader>
                            {camelCase(header.title || option)}&nbsp;
                            {current_sort === option &&
                              displayOrdering(pageObject?.ordering)}
                          </TableHeader>
                        </TableCell>
                      )}
                    </React.Fragment>
                  );
                })}

               { options.related_edit === 'inline' &&  <TableCell sx={{ width: 120 }}></TableCell> }
              </TableRow>
            </TableHead>


            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <DisplayTableRow
                        key={index}
                        index={index} 
                        selected={selected} 
                        row={row} 
                        objectName={objectName} 
                        url={url}
                        onComplete={refreshData}
                        fieldList={fieldList}
                        handleChecked={handleChecked}
                        permissions={permissions}
                        onDataChanges={onDataChanges}
                        options={options}
                        />
                );
              })}

              {loading && (
                <TableRow>
                  <TableCell
                    className="w-full text-center h-5rem"
                    colSpan={Object.keys(options?.properties)?.length + 2}
                  >
                    <CircularProgress />
                  </TableCell>{" "}
                </TableRow>
              )}
 
              {rows?.length === 0 && !loading && (
                <TableRow>
                  <TableCell
                    className="w-full text-left"
                    colSpan={Object.keys(options?.properties)?.length + 2}
                  >
                    No {makeLabel(objectName).toLowerCase()} matching query
                    found
                  </TableCell>
                </TableRow>
              )}


            </TableBody>
          </Table>
          { console.log(options)}
          { options.related_edit === 'inline' &&  parent_id && <TableEditCreate saveOnComplete onComplete={() => { refreshData(); onDataChanges(Math.random()) }} objectName={objectName}  permissions={permissions} parent_id={parent_id} parent_name={parent_name} /> }
        </TableContainer>
    </Slide>

        <div className="hidden md:block border-top-1 border-gray-300">
          {can_paginate && (
            <div className="p-2">
              <PaginateComponents
                dataObject={dataObject}
                pageObject={pageObject}
                rows={rows}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          )}
        </div>

        <div className="md:hidden">
          <List>
            {rows?.map((unit, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemButton
                    onClick={() => navigate(`/manage/${url}/${unit.slug}/`)}
                  >
                    <ListItemDecorator>
                      <Circle />
                    </ListItemDecorator>
                    <ListItemContent>
                      {Object.keys(options?.properties).map((option, index) => {
                        let header = options?.properties[option];
                        let total_headings =
                          Object.keys(options?.properties)?.length - 1;
                        let INCLUDES = [
                          "imsi_number",
                          "gsm_operator",
                          "imei_number",
                          "manufacturer",
                          "registration_number",
                          "description",
                          "customer_name",
                          "email",
                          "authorized_token",
                          "user_id",
                        ];
                        return (
                          INCLUDES.includes(option) &&
                          (index === 0 ? (
                            <Typography level="title-sm">
                              {displayRowValue(unit, option, header)}
                            </Typography>
                          ) : (
                            <Typography level="body-sm" noWrap>
                              {displayRowValue(unit, option, header)}
                            </Typography>
                          ))
                        );
                      })}
                    </ListItemContent>
                    <Box className="flex justify-content-end">
                 

                      {Object.keys(options?.properties).map((opt, index) => (
                        <React.Fragment key={index}>
                          {["status", "is_active", "is_revoked"].includes(
                            opt
                          ) && displayRowValue(unit, opt, { type: "boolean" })}
                        </React.Fragment>
                      ))}
                    </Box>
                    <KeyboardArrowRight />
                  </ListItemButton>
                </ListItem>
                <ListDivider />
              </React.Fragment>
            ))}
          </List>

          <div className="p-2">
            <PaginateComponents
              dataObject={dataObject}
              pageObject={pageObject}
              mobile={true}
              rows={rows}
              handleChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
  );
}


