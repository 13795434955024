import { Button, Checkbox, Divider, IconButton, Typography } from "@mui/joy";
import { Link, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../utils/useApi";
import useTools from "../utils/useTools";
import Content, {  ContentBetween, ContentColumn, ContentEnd, ContentStart, PageHeader } from "../components/layout/Content";
import CreateForm from "../components/formcontrols/CreateForm";
import { updateOptions } from "../utils/models/createOptions";
import { Cancel, CancelOutlined, Close, Edit } from "@mui/icons-material";
import UploadCsvModal from "../components/layout/modals/UploadCsvModal";

import moment from "moment";
import MonthPaginate from "../components/layout/reminders/MonthPaginate";
import DisplayMonth from "../components/layout/reminders/DisplayMonth";
import Reminder from "../components/layout/reminders/Reminder";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';


const reminderFilters = [
    {
        type: "Reminder",
        color: "blue",
        total: 0,
    },
    {
        type: "Drop Off",
        color: "green",
        total: 0,
    },
    {
        type: "Pick up",
        color: "orange",
        total: 0,
    },
    {
        type: "Leave start",
        color: "pink",
        total: 0,
    },
    {
        type: "Leave end",
        color: "purple",
        total: 0,
    }
]

const RemindersPage = ({ is_admin = false}) => {
    const params = useParams()
    const [options, setOptions] = useState(null)

    const [dataChange, setDataChange] = useState(0)
    const [expanded, setExpanded] = useState(false)

    const [selected, setSelected] = useState([])
    const [reminders, setReminders] = useState([])

    const objectName = 'reminders'
    const api = useApi();
    const tools = useTools()

    const [currentDate, setCurrentDate] = useState({})

    const [filtered, setFiltered] = useState([])
    const [selectedReminder, setSelectedReminder] = useState(null)
    const [selectedDay, setSelectedDay] = useState(null)
    

    const handleChecked = (event, name) => {
        const selectedIndex = filtered.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);

        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));

        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));

        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setFiltered(newSelected);
      };

    const getReminders = useCallback(()=> {
        if(!currentDate)
           return


        const { year, month, date } = currentDate
        console.log(currentDate)

        if(!year||!month||!date)
        return

        let start_date = moment(new Date(year, month, date)).startOf('month').format('YYYY-MM-DD hh:mm:ss')
        let end_date = moment(new Date(year, month, date)).endOf('month').format('YYYY-MM-DD hh:mm:ss')

        
           console.log(start_date, end_date)

        api.get(`reminders/?page_object=${JSON.stringify({skip:0, limit: 200, start_date, end_date })}`)
        .then((res) => {
            setReminders(res.data.results)
            console.log(res.data.results)
        }).catch(console.log)
    },[api, currentDate?.year, currentDate?.month, currentDate?.date])

    useEffect(() => {
        console.log(currentDate)
        let date = moment()
  
        setCurrentDate({
            year: date.year(),
            month: date.month(),
            date: date.date()
        })

        getReminders()

        return () => {
            setReminders([])
        }
  
      },[])

    
      const isSelected = (name) => filtered.indexOf(name) === -1; 

    const handleChangePage = (data) => {
        let month = currentDate?.month;
        let year = currentDate?.year;
        
        month+=(data)

        console.log(month)
        year = month < 0 ? (year-1) : month > 11 ? (year+1) : year;
        month = month < 0 ? 11 : month > 11 ? 0 : month;

        console.log(year, month)
        setCurrentDate(prev => ({ ...prev, month, year }))
    }

    useEffect(() => {
        getReminders()
    },[currentDate?.year, currentDate?.month, currentDate?.date])


    useEffect(() => {
        let opts = updateOptions[objectName]
       //console.log(objectName)
        if(!opts) {
            api.options(`/${objectName}/create`).then(res => {
               //console.log(res)
                setOptions(res.data)
            }).catch(console.log)
        }
        else {
           //console.log(opts)
            setOptions(opts)
        }
    
        return () => {
          setOptions(null)
        }
         
      },[objectName, api])

      const getTypeCount = (filter) => {
        return reminders.filter(r => r.reminder_type===filter.type).length
      }

      const onDaySelect = (day) => {
          console.log(day)
          setSelectedDay(day)
      }

      const onItemSelect = (item) => {
           console.log(item)
           setSelectedReminder(item)
      }

    return (
        <div className="w-full">
            <PageHeader
                  label={`${objectName}`}
                  //startDecorator={<Button startDecorator={<BarChart />} variant="plain" color="neutral">Table Statictics</Button>}
                  endDecorator={
                  <div className="flex justify-content-end align-items-center">
                  {/* <CreateObjectComponent label={objectName} /> */}
                    
                     <UploadCsvModal objectName={objectName} />
                     <Button variant="solid" color="primary" size='sm' onClick={() => setExpanded(true)}>
                        <Edit className="md:hidden block"/>
                        <span className="hidden md:block">
                           Create {tools.makeLabel(objectName.slice(0, -1))}
                        </span>
                    </Button>


                  </div>}
                  />
                  <div className="w-full m-0">
                   { options && <CreateForm objectName={objectName} options={options} onComplete={setDataChange} expanded={expanded} onCollapse={setExpanded}/> }
                  </div>

                  <div className="w-full m-0 bg-white table-responsive">
                   { currentDate && <MonthPaginate currentDate={currentDate}  handleChangePage={handleChangePage}/> }
                   <Content className="m-0 row row-cols-1 row-cols-md-2">
                      <ContentBetween className="col flex-column p-0 border-right-1 border-300 flex-1" style={{ maxWidth: 330 }}>
                         
                         { selectedReminder ? <ContentColumn className="p-2 bg-gray-50" style={{minHeight: 300}}>
                                  <ContentBetween className={"align-items-start"}>
                                    <Typography className="font-bold">{tools.makeLabel(selectedReminder.reminder_type)} - {tools.makeLabel(selectedReminder.short_name)}</Typography>   
                                    <IconButton size="sm" className="border-round-2xl" onClick={() => onItemSelect(null)}><Close /></IconButton>
                                  </ContentBetween>
                                  <Typography className="text-gray-500 text-sm">{`${selectedReminder.reminder_date}`}</Typography>   
                                  <Typography className="text-gray-800 text-sm">{`${selectedReminder?.owner?.full_name||'No one'}`}</Typography>   
                                  <Typography className="mt-3 bg-blue-100 border-round-sm p-2 text-gray-800 text-sm pb-3">{`${selectedReminder?.description||'No one'}`}</Typography>   
                          </ContentColumn> : 
                           <Content className="p-2">
                               <Typography className="font-bold">Filters</Typography>
                               { reminderFilters.map((filter, index) =>  <Reminder key={index} type="" color={filter.color} count={getTypeCount(filter)} startDecorator={<Checkbox label={filter.type} onChange={(e) => handleChecked(e, filter.type)} checked={isSelected(filter.type)} />} /> ) }
                           </Content>

                          }
                          <Content className="mt-auto">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar onChange={(e) => onDaySelect(moment(e.$d))} />
                            </LocalizationProvider>
                          </Content>
                      </ContentBetween>
                      <Content className="col p-0 overflow-y-scroll" style={{ maxHeight: '75vh'}}>
                        { selectedDay ? <DayByHours reminders={reminders.filter(r => !filtered.includes(r.reminder_type))} selectedDay={selectedDay}  onDeselect={() => onDaySelect(null)} onItemSelect={onItemSelect}  /> :
                         currentDate && <DisplayMonth currentMonth={currentDate} reminders={reminders.filter(r => !filtered.includes(r.reminder_type))} onDaySelect={onDaySelect} onItemSelect={onItemSelect}  /> }
                      </Content>
                   </Content>
                  </div>
        </div>

    )
  };
  
  export default RemindersPage;
 

  const DayByHours = ({selectedDay, reminders,  onDeselect = f => f, onItemSelect = f=> f }) => {
    const tools = useTools()
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

    const thisDay = selectedDay
    const [rows, setRows] = useState([])
    
    const days =['Sun','Mon','Tue','Wed','Thu','Fri','Sat']

    const datefy = (date) => {
        return moment(date?.split(".")[0].replace("T"," "))
    }

    const equalDate = (date_1, date_2) => {
        return (date_1.date() === date_2.date())&&(date_1.month() === date_2.month())&&(date_1.year()===date_2.year())
    }

    useEffect(() => {
        let reminder_list = reminders?.map(r => ({ ...r, reminder_date : datefy(r.reminder_date)}))
        let reminder_row = reminder_list.filter(r => equalDate(r.reminder_date, thisDay))
        setRows(reminder_row)

        return () => setRows([])
    },[reminders])


    const hours = useMemo(() => {
        let hrs =[]
       for(let i = 0; i < 24 ; i++)
       {
          hrs.push(i)
       }
       return hrs
    },[selectedDay])

    return (
        <ContentColumn style={{ minHeight: '76vh'}}>
            <ContentBetween className={"align-items-start p-3"}>
                <ContentColumn>
                    <Typography className="text-2xl text-gray-400">{selectedDay.format('ddd')}</Typography>
                    <Typography className="">{selectedDay.date()} {months[selectedDay.month()]}, {selectedDay.year()}</Typography> 
                </ContentColumn>
                  
                <IconButton size="sm" className="border-round-2xl" onClick={() => onDeselect(null)}><Close /></IconButton>
            </ContentBetween>

            <ContentColumn className={"align-items-start p-3"}>
                { hours.map((hour, index) => <HourItem reminders={rows} key={index} hour={hour} onItemSelect={onItemSelect} /> )}
            </ContentColumn>

        </ContentColumn>
    )
  }

  const HourItem = ({ hour, reminders, onItemSelect = f=> f }) => {

    const reminderStart = (reminder) => { return moment(reminder.reminder_date).hour() }

    return (
        <>
        <ContentStart className={"align-items-end"}>
                <Content className="border-right-1 border-300 p-2 pt-3 text-sm font-bold w-5rem">
                   {hour < 10 ? `0`: null }{hour}:00 am
                </Content>
                <ContentStart className={'flex flex-1 ml-1 mb-1 pb-1 overflox-x-scroll'}>
                   { reminders?.map((reminder, index) => reminderStart(reminder) == hour && <Content className="mr-2 flex flex-1"><Link key={index}  href={`/manage/reminder/${reminder?.slug}`} className="no-underline w-20rem" onClick={(e) => {e.preventDefault(); onItemSelect(reminder)}}><Reminder type={reminder.reminder_type} key={index} count={reminder.reminder_date.fromNow()} /></Link> </Content> ) }
                </ContentStart>
                </ContentStart>

                <Divider orientation="horizontal"></Divider>
        </>

    )
  }