import { useState } from "react";
import Content, { ContentBetween, ContentColumn, ContentEnd, HeaderContent } from "../layout/Content";
import AppSnackBar from "../layout/AppSnackBar";
import * as React from 'react';
import { useApi } from "../../utils/useApi";

import { Api } from "@mui/icons-material";
import NoData from "../layout/NoData";
import useTools from "../../utils/useTools";
import { Button, Checkbox } from "@mui/joy";
import TableFilter from "./TableFilter";
const ModelTableList = ({ noFilters, objectName, selectedPermission, page=1, page_size=50, onPermissionChange = f => f, onDataBaseCommit = f => f }) => {
    
    const [rows, setRows] = useState([])
    const api = useApi()

    const [options, setOptions] = React.useState({ properties: {}})
    const [triggerToast, setTriggerToast] = React.useState({
        message: '',
        counter: 0,
        color: 'success'
    })

    const [pageObject, setPageObject] = React.useState({
        page: page,
        page_size: page_size,
        limit: page_size,
        skip: 0 ,
        ordering: 'id',
    })
    
    const showToast = (message, type='primary') => {
        setTriggerToast(prev => ({ message: message, counter: prev.counter+1, color: type }))
    }

    const hideToast = () => {
        setTriggerToast(prev => ({ message: null, counter: 0 }))
    }

    const doSearch = React.useCallback((term) => {
        setPageObject(e => ({
          ...e,
          query: term
        }))
    })
    
    const doFilter = (filter_object) => {
    let pageDefaults = {
        page: page,
        page_size: pageObject.page_size,
        limit: pageObject.limit,
        skip: 0,
        ordering: pageObject.ordering 
    }

    if(filter_object)
        setPageObject({
        ...pageDefaults,
        ...filter_object
        })
    else
        setPageObject(pageDefaults)
    }

    const getUserRoles = React.useCallback(() => {
         api.get(`${objectName}/?page_object=${JSON.stringify(pageObject)}`).then(response => {
            setRows(response.data.results)
         }).catch(console.log)
    },[api, selectedPermission?.id])

    const handlePermissionChange = (changedPermission) => {
        console.log(changedPermission)
        onPermissionChange(changedPermission)
    }

    const commitPermissionChange = () => {
          //console.log(selectedPermission)
          api.put(`permissions/${selectedPermission.slug}`, selectedPermission)
          .then((res) => {
             console.log(res)
             onDataBaseCommit(true)
          })
          .catch(console.log)
    }

    React.useEffect(() => {
        getUserRoles()
    },[])
    
    return (
      <ContentColumn className={'p-3'}>
          <HeaderContent header={"Model Table"} subheading={"Model table objects based on selected user group."} endDecorator={<Button onClick={commitPermissionChange}>Update roles</Button>}/>

          { triggerToast?.message && <AppSnackBar
                   openCount={triggerToast.counter} 
                   message={triggerToast.message} 
                   type={triggerToast.color} 
                   onClose={hideToast}
         /> }

        <TableFilter properties={options?.properties} doFilter={doFilter} doSearch={doSearch} noFilters={noFilters} /> 
        
        { rows.map((userRole, index) => <TableItem  key={index} profile={userRole} selectedProfile={selectedPermission} onPermissionChange={handlePermissionChange}/> ) }
        { rows.length === 0 && <NoData objectName="model tables" /> }

      </ContentColumn>
    )
}

export default ModelTableList;

const TableItem = ({ profile, selectedProfile, onSelect = f=>f, onPermissionChange = f => f }) => {
    const tools = useTools()

    const permissionLink = React.useMemo(() => {
        //console.log(selectedProfile)
         const default_value = { 
            table_id: profile.id, 
            user_role_id : selectedProfile.id, 
            read: false, 
            create: false, 
            update: false, 
            remove: false 
        }

         return selectedProfile?.permission_relations?.find(p => p.table_id === profile.id) || default_value
    },[profile, selectedProfile?.id])

    React.useEffect(() => {
      //console.log(permissionLink)
    },[permissionLink])

    const handlePermissionChange = (perimission) => {
        onPermissionChange(perimission)
    }


    return <ContentColumn onClick={() => onSelect(profile)} className={`p-3 shadow-2 my-2 border-round-md font-semibold bg-white`}>
        <ContentBetween>
          <Content>
             {tools.makeLabel(profile.name)}
          </Content>

           <PermissionLink permissionLink={permissionLink} onChange={handlePermissionChange} />

        </ContentBetween>
    </ContentColumn>
}

const PermissionLink = ({ permissionLink, onChange = f => f }) => {
    const [link, setLink] = useState(permissionLink);

    const setLinkValues = (name, e) => {
         let values = { ...link, [name]: e }
         setLink(values)
         onChange(values)
    }

    React.useEffect(() => {
        setLink(permissionLink)
    },[permissionLink])

    return <ContentEnd>
                <Checkbox label="Read" className="mx-2" checked={link.read||false} onChange={(e) => setLinkValues('read', e.target.checked)}/> 
                <Checkbox label="Create" className="mx-2" checked={link.create||false} onChange={(e) => setLinkValues('create', e.target.checked)}/> 
                <Checkbox label="Update" className="mx-2" checked={link.update||false} onChange={(e) => setLinkValues('update', e.target.checked)}/> 
                <Checkbox label="Remove" className="mx-2" checked={link.remove||false} onChange={(e) => setLinkValues('remove', e.target.checked)}/> 
           </ContentEnd>
}