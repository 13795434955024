import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import { useApi } from '../../utils/useApi';
import { Checkbox, ModalClose, extendTheme } from '@mui/joy';
import useTools from '../../utils/useTools';


const theme = extendTheme({
  components: {
    JoyModalDialog: {
      defaultProps: { layout: 'top' },
      styleOverrides: {
        backdrop: ({ ownerState }) => ({
          backgroundColor: "red"
        }),
        root: ({ ownerState }) => ({
          ...(ownerState.layout === 'top' && {
            top: '12vh',
            left: '50%',
            transform: 'translateX(-50%)',
          }),
        }),
      },
    },
  },
});

export default function CreateObjectComponent({ label, buttonLabel=null }) {
  const [open, setOpen] = React.useState(false);
  const api = useApi()
  const tools = useTools()

  const [options, setOptions] = React.useState({ properties: {}});

  React.useEffect(() => {
    api.options(`/${label}/`).then(res => {
        //console.log(res.data)
        setOptions(res.data)
    }).catch(console.log)

    return () => {
      setOptions({ properties: {}})
    }
     
  },[label])

  return (
    <>
      <Button
        variant="solid"
        color="success"
        size='sm'
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
        className='mr-2'
      >
       { buttonLabel ? buttonLabel : tools.titleCase(label.slice(0,-1))} 
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} slotProps={{ backdrop: { sx: { backgroundColor: "#3c3c3c", opacity: 0.2 }}}}>
        <ModalDialog className="md:w-3 xl:w-3">
        <ModalClose />
          <DialogTitle>Create new {tools.titleCase(label.slice(0, -1))}</DialogTitle>
          <DialogContent>Fill in the information of the new {label.slice(0, -1)}.</DialogContent>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setOpen(false);
            }}
          >
            <Stack spacing={2}>

              { Object.keys(options?.properties).map((field, index) => { 
                    let header = options?.properties[field]
                    let excluded = ["id","slug"]
                    let required = options?.required?.includes(field)

                    return ["id","slug"].includes(field) || <FormField 
                          title={header.title} 
                          required={required}
                          autoFocus={index==0}
                          value={""}
                          type={header.type}
                          key={index}
                    />
                } )}

             { buttonLabel ? <Button type="submit">{buttonLabel}</Button>
             : <Button type="submit">Add new {tools.titleCase(label.slice(0, -1))}</Button> }
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

const FormField = ({ type, autoFocus, title, required, value, onChange = f => f }) => {
  return (
    <>
    { ['boolean'].includes(type) &&
    <FormControl>
       <Checkbox label={title} defaultChecked />
    </FormControl> }
    
    { ['string','email','number'].includes(type) &&
    <FormControl>
          <FormLabel>{title}{ required && <span className='text-red-600'>*</span>}</FormLabel>
          <Input autoFocus={autoFocus} required={required} placeholder={title} onChange={onChange}/>
    </FormControl> }

    </>
     
  )
}