import { Checkbox, CircularProgress, FormControl, FormLabel, IconButton, Input, ListDivider, colors } from "@mui/joy"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ChevronLeft, ChevronRight, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { useApi } from "../../utils/useApi";
import NestedList from "./NestedList";
import { ContentBetween, ContentColumn } from "../layout/Content";


export default function SlushBucket({ field, value, onChange = f => f }) {

    const [selectedChoices, setSelectedChoices] = useState([])
    const [availableChoices, setAvailableChoices] = useState([])
    
    const [leftHandSelection, setLeftHandSelection] = useState([])
    const [rightHandSelection, setRightHandSelection] = useState([])
    
    const api = useApi()

    const buildChoiceList = (available=[]) => {
        let newValue = value || []
        let choiceList = available;
  
        newValue.forEach(element => {
          choiceList = choiceList.filter(c => c.id != element.id)
        });
  
        setSelectedChoices(value)
        setAvailableChoices(choiceList)
    }
    
    useEffect(() => {
      // console.log("Object re-rendered.")
    },[])

    useEffect(() => {
        //console.log("The value has changed", value)
        buildChoiceList(availableChoices)
    },[value])

    useEffect(() => {
       // console.log(value)
    },[value])
    
    const ordered = (object_list) => {
        return object_list.sort((a, b) => a.id - b.id)
    }
    
    const assignLeftToRight = () => {
      let available = availableChoices.map((choice) => {
         if(!leftHandSelection.includes(choice.id))
            return choice 
      }).filter(e => e != undefined)
    
      let selected = availableChoices.map(choice => {
        if(leftHandSelection.includes(choice.id))
              return choice
      }).filter(e => e != undefined)

      //console.log(available)
      //console.log(selected)
    
      setAvailableChoices(ordered(available))
      setSelectedChoices(prev => ordered([...prev, ...selected]))
      setLeftHandSelection([])
      onChange(ordered([...selectedChoices, ...selected]))
    }
    
    const assignAllLeftToRight = () => {
      let selected = availableChoices
    
      setAvailableChoices([])
      setSelectedChoices(prev => ordered([...prev, ...selected]))
      setLeftHandSelection([])
      onChange(ordered([...selectedChoices, ...selected]))
    }
    
    const  assignAllRightToLeft= () => {
      let removing = selectedChoices;
    
      //console.log(removing)
    
      setAvailableChoices(prev => ordered([...prev, ...removing]))
      setSelectedChoices([])
      setRightHandSelection([])
      onChange([])
    }
    
    const assignRightToLeft = () => {
      let removing = selectedChoices.map((choice) => {
         if(rightHandSelection.includes(choice.id))
            return choice 
      }).filter(e => e != undefined)
    
      let remaining = selectedChoices.map(choice => {
        if(!rightHandSelection.includes(choice.id))
              return choice
      }).filter(e => e != undefined)
    
      //console.log(removing)
      //console.log(remaining)
      
      setAvailableChoices(prev => ordered([...prev, ...removing]))
      setSelectedChoices(ordered(remaining))
      setRightHandSelection([])
      onChange(ordered(remaining))
    }
    
    const getAvailableChoices = useCallback(() => {
        if(field.choices) {
          setAvailableChoices(field.choices)
        } else if (field.related_url) {
            api.get(`${field.related_url}/`)
            .then((response) => {
              //console.log(response)
             // setAvailableChoices(ordered(response.data.results))
              buildChoiceList(ordered(response.data.results))
            })
        }
    },[api, field])
    
    useEffect(() => {
        getAvailableChoices()
    },[field, getAvailableChoices])
    
    
    
    return <FormControl>
    <FormLabel className="font-bold">{field.title}{ field.required && <span className='text-red-600'>*</span>}</FormLabel>
     <ContentBetween className="w-full">
       <NestedList label={"Available choices"} choices={availableChoices} className="flex-1" onChoicesUpdate={setLeftHandSelection} />
      
       <SluhButtonGroup 
                assignRightToLeft={assignRightToLeft}
                assignLeftToRight={assignLeftToRight}
                assignAllLeftToRight={assignAllLeftToRight}
                assignAllRightToLeft={assignAllRightToLeft}/>
       
       <NestedList label={"Selected choices"} choices={selectedChoices} className="flex-1" onChoicesUpdate={setRightHandSelection} />
    
     </ContentBetween>
     </FormControl>
}

const SluhButtonGroup = ({assignRightToLeft, assignAllRightToLeft, assignAllLeftToRight, assignLeftToRight}) => {
    return <ContentColumn>
    <IconButton size="sm" onClick={() => assignRightToLeft()}>
      <KeyboardArrowLeft />
    </IconButton>
    <IconButton size="sm" onClick={() => assignAllRightToLeft()}>
      <KeyboardDoubleArrowLeft />
    </IconButton>
    <IconButton size="sm" onClick={() => assignAllLeftToRight()}>
      <KeyboardDoubleArrowRight />
    </IconButton>
    <IconButton size="sm" onClick={() => assignLeftToRight()}>
      <KeyboardArrowRight />
    </IconButton>
  </ContentColumn>
}