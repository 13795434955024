import { useApi } from "./useApi"
import { tabClasses } from '@mui/joy/Tab';



export const useTools = () => {
    const api = useApi()
    const firstLetter  = (str) => {
        return str ? str.slice(0,1).toUpperCase() : "-"
    }

    const reverseText = (str) => {
        let data = str.split(" ").reverse()
        if(data[0].endsWith("ed"))
            data[0] = data[0].slice(0, -2)
        data = data.join(" ")
        return data
    }

    const negateReversed = (str) => {
        let data = reverseText(str)
        data =  "un" + data
        return camelCase(data)
    }

    const wordArray = (str="") => {
        try {
            return str.toLowerCase().replaceAll("_"," ").split(" ")
        } catch {
            return [""]
        }
    }

    const englishContext = (str="") => {
        let word = titleCase(str)
        if(word.startsWith("Is "))
        {
            return word.slice(3) + " Status"
        }
        return word
    }

    const inContext = (str="") => {
        if(str.length < 1)
           return ""

        let word = titleCase(str)
        if(word.startsWith("Is "))
        {
            return word.slice(3) 
        }
        return word
    }

    const titleCase = (str="") => {        
        if(str.length < 1)
           return ""

        let strArray = wordArray(str)

        strArray.forEach((word, index) => {
            strArray[index] = firstLetter(word) + word.slice(1) 
        });
      
        return strArray.join(" ")
    }

    const camelCase = (str="") => {
        return titleCase(str)
    }

    const makeLabel = (str="") => {
         return camelCase(str)
    }

    const avatarize = (str="") => {
        let s = str.split(" ")
        return s.length > 1 ? `${firstLetter(s[0])}${firstLetter(s[1])}`: s.length > 0 ? `${firstLetter(s[0])}`: "- -"
    }

    const dataFromUrl = (url, setLoader = f => f) => {
        return new Promise((resolve, reject) => {
             setLoader(true);
             api.get(url)
             .then((res) => {
                resolve(res.data)
             }).catch(reject)
             .finally(setLoader(false))
        })
    }

    const postToUrl = (url, data, setLoader = f => f) => {
        return new Promise((resolve, reject) => {
             setLoader(true);
             api.post(url, data)
             .then((res) => {
                resolve(res.data)
             }).catch(reject)
             .finally(setLoader(false))
        })
    }

    const putToUrl = (url, data, setLoader = f => f) => {
        return new Promise((resolve, reject) => {
             setLoader(true);
             api.put(url, data)
             .then((res) => {
                resolve(res.data)
             }).catch(reject)
             .finally(setLoader(false))
        })
    }

    const deleteUrl = (url, setLoader = f => f) => {
        return new Promise((resolve, reject) => {
             setLoader(true);
             api.delete(url)
             .then((res) => {
                resolve(res.data)
             }).catch(reject)
             .finally(setLoader(false))
        })
    }

    const anyOf = (field, ftype) => {
        if(!field)
            return null
        return field.type === ftype || (field?.anyOf && field?.anyOf[0]?.type === ftype) || (field?.anyOf && field?.anyOf[1]?.type === ftype) || (field?.anyOf && field?.anyOf[0]?.format === ftype)
      }

    const fieldType = (field) => {
    if(!field)
        return null
    return field?.type  || (field?.anyOf && field?.anyOf[0]?.type) || (field?.anyOf && field?.anyOf[0]?.format) || (field?.anyOf && field?.anyOf[1]?.type)
    }
      

    return {
        camelCase,
        titleCase,
        firstLetter,
        reverseText,
        dataFromUrl,
        postToUrl,
        putToUrl,
        deleteUrl,
        englishContext,
        inContext,
        negateReversed,
        makeLabel,
        avatarize,
        fieldType,
        anyOf
    };
}

export default useTools;


export const INITAL_PAGE_OBJECT = {
    limit : 10,
    skip: 0,
    ordering: "-id",
    page: 1,
    page_size: 10
 }

export const format = "YYYY-MM-DDTHH:mm:ss"

export const colorsSwatch = [
     "bg-blue-100",
     "bg-blue-500 text-white",
     "bg-orange-400 text-white",
     "bg-gray-800 text-white",
     "bg-red-800 text-white",
     "bg-green-600 text-white",
 ]


export const tabStyles ={
    pt: 0,
    //justifyContent: 'center',
    [`&& .${tabClasses.root}`]: {
      flex: 'initial',
      bgcolor: 'transparent',
      '&:hover': {
        bgcolor: 'transparent',
      },
      [`&.${tabClasses.selected}`]: {
        color: 'primary.plainColor',
        '&::after': {
          height: 2,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          bgcolor: 'primary.500',
        },
      },
    },
  }