import { useState } from "react";
import DualCard from "../components/dashboard/DualCard";
import ListCard from "../components/dashboard/ListCard";
import Content, {
  ContentColumn,
  HeaderContent,
  PageHeader,
} from "../components/layout/Content";
import GenericList from "../components/lists/GenericList";

import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";

const sampleData = [
  {
    key: false,
    label: "Incomplete",
  },
  {
    key: true,
    label: "Completed",
  },
];

const smapleRemiders = [
  {
    value: 0,
    label: "Upcoming",
  },
  {
    value: 0,
    label: "Current",
  },
];

const sampleLeaveData = {
  title: "Leave management",
  data: [
    {
      total: 0,
      category: "Annual",
      indicator: "primary",
    },
    {
      total: 0,
      category: "Due",
      indicator: "success",
    },
    {
      total: 0,
      category: "Absent",
      indicator: "danger",
    },
  ],
};

const sampleRentalData = {
  title: "Car rentals",
  data: [
    {
      total: 0,
      category: "Requested",
      indicator: "primary",
    },
    {
      total: 0,
      category: "Completed",
      indicator: "success",
    },
    {
      total: 0,
      category: "Cancelled",
      indicator: "danger",
    },
  ],
};

const Dashboard = () => {
  const [dataChange, setDataChange] = useState();

  return (
    <Fade in={true}>
    <div>
      <ContentColumn className="py-0 flex flex-1 align-items-center">
        <PageHeader label="Dashboard" />
        <Content className="py-3 row row-gap-5 row-cols-1 row-cols-md-4 w-full">
          <Content className="col-12 md:col-3 flex-1" style={{ minWidth: 250 }}>
            <DualCard
              label="Traveller profiles"
              dataSource={sampleData}
              dataUrl={`traveller_invites/usage_stats/is_used`}
            />
          </Content>
          <Content className="col-12 md:col-3 flex-1" style={{ minWidth: 250 }}>
            <ListCard
              label={sampleLeaveData.title}
              dataSource={sampleLeaveData}
            />
          </Content>
          <Content className="col-12 md:col-3 flex-1" style={{ minWidth: 250 }}>
            <ListCard
              label={sampleRentalData.title}
              dataSource={sampleRentalData}
            />
          </Content>
          <Content className="col-12 md:col-3 flex-1" style={{ minWidth: 250 }}>
            <DualCard label="Reminders" dataSource={smapleRemiders} />
          </Content>
        </Content>

        <Content className="my-3 mx-0 w-full bg-white table-responsive border-top-1 border-300">
          <Content className="p-3">
            <HeaderContent
              header={"Profile invite overview"}
              subheading={"The are the invites sent to stakeholders"}
            ></HeaderContent>
          </Content>
          <GenericList
            page_size={5}
            url={`traveller_invites`}
            objectName={`travel_invites`}
            dataChange={dataChange}
          />
        </Content>
      </ContentColumn>
      </div>

    </Fade>
  );
};

export default Dashboard;
