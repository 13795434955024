import React, { useCallback, useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PdfDocument from '../components/printable/PdfDocument';
import { useApi } from '../utils/useApi';
import { useParams } from 'react-router-dom';
import Loading from '../components/Loading';


const PreviewPdf = () => {
 
const [profile, setProfile] = useState(null)
 const api = useApi()
 const { objectName, objectSlug } = useParams()

 const getDocument = useCallback(() => {
      setProfile(null)
      api.get(`${objectName}/${objectSlug}`).then((response) => {
        setProfile(response.data)
      }).catch(console.log)

 },[objectName, objectSlug, api])

 useEffect(() => {
    getDocument()
 },[objectName, objectSlug])
 return profile ? <PDFViewer className='h-100' style={{ height: '90vh'}}>
    <PdfDocument objectName={objectName} profile={profile} />
  </PDFViewer>
  :
  <Loading />
}

export default PreviewPdf;