import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import moment from "moment";
import { Avatar, ListDivider, Typography } from "@mui/joy";
import { Comment, Create, Edit } from "@mui/icons-material";
import Content, { ContentBetween, ContentColumn, ContentStart } from '../Content';
import { useApi } from '../../../utils/useApi';
import Loading from '../../Loading';
import useTools from '../../../utils/useTools';


const tabStyles ={
    pt: 1,
    //justifyContent: 'center',
    [`&& .${tabClasses.root}`]: {
      flex: 'initial',
      bgcolor: 'transparent',
      '&:hover': {
        bgcolor: 'transparent',
      },
      [`&.${tabClasses.selected}`]: {
        color: 'primary.plainColor',
        '&::after': {
          height: 2,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          bgcolor: 'primary.500',
        },
      },
    },
  }

const StatusNotes = ({ objectName, object, serialNumber }) => {

    const [tab, setTab] = React.useState(0)

return <ContentColumn className={'flex-1 border-top-1 border-300 '} >
{ object?.id && <Tabs aria-label="Basic tabs" value={tab} onChange={(e, v) => setTab(v)}>
      <TabList sx={tabStyles}>
        <Tab>Logs</Tab>
        <Tab>Notes</Tab>
      </TabList>
      <TabPanel value={0} className="p-2 overflow-y-scroll" style={{ maxHeight: 300 }}>
        <ActionItemList objectName={objectName} object={object} serialNumber={serialNumber} />
      </TabPanel>
      <TabPanel value={1} className="p-2 overflow-y-scroll" style={{ maxHeight: 300 }}>
        <NoteList objectName={objectName} object={object} serialNumber={serialNumber}/>
      </TabPanel>
    </Tabs> }
    </ContentColumn>
}

export default StatusNotes;

const ActionItem = ({ item }) => {
  const tools = useTools()
   const created_at = item.created_at.split(".")[0].replace("T"," ")

    return <ContentStart className={"p-2 border-round mb-3 shadow-1"}>
        {
          item.action_name=='commented' ? 
         <Comment className="p-1 text-gray-500 px-2 text-sm"/> :
         item.action_name=='updated' ? <Edit className="p-1 text-gray-500 px-2 text-sm"/> : <Create className="p-1 text-gray-500 px-2 text-sm"/> 
        }
         <ContentColumn className={'flex-1'}>
            <ContentBetween>
                 <b className="text-sm">{tools.makeLabel(item.action_name)}</b>
                 <small className="text-gray-500 text-xs">{moment(created_at).fromNow()}</small>
            </ContentBetween>
            <ContentStart>
             <Avatar size='sm'>{tools.avatarize(item?.created_by?.full_name)}</Avatar>
             <Content className="ml-3">
                <Typography className="text-gray-500 text-sm">
                {tools.makeLabel(item?.created_by?.full_name)} {item.action_name} {item.action_name=='commented' ? 'on':null } {tools.makeLabel(item.relation_table.slice(0,-1))}.
                </Typography>
                <Typography className="text-gray-700 font-bold text-sm mb-2">
                    {tools.makeLabel(item?.created_by?.full_name)} 
                </Typography>

             </Content>

            </ContentStart>
            

        </ContentColumn>
    </ContentStart>

}

const NoteItem = ({ item }) => {
  const tools = useTools();

    return <ContentColumn className={'w-full flex-1'}>
            <ContentStart className={'w-full flex-1'}>
            <Avatar size='sm'>{tools.avatarize(item?.created_by?.full_name)}</Avatar>
            <ContentColumn className={"ml-3 w-full flex-1"}>
                <ContentBetween>
                <Typography className="text-gray-700 font-bold text-sm">
                    {tools.makeLabel(item?.created_by?.full_name)}
                </Typography>
                    <small className="text-gray-500 text-xs">About {moment(item.created_at).fromNow()}</small>
                </ContentBetween>
                <Typography className="text-gray-500 text-sm mb-2">
                    {item.work_notes}
                </Typography>
                <Typography className="text-xs mb-3 font-bold">On {moment(item.created_at).format('YYYY-MM-DD @ HH:mm:ss')}</Typography>
            </ContentColumn>


            </ContentStart>

            
        <ListDivider />

        </ContentColumn>

}

const ActionItemList = ({ objectName, object, serialNumber }) => {
     const [rows, setRows] = React.useState(null);
     const [loading, setLoading] = React.useState(false)
     const api = useApi()
     const tools = useTools()

     const loadData = React.useCallback(() => {
      setLoading(true)
      api.get(`systemlogs/${objectName}/${object.id}?page_object=${JSON.stringify({ limit: 10, skip: 0, ordering: '-id' })}`)
      .then((response) => {
       setRows(response.data.results)
       //console.log(response)
      }).catch(console.log)
      .finally(setLoading)
    },[objectName, serialNumber, object?.id])

    React.useEffect(() => {
      loadData()
    },[])

    React.useEffect(() => {
       loadData()
    },[serialNumber, objectName, object?.id])

     return loading ? <Loading height={100}/> : ( rows?.length > 0 ?
        <ContentColumn className={"flex w-full  flex-1"}>
            { rows.map((item, index) => {
                return <ActionItem item={item} key={index}/>
            })}
        </ContentColumn>
        :
        <Typography className={"flex-1"}>
           There are <b>0</b> { tools.makeLabel(objectName.slice(0, -1))}actions.
       </Typography>
     )
} 

const NoteList = ({ objectName, object, serialNumber }) => {
    const [rows, setRows] = React.useState(null);
    const [loading, setLoading] = React.useState(false)
    const api = useApi()
    const tools = useTools()

    const loadData = React.useCallback(() => {
      setLoading(true)
      api.get(`worknotes/${objectName}/${object.id}?page_object=${JSON.stringify({ limit: 10, skip: 0, ordering: '-id' })}`)
      .then((response) => {
       setRows(response.data.results)
       console.log(response)
      }).catch(console.log)
      .finally(setLoading)
    },[objectName, serialNumber, object?.id])

    React.useEffect(() => {
      loadData()
    },[])

    React.useEffect(() => {
       loadData()
    },[objectName, serialNumber, object?.id])

    return loading ? <Loading height={100}/> :
    (  rows?.length > 0  ?
       <ContentColumn className={"flex w-full  flex-1"}>
           { rows.map((item, index) => {
               return <NoteItem item={item} key={index}/>
           })}
       </ContentColumn>
       :
       <Typography className={"flex-1"}>
           There are <b>0</b> { tools.makeLabel(objectName.slice(0, -1))} notes.
       </Typography>
    )
} 