import { LinearProgress, Typography } from "@mui/joy";
import Content, { ContentColumn, HeaderContent, TitleValue } from "../components/layout/Content";
import IconStepper from "../components/wizard/IconStepper";
import { useCallback, useEffect, useMemo, useState } from "react";
import EditForm from "../components/formcontrols/EditForm";
import { useApi } from "../utils/useApi";
import { Check, ReadMoreTwoTone } from "@mui/icons-material";
import travelWizard from "../utils/models/travelWizard";
import CompletedScreen from "../components/wizard/CompletedScreen";
import ReviewDataScreen from "../components/wizard/ReviewDataScreen";
import { useParams } from "react-router-dom";
import { useAppContext } from "../utils/AppContext";
import { logo } from "../components/layout/Layout";
import URLInvalidScreen from "../components/wizard/URLInvalidScreen";

const getWizardByName = (wizardName) => {
    let options = {
         'traveller' : travelWizard,
    }
    return options[wizardName]
}

const WizardPage = () => {

    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)

    const [wizardData, setWizardData] = useState([])

    const [wizard, setWizard] = useState(null)
    const [wizardInvite, setWizardInvite] = useState(null)

    const { state,  dispatch} = useAppContext()

    const api = useApi();
    const { wizardName, wizardId } = useParams()

    useEffect(() => {
        setWizard(getWizardByName(wizardName))
        console.log(wizardName)
    },[wizardName])

    useEffect(() => {
         
    },[wizardInvite])

    const wizardSteps = useMemo(() => {
        if(!wizard)
            return []

        return [ 
            ...wizard, 
            { title: "Review information", icon: <ReadMoreTwoTone />, controls: [], content: <ReviewDataScreen profile={wizardData} wizardList={travelWizard} /> },
            { title: "Completion", icon: <Check />, controls: [], content: <CompletedScreen /> }
         ]
    },[wizard, wizardData])

    const loadWizardFromApi = useCallback(() => {
        setLoading(true)
        dispatch({ type: 'setItem', name:'loading', value: true })
        api.get(`/traveller_invites/by-uuid/${wizardId}/`)
        .then((response) => {
            setWizardInvite(response.data)
            console.log(response.data)
        })
        .catch((e) => {
            console.log(e) 
        }).finally(() => {
            dispatch({ type: 'setItem', name:'loading', value: false })
            setLoading(false)
        })

    },[api, wizardId])
    
    useEffect(() => {
        loadWizardFromApi()
        console.log("Initial load", wizardId)
    },[wizardId, loadWizardFromApi])

    const handleNextStep = (data) => {
        let previousData = wizardData
        previousData[activeStep] = data

        setWizardData(previousData)
        if(activeStep===(wizardSteps.length-2))
        { 
            let data = {}
            previousData.forEach(d => {
               data = { ...data, ...d }
            })
            console.log(data)
            api.post(`traveller_invites/${wizardId}/create_data/`, data)
            .then((response) => {
                setActiveStep(prev => prev+=1)
            }).catch((e) => {
               console.log(e)
            })

        } else {        
            setActiveStep(prev => prev+=1)
        }

    }

    const handlePreviousStep = (data) => {
        setActiveStep(prev => prev-=1)
    }


    const activeScreen = useMemo(() => {
           return wizardSteps[activeStep]
    },[activeStep, wizardSteps])

    const buttons = useMemo(() => {
           return { 
            cancel :  { 
                label : `Previous`,
                disabled: activeStep === 0 
            }, 
            accept : { 
                label: activeStep === (wizardSteps.length -2) ? 'Complete' : 'Continue',
                disabled: activeStep === (wizardSteps.length - 1) 
            }
        }
    },[activeStep, wizardSteps])

    if(state.loading)
    return (
        <Content className="w-screen h-screen flex justify-content-center align-items-center flex-column">
                <img src={logo} alt="Reatswelela travel - Webdesk" className='p-2 w-8rem'/>
                <Content className="w-20rem mt-3">
                   <LinearProgress variant="soft"  thickness={5} />
                </Content>
        </Content>
    )


    if(!wizardInvite) return (
        <URLInvalidScreen />
    )

    return (
        <ContentColumn className={"w-full pt-5"}>
        <Content className="col-12 col-md-10 md:col-10 mx-auto px-3">
            <HeaderContent className={" justify-content-center align-items-center"} header="Create traveller profile" subheading={"Please complete the form below to update your profile."} />
            <Typography className="text-center text-xs text-gray-500">By filling this form, you hereby authorize <b>Reatswelela travel & Tours (Pty) Ltd</b> to use this information to provide services as per their contract with your organization.</Typography>
        </Content>

        <Content className="w-full flex flex-column justify-content-center align-items-center mt-6">

            <IconStepper currentStep={activeStep} wizardSteps={wizardSteps} wizardData={wizardData} />

           { activeScreen && <Content className="my-4 py-6 col-12 col-md-9 md:col-9 shadow-2 border-round-md p-6 bg-white">

                { activeStep < (wizardSteps.length -2) && <TitleValue loading={loading} title={activeScreen?.title} value="Complete the form below to continue" /> }
                { activeStep === (wizardSteps.length -2) && <TitleValue loading={loading} title={"Confirm your profile"} value="Please verify your information below and click complete to save the data." /> }

                <EditForm 
                    loading={loading} 
                    options={activeScreen?.controls} 
                    profile={wizardData[activeStep]||{}}
                    buttons={buttons} 
                    saveOnComplete={false} 
                    onComplete={handleNextStep}
                    onCancel={handlePreviousStep}
                    hideActions={activeStep === (wizardSteps.length -1)}
                    />

                    { activeScreen?.content }
            </Content> }


            <Typography className="text-xs text-gray-500">By filling this form, you hereby authorize <b>Reatswelela travel & Tours (Pty) Ltd</b> to use this information to provide services as per their contract with your organization.</Typography>

        </Content>
        </ContentColumn>

    )
}

export default WizardPage;



