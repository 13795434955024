import { CircularProgress } from "@mui/joy"
import { ContentColumn } from "./layout/Content"

const Loading = ({ height=100, size='md', children }) => {
    return (
        <ContentColumn className={'w-full justify-content-center align-items-center flex-column'} style={{ height }}>
                <CircularProgress variant="soft" size={size} />
                {children}
        </ContentColumn>
    )
}

export default Loading;