import { Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, LinearProgress, Option, Select, Stack, Textarea } from "@mui/joy";
import Content, { ContentBetween, ContentColumn, ContentEnd } from "../../components/layout/Content";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useApi } from "../../utils/useApi";
import { useFormik } from "formik";
import * as Yup from 'yup';
import useTools from "../../utils/useTools";
import AssignSettingWidget from "./AssignSettingWidget";
import moment from "moment";
import AppSnackBar from "../layout/AppSnackBar";
import PasswordMeterInput from "./PasswordField";
import CreateWorknote from "../layout/modals/CreateWorknote";

import { AppDateField, AppDateTimeField } from "./JoyDatePicker";
import AppAutocomplete from "./AppAutocomplete";
import SlushBucket from "./SlushBucket";
import { formatCurrency } from "../lists/GenericList";


const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss'
const dateFormat = 'YYYY-MM-DD'

const fieldLengths = {
    id: 2,
    title: 1,
    order: 1,
}

export const initialValues = (initialSchemaValues, options, profile) => {
    
    if(initialSchemaValues)
      return initialSchemaValues

    let controls = options?.properties ?  Object.keys(options?.properties) : options

    let obj = {}
    controls?.forEach((control) => {
        obj[control?.name] = profile[control?.name] ? profile[control?.name] : control?.default ? control?.default : null
    })
    return obj
}

export const memoValidationSchema  = (validationSchema, tools, options) => {
    if(validationSchema)
        return validationSchema

    let obj = {}

    //console.log(options)
    let controls = options?.properties ?  Object.keys(options?.properties) : options


    controls?.forEach(control => {

        if(!['boolean'].includes(control?.type)) {
                if(control?.name==='email' && control?.required) {
                obj[control?.name] = Yup.string()
                    .email(`A valid email is required`)
                    .required(`${tools.makeLabel(tools.makeLabel(control?.title))} is required`)
                }
                else if (control?.type === 'related' || control?.type === 'autocomplete' ) {
                    if(control.required)
                        obj[`${control?.name}_id`] = Yup.string()
                              .required(`${tools.makeLabel(control?.title)} is required`)
                }
                else if (control?.type === 'array') {
                    obj[control?.name] = Yup.array()
                }
                else if (control?.name == 'password' && control?.required) {
                    obj['password'] = Yup.string()
                    .min(8, 'Password too short!')
                    .matches(/[0-9]/, 'Password requires a number')
                    .matches(/[a-z]/, 'Password requires a lowercase letter')
                    .matches(/[A-Z]/, 'Password requires an uppercase letter')
                    .matches(/[^\w]/, 'Password requires a symbol')
                } else if (control?.name == 'confirm_password' && control?.required) {
                     obj['confirm_password'] = Yup.string().required('Confirm password is required')
                    .oneOf([Yup.ref('password'), null], 'Passwords do not match')
                }
                else if(control?.required){
                    obj[control?.name] = Yup.string()
                    .min(fieldLengths[control?.name]||2, `${tools.makeLabel(control?.title)} is too short`)
                    .max(140, `${tools.makeLabel(control?.title)} is too long`)
                    .required(`${tools.makeLabel(control?.title)} is required`)
                }
        }
    })
    
    return Yup.object().shape(obj)
    
}


const EditForm = ({ 
      hideActions, 
      profile, 
      options, 
      createMode=true, 
      objectName="object", 
      saveOnComplete=true,  
      validationSchema=null,
      initialSchemaValues=null,
      onCancel = f => f, 
      onComplete = f => f, 
      clearOTP = f => f,
      permissions,
      buttons ,
      ...props
    }) => {
    const [profileData, setProfileData] = useState(null);

    const [editForm, setEditForm] = useState();
    const [loader, setLoader] = useState(false)

    const api = useApi(); 
    const tools = useTools();

    const [triggerToast, setTriggerToast] = useState({
        message: '',
        counter: 0,
        color: 'success'
    })

    const showToast = (message, type='primary') => {
        setTriggerToast(prev => ({ message: message, counter: prev.counter+1, color: type }))
    }
    
    const hideToast = () => {
        setTriggerToast(prev => ({ message: null, counter: 0 }))
    }

    
    useEffect(() => {
       //console.log(options)
       if(!options)
            return
       
        if(options?.properties) {
          setEditForm({
           controls: Object.keys(options?.properties).map((key) => {
               return { ...options?.properties[key], name: key } //Convert to array
           }),
       })
        } else {
            setEditForm({
                controls: options
            })
        }
 
    },[options])

    useEffect(() => {
       console.log(permissions)
    },[permissions])

    const handleSystemLog = (data) => {
        api.post(`systemlogs/`, data).then(console.log).catch(console.log)
    }

    
    const saveEditedData = useCallback((data) => {
        let method;
        if(data?.id) {
            method =  api.put(`${objectName}/${data?.slug||data?.id}`, data)
        }
        else {
            method =  api.post(`${objectName}/`, data)
        }

        method
        .then((response) => {
            showToast("Data updated successfully")
            if(profile?.id)
                setProfileData(response.data) //Update this only when in update mode to prevent multi create bug

            let action_name;

            if(profile?.id) {
                action_name = "updated"
            } else {
                action_name = "created"
            }

            handleSystemLog({ relation_table: objectName, relation_table_id: response.data.id ?? 0, action_name })
            if(createMode) {
                formik?.setValues({})
                formik?.setTouched({})
             }
            onComplete(response.data)

        })
        .catch((e) => {
           console.log(e)
            showToast(e.message, "danger")
        })
        .finally(setLoader)
    },[api])

    
    const formik = useFormik({
        initialValues: initialValues(initialSchemaValues, options, profile),
        validationSchema: memoValidationSchema(validationSchema, tools, options),
        validateOnBlur: true,
        onSubmit: (data) => {
            setLoader(true)
           //console.log(data)
            if(saveOnComplete) {
                saveEditedData(data)
            } else {
                onComplete(data)
                setLoader(false)
            }
            
        }
    })

    useEffect(() => {
        if(profile?.id)
           formik?.setValues(profile)
       //console.log(profileData)
      if(!profileData)
          return setProfileData(profile)
    
      if(profile instanceof Object)
      {
         let isEqual = true;
         Object.keys(profile).forEach((key) => {
            isEqual = isEqual && (profile[key] === profileData[key])
         })
      } else {
        setProfileData(profile)
      }
    },[profile])

    const buttonData = useMemo(() => {
        let obj = `${tools.makeLabel(objectName.slice(0,-1))}`
        return buttons ? buttons : { cancel :  { label : `Cancel` }, accept : { label: profileData?.id ? `Update ${obj}` : `Create ${obj}` }}
    },[buttons, profileData])

    const booleans = ['boolean']
    const contact_confirmations = ['email_confirmed','phone_confirmed']

    const exclusively = (list=[], item) => {
        return list.includes(item)
      }

      const formatDate = (value, control) => {
        if(!value)
            return ''
        if(control.type == 'date')
        {
            if(value)
               return moment(value).format('YYYY-MM-DD')
        }
        if(!value)
            return moment(value.split(".")[0]).format('YYYY-MM-DD hh:mm:ss')
      }


      const no_permissions = useMemo(() => {
            console.log(permissions)
           return profile?.id ? !permissions?.update : !permissions?.create
      },[profile, permissions])



    return <>

          { triggerToast?.message && <AppSnackBar
                   openCount={triggerToast.counter} 
                   message={triggerToast.message} 
                   type={triggerToast.color} 
                   onClose={hideToast}
         /> }

    { formik?.values ? <ContentColumn className="bg-white">
    
     <form onSubmit={formik?.handleSubmit} noValidate>
     { formik?.errors.length && <p>This form has {formik?.errors.length} errors</p>}

      <Content className="row row-cols-3">
           { editForm?.controls?.filter(c => c.type != 'boolean')?.map((control, key) => {
            let visual_error 
            if(['related','autocomplete'].includes(control.type))
            {
                visual_error = formik?.errors && formik?.errors[`${control?.name}_id`] ? " border-red-600 " : ""
            } else {
                visual_error = formik?.errors && formik?.errors[control?.name] ? " border-red-600 " : ""
            }

            let read_only = control.read_only || no_permissions
            return (
         <Fragment key={key}>
          { control?.new_line && <Content className={`col-${control?.new_line} my-0 py-0`}></Content>}

           { ['string','text','email'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Input size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formik?.values[control?.name]||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['currency'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Input 
                    startDecorator={ control.subtype=='currency' ? profile.currency_symbol : null }
                    size="sm" 
                    disabled={read_only} 
                    className={visual_error} 
                    name={control?.name} 
                    type={control?.type} 
                    placeholder={control?.title} 
                    value={formatCurrency(formik?.values[control?.name])||''} 
                    onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['number', ].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||3} md:col-${control?.size||3}`}  >
                { !props.noLabel && <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>}
                { console.log(formik?.values[control?.name]) }
                <Input 
                    size="sm" 
                    sx={{ minWidth: 120 }}
                    startDecorator={ control.subtype=='currency' ? profile.currency_symbol : null }
                    disabled={read_only} 
                    className={visual_error} 
                    name={control?.name} 
                    type={control?.type} 
                    placeholder={control?.title} 
                    value={formik?.values[control?.name]===undefined||formik?.values[control?.name]===null ? '' : formik?.values[control?.name]} 
                    onChange={formik?.handleChange}
                    slotProps={{
                        input: {
                          min: control.min,
                          step: control.step
                        },
                      }}
                    />
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['password'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <PasswordMeterInput size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formik?.values[control?.name]||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['date'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Input size="sm" disabled={read_only} className={visual_error} name={control?.name} type={control?.type} placeholder={control?.title} value={formatDate(formik?.values[control?.name], control)||''} onChange={formik?.handleChange}/>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

            { ['datetime'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                    <AppDateTimeField views={['year', 'day', 'hours', 'minutes']} size="sm" disabled={read_only} className={"w-full " + visual_error} value={formatDate(formik?.values[control?.name], control)||''} name={control?.name} type={control?.type} placeholder={control?.title} onChange={(e) => { console.log(e.$d); formik?.setFieldValue(control?.name, moment(e.$d).format(dateTimeFormat) )}} />
                    {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> } 

        {
         ['related','autocomplete'].includes(control.type) &&
         <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}>
             <FormLabel className="font-bold">{control.title}{ control.required && <span className='text-red-600'>*</span>}</FormLabel>
             <AppAutocomplete
                 getOptionLabel={option => option.name||option}  
                 type={'text'} 
                 size="sm"
                 field={control}
                 className={visual_error} 
                 placeholder={control.title||control.name} 
                 name={control.name} 
                 onChange={(e,v) =>  { 
                    formik?.setFieldValue(control.name, v);
                    if(v instanceof Object) {
                        formik?.setFieldValue(`${control.name}_id`, v?.id);
                        formik?.setFieldValue(`${control.name}_email`, v?.email);
                    }
                    console.log(formik?.values)
                }} 
                 value={formik?.values[control?.name]}
                 disabled={read_only}
             />
             {formik?.errors[`${control?.name}_id`] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[`${control?.name}_id`]}</small>) : null}
          </FormControl>
       }
 
 
 
 
      {
         !createMode && ['multiselect'].includes(control.type) && 
         <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}>

         <SlushBucket 
         onChange={(v) => formik?.setFieldValue(control.name, v)} 
         field={control} 
         profile={profile} 
         objectName={objectName} 
         value={formik?.values[control?.name]||[]}
         />
         </FormControl>

 
       }


        { ['textarea',].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Textarea
                size="sm"
                    className={visual_error}
                    name={control?.name}
                    disabled={read_only}
                    placeholder={control?.title}
                    required={control?.required}
                    value={formik?.values[control?.name]||control?.default||''} 
                    onChange={formik?.handleChange}
                    minRows={3}
                    maxRows={5}
                />
                { control?.helptext && <FormHelperText>{control?.helptext}</FormHelperText> }
               {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> }

           

            { ['select'].includes(control?.type) && 
           ( control?.depends ? formik?.values[control?.depends] : true ) &&
            <FormControl className={`mt-0 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`} >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Select
                       size="sm"
                       className={visual_error}
                        onChange={(e, newValue) => formik?.setFieldValue(control?.name, newValue)}
                        value={formik?.values[control?.name]!=null ? formik?.values[control?.name] : control?.default}
                        placeholder={control?.title}
                        name={control?.name}
                        required={control?.required}
                        disabled={read_only}
                        sx={{ minWidth: 50 }}
                        >
                       { control?.choices.map((option,  key) => <Option  key={key} value={ option.value==null ? option : option.value }>{option.label == null ? option : option.label }</Option>) } 
                    
                </Select>
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> }

            { ['boolean'].includes(control?.type) && <FormControl className={`mb-0 pt-1 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}>
            <FormLabel className="font-semibold">&nbsp;</FormLabel>
               <Checkbox label={control?.title} checked={formik?.values[control?.name]||false} onChange={(e) => formik?.setFieldValue(control?.name, e.target.checked )} />
            </FormControl> }

            </Fragment>

           )}
           )}
      </Content>

     { !hideActions && <ContentEnd className="my-4">
          <Button size="sm" loading={loader} disabled={buttonData?.cancel?.disabled} className="mr-2" variant="soft" color="neutral" onClick={() => onCancel()} >{buttonData?.cancel?.label}</Button>
          { profile?.id && objectName  ? <CreateWorknote buttonLabel={buttonData?.accept?.label} disabled={buttonData?.accept?.disabled} loading={loader} onComplete={formik?.submitForm} profile={profile} objectName={objectName}/>
           : <Button size="sm" type="submit" loading={loader} disabled={buttonData?.accept?.disabled||no_permissions} onClick={() => console.log(formik?.ini)} >{buttonData?.accept?.label}</Button>
            }
      </ContentEnd> }


      { !createMode && <ContentColumn className={"col-md-12 "}>
                                 { profileData && profile?.id && editForm?.controls?.map((control, index) =>  { 
                                    let value = profileData[control.name]
                                    if(exclusively(booleans, tools.fieldType(control)))
                                    {
                                        return exclusively(contact_confirmations, control) ? 
                                        (
                                            <ContentBetween className={"col-12 col-md-12 mb-0"} key={index}>
                                                <AssignSettingWidget field={control} value={value} role={control.name} onChange={clearOTP} userId={profile} objectName={objectName}>
                                                    <FormControl>
                                                            <Checkbox label={control.title} checked={value} disabled={true} />
                                                    </FormControl> 
                                                </AssignSettingWidget>
                                            </ContentBetween> 
                                        )
                                        :
                                        (
                                        <ContentBetween className={"col-12 md:col-12 mb-0"} key={index}>
                                            <AssignSettingWidget field={control} value={value} role={control.name} onChange={setProfileData} userId={profile?.id} objectName={objectName}>
                                                <FormControl>
                                                        <Checkbox label={control.title} checked={value} disabled={true} />
                                                </FormControl> 
                                            </AssignSettingWidget>
                                        </ContentBetween> 
                                        )
                                    }
                                /* This is where the options map ends */
                                }
                                ) }
                                </ContentColumn>  }
     </form>


    </ContentColumn>
    :
    <ContentColumn className="bg-white">
             <LinearProgress className="h-3rem w-30rem mx-auto"  />
    </ContentColumn> }
    </>
}

export default EditForm;