import * as React from 'react';
import Button from '@mui/joy/Button';
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import { CheckCircle } from '@mui/icons-material';
import { BsCheck2Circle, BsExclamationTriangle, BsInfoCircle } from 'react-icons/bs';


const getIcon = (color) => {
    switch (color) {
        case 'primary': return <BsInfoCircle fontSize={24} />
        case 'success': return <BsCheck2Circle fontSize={24} />
        case 'danger': return <BsExclamationTriangle fontSize={24} />
        case 'warning': return <BsExclamationTriangle fontSize={24} />
    }
}
export default function AppSnackBar({ openCount=0, type='success', position='top', test_mode=false, message="Sample toast triggerd", onClose = f => f }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
      if(openCount>0)
         setOpen(true)
  },[openCount])

  return (
    <React.Fragment>
      { test_mode && <Button variant="outlined" color="neutral" onClick={() => { 
        setOpen(true) 
        
        }}>
        Show Snackbar
      </Button> }

      <Snackbar
        variant="soft"
        color={type}
        open={open}
        autoHideDuration={7000}
        //resumeHideDuration={true}
        onClose={() => { 
            setOpen(false) 
            onClose(null)
        }}
        anchorOrigin={{ vertical: position, horizontal: 'center' }}
        startDecorator={getIcon(type)}
        endDecorator={
          <Button
            onClick={() => setOpen(false)}
            size="sm"
            variant="soft"
            color={type}
          >
            Dismiss
          </Button>
        }
      >
        {message}
      </Snackbar>
    </React.Fragment>
  );
}