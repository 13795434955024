import React from 'react';
import { Page, Font, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import useTools from '../../utils/useTools';
import real_logo from "../../assets/logo.png";
import moment from 'moment';
import { formatCurrency } from '../lists/GenericList';
import { Button } from '@mui/joy';

const dateFormat = 'DD/MM/YYYY'

Font.register({
    family: 'Roboto',
   // src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
   src: 'https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrEzQdL-vwnYh2eg.woff2'
});

export const logo = real_logo;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 20,
    fontFamily: 'Roboto'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    textAlign: 'center'
  },
  header: {
    width: '100%',
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  bodytext: { fontSize: 9, textAlign: 'left', fontWeight: 300, lineHeight: 1.3 },
  w30 : { width: 30, paddingHorizontal: 5 },
  w60 : { width: 60, paddingHorizontal: 5 },
  w100 : { width: 100, paddingHorizontal: 5 },
  wdescription : { flex: 1, paddingHorizontal: 5},
  wsubtotal : { width: 100, textAlign: 'right', paddingHorizontal: 5},
});

// Create Document Component
const PdfDocument = ({ objectName, profile }) => {
    const tools = useTools()

return (
  <Document title={profile.number} author={profile.created_by.full_name} subject={`${profile.customer.name} ${objectName.slice(0,-1)} - ${profile.number}`}>
    <Page size="A4" style={styles.page}>

    <View style={styles.header}>

    <Image src={logo} style={{ width: 180, marginHorizontal: 'auto', marginTop: 0 }} />
    <View style={{ fontSize: 9, textAlign: 'center', boxShadow: '0 0 0 1px #9c9c9c', marginBottom: 20, lineHeight: 1.3 }}>
        <Text style={{ fontSize: 12, marginBottom: 5 }}>Hands on Partner</Text>
        <Text>Tel.: {profile.tenant.contact_number }, {profile.tenant.fax_number ? `Fax.: ${profile.tenant.fax_number} ,` : null } Email.: {profile.tenant.email}</Text>
        <Text>Reg No.: 2015/011610/07{profile.tenant.vat_number ? `, VAT. ${profile.tenant.vat_number}` : null }</Text>
        <Text style={{ fontSize: 18, marginVertical: 5 }}>{profile.is_proforma ? 'PROFORMA ':null}{tools.makeLabel(objectName.slice(0,-1)).toUpperCase()}</Text>
    </View>
    </View>


    <View style={{ fontSize: 9, textAlign: 'left', fontWeight: 300, lineHeight: 1.3 }}>
        <Text>{profile.customer.name}</Text>
        <Text>Attention: {profile.customer.email}</Text>
        <Text style={{ fontWeight: '600' }}>Reference: {profile.reference||'No reference'}</Text>
    </View>

    <View style={{ ...styles.bodytext, marginTop: 20, marginBottom: 20, justifyContent: 'space-between', alignItems: 'flex-start', flexDirection:'row' }}>
        <View>
            <Text>{`${tools.makeLabel(objectName.slice(0,-1))} to:`}</Text>
            <Text>{profile.customer.name}</Text>
            <Text>{profile.customer.address}</Text>
            <Text>VAT.: {profile.customer.vat_number}</Text>
        </View>
        <View>
            <Text style={{ textAlign: 'right'}}>{`${tools.makeLabel(objectName.slice(0,-1))} from:`}</Text>
            <Text>{profile.tenant.name}</Text>
            <ValuePaired label={`${tools.makeLabel(objectName.slice(0,-1))} number:`} value={profile.number}/>
            <ValuePaired label={`${tools.makeLabel(objectName.slice(0,-1))} date:`} value={moment(profile.created_at).format(dateFormat)}/>
            <ValuePaired label={`Due date:`} value={moment(profile.due_date).format(dateFormat)}/>
            <ValuePaired label={`${tools.makeLabel(objectName.slice(0,-1))} status:`} value={profile.status}/>
        </View>
    </View>

<TableRow header={true} />
{ profile.items.map((item, key) => <TableRow key={key} row={item} /> )}

<View style={{ marginTop: 20}} />

<TableFooterRow label={"Subtotal"} value={profile.subtotal} profile={profile}/>
<TableFooterRow label={"Vat Amount"} value={profile.vat_amount} profile={profile}/>
<TableFooterRow label={"Grand Total"} value={profile.grand_total} border={true} profile={profile} />

<View style={{ marginTop: 10 }}>
<Text style={styles.bodytext}>Terms:</Text>
<Text style={styles.bodytext}>{profile.terms}</Text>
</View>

<View style={{ ...styles.bodytext, marginTop: 10, marginBottom: 10, justifyContent: 'space-between', alignItems: 'flex-start', flexDirection:'row' }}>
<View>
    <Text>Banking Details:</Text>
    <Text>Bank name: {profile.tenant.bank_name}</Text>
    <Text>Account no.: {profile.tenant.bank_account_number}</Text>
    <Text>Account type: {profile.tenant.bank_account_type}</Text>
    <Text>Branck code: {profile.tenant.bank_branch_code}</Text>
</View>
<View>
    <Text>Contact details:</Text>
    <Text>{profile.tenant.name}</Text>
    <Text>Email: {profile.tenant.email}</Text>
    <Text>Tel: {profile.tenant.contact_number}</Text>
    <Text>{profile.tenant.address}</Text>
</View>
</View>

<Text style={{ ...styles.pageNumber }} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} fixed />

</Page>
</Document>
)
}

export default PdfDocument;

const ValuePaired = ({ label, value}) => {
    return <View style={{ width: 200, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ textAlign: 'left'}}>{label}</Text>
            <Text style={{ textAlign: 'right'}}>{value}</Text>
    </View>
}

const TableRow = ({ row, header=false }) => {
   return  <View style={{...styles.bodytext, lineHeight: 1,  flexDirection: 'row', paddingVertical: 5, justifyContent: 'flex-start', boxShadow: '0 0 0 2px #9c9c9c' }}>
    <View style={styles.w30}>
       <Text>{ header ? 'Qty' : row.quantity }</Text>
    </View>
    <View style={styles.w100}>
        <Text>{ header ? 'Item' : row.item }</Text>
    </View>
    <View style={styles.wdescription}>
        <Text>{ header ? 'Description' : row.description }</Text>
   </View>
   <View style={styles.wsubtotal}>
        <Text>{ header ? 'Unit price' : `${row.currency_symbol} ${formatCurrency(row.unit_price)}`}</Text>
   </View>
   <View style={styles.wsubtotal}>
        <Text>{ header ? 'Subtotal' : `${row.currency_symbol} ${formatCurrency(row.subtotal)}` }</Text>
   </View>
</View>
}

const TableFooterRow = ({ profile, label, value, border}) => {
    return  <View style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
        <View style={{ padding: 5 }}></View>
    <View style={{...styles.bodytext, lineHeight: 1,  flexDirection: 'row', paddingVertical: 5, borderBottom: `${border ? "1px solid #9c9c9c" : null}`, justifyContent: 'flex-end', width: 160 }}>
    <View style={{ ...styles.w60, textAlign: 'right'}}>
         <Text>{label}</Text>
    </View>
    <View style={styles.wsubtotal}>
         <Text>{profile.currency_symbol} {formatCurrency(value)}</Text>
    </View>
 </View>
 </View>
 }