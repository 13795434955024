import { useCallback, useEffect, useMemo, useState } from "react"
import Content, { ColumnValuePair, ContentBetween, ContentColumn } from "./Content";
import { formatCurrency } from "../lists/GenericList";
import { Badge } from "@mui/joy";
import { useApi } from "../../utils/useApi";
import Loading from "../Loading";

const CustomerHeader = ({ profile, objectName }) => {
    const [headerSummary, setHeaderSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const api = useApi();

    const getCustomerSummary = useCallback(() => {
        setLoading(true)
         api.get(`${objectName}/${profile.slug}/get-summary`)
         .then(response => {
            setHeaderSummary(response.data)
            console.log(response.data)
         }).catch(e => {
            console.log(e)
         }).finally(() => setLoading(false))
    },[profile?.slug, objectName, api])

    useEffect(() => {
      if(!profile)
          return
          getCustomerSummary();
    },[profile])

    const outstanding_balance = useMemo(() =>{
        let pending = headerSummary?.invoice_status_value?.Pending || 0
        let overdue = headerSummary?.invoice_status_value?.Overdue || 0
        return pending + overdue
    }, [headerSummary?.invoice_status_value])
    
    return headerSummary ? (
        <Content className="m-3 border-1 border-300 shadow-2 border-round-sm bg-white p-3">
            <ContentColumn>
                <ContentBetween className={"mt-3"}>
                   <ContentColumn className={"col-2 p-0"}>

                        <Content className="mr-7 mb-3">Total quotations <Badge color="neutral" variant="soft" size="sm" className="mx-3" badgeContent={headerSummary.total_quotations||"0"}/></Content> 
                        <ContentBetween className={""}>
                            <ColumnValuePair labeSize="text-sm" value={"Available Credit"} label={formatCurrency(profile?.available_credit||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                            <ColumnValuePair labeSize="text-sm" value={"Total Credits"} label={formatCurrency(profile?.total_credits||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                        </ContentBetween>

                   </ContentColumn>
                   
                   <ContentColumn className={"col-4 p-0"}>
                        <Content className="mr-7 mb-3">Credit notes <Badge color="neutral" variant="soft"  size="sm" className="mx-3" badgeContent={headerSummary.total_credit_notes||"0"}/></Content> 
                        <ContentBetween className={""}>
                        <ColumnValuePair labeSize="text-sm" value={"Outstanding balance"} label={formatCurrency(outstanding_balance||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                        <ColumnValuePair labeSize="text-sm" value={"Balance payable"} label={formatCurrency(profile.balance_payable||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                        <ColumnValuePair labeSize="text-sm" value={"Credit notes"} label={formatCurrency(headerSummary?.credits_status_value?.Approved||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                        </ContentBetween>

                   </ContentColumn>

                   <ContentColumn className={"col-4 p-0"}>
                        <Content className="mr-7 mb-3">Invoices <Badge color="neutral" variant="soft" size="sm" className="mx-3" badgeContent={headerSummary.total_invoices||"0"}/></Content> 
                        <ContentBetween className={""}>
                           <ColumnValuePair labeSize="text-sm" badge={"green"} value={"Paid"} label={formatCurrency(headerSummary?.invoice_status_value?.paid||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                           <ColumnValuePair labeSize="text-sm" badge={"blue"} value={"Pending"} label={formatCurrency(headerSummary?.invoice_status_value?.Pending||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                           <ColumnValuePair labeSize="text-sm" badge={"red"} value={"Overdue"} label={formatCurrency(headerSummary?.invoice_status_value?.Overdue||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                           <ColumnValuePair labeSize="text-sm" badge={"gray"} value={"Draft"} label={formatCurrency(headerSummary?.invoice_status_value?.Draft||'0', profile?.currency_symbol)} loading={loading}></ColumnValuePair>
                        </ContentBetween>
                   </ContentColumn>
                </ContentBetween>
            </ContentColumn>

          </Content>
    ) : <Content className="m-3 border-1 border-300 border-round-sm bg-white p-3"><Loading /></Content>
}

export default CustomerHeader;