import {  ChangeCircle, Check, Close } from "@mui/icons-material";
import * as React from 'react';
import { BsPencilFill } from "react-icons/bs";
import { useAppContext } from "../../utils/AppContext";
import DiscardModal from "../layout/modals/DiscardModal";
import useTools from "../../utils/useTools";
import { ContentBetween, ContentColumn, ContentStart } from "../layout/Content";
import AppSnackBar from "../layout/AppSnackBar";

let twoFAtext = "Two-factor authentication (2FA) provides an additional layer of security beyond passwords and is strongly recommended. Your account is protected by requiring both your password and an authentication code from an authenticator app."

const AssignSettingWidget = ({ children, objectName, field = {}, userId, role, value, onChange = f => f }) => {
    const tools = useTools()
    const { state } = useAppContext()

    const handleRole = (role, value) => {
      let action = value ? 'revoke_role' : 'assign_role'
      tools.dataFromUrl(`${objectName}/${userId}/${action}/${role}`)
      .then((data) => {
        console.log(data)
        showToast(`${role} was updated successfully`)
        onChange(data)
      })
      .catch(e => {
        console.log(e)
        showToast(`${role} could not be updated`, "danger")
      })
    }

    const handleConfirm = (role, value) => {
      if(value) {
        showToast(`${role} could not be updated`, "danger")
        return
      }

      tools.dataFromUrl(`users/${state?.currentUser?.id}/confirm_contact/${role}`)
      .then((data) => {
        console.log(data)
        showToast(`${role} was updated successfully`)
        onChange(data)
      }).catch(
        () => {
            showToast(`${role} could not be updated`, "danger")
        }
      )
    }

    let not_roles = ["phone_confirmed","email_confirmed"]
    let two_factor = ["two_factor_auth",]

    const [triggerToast, setTriggerToast] = React.useState({
        message: '',
        counter: 0,
        color: 'success'
    })


    const showToast = (message, type='primary') => {
        setTriggerToast(prev => ({ message: message, counter: prev.counter+1, color: type }))
    }
    
    const hideToast = () => {
        setTriggerToast(prev => ({ message: null, counter: 0 }))
    }

    return <ContentBetween className={"flex flex-1 shadow-1 p-2 my-1 align-items-center"}>

{ triggerToast?.message && <AppSnackBar
                   openCount={triggerToast.counter} 
                   message={triggerToast.message} 
                   type={triggerToast.color} 
                   onClose={hideToast}
         /> }

         <ContentColumn className={"text-bluegray-500 col-7"}>
            {children}
            <ContentStart className={"mt-1"}>
            { two_factor.includes(role) ? twoFAtext : value ? `${tools.titleCase(objectName).slice(0, -1)} is currently ${tools.inContext(role).toLowerCase()}` : `${tools.titleCase(objectName).slice(0, -1)} is currently not ${tools.inContext(role).toLowerCase()}`}
            </ContentStart>
         </ContentColumn>

            {
              not_roles.includes(role) ? <DiscardModal
              onConfirmAction={() => handleConfirm(role, value)}
              config={{
                color: 'primary',
                variant: 'solid',
                icon: value ? <Check size='sm' className="hidden md:block"/> : <ChangeCircle className="hidden md:block"/>,
                disabled: value ? true : false,
                action: value ? field.title : tools.reverseText(field.title),
                prompt: value ? `You are about to "${tools.negateReversed(field.title)}", are you sure?` : `You are about to "${tools.reverseText(field.title)}", are you sure?`,
                className: value ? "bg-gray-200 hover:bg-gray-300 text-gray-800" : null
              }}/> : 
              two_factor.includes(role) ?
              <DiscardModal 

              onConfirmAction={() => handleRole(role, value)}
              config={{
                    color: 'primary',
                    variant: 'solid',
                    icon: value ? <Close size='sm' className="hidden md:block"/> : <BsPencilFill className="hidden md:block" />,
                    action: value ? "Deactivate 2FA" :  "Activate 2FA",
                    prompt: value ? `You are about to deactivate security "${field.title}", are you sure?` : `You are about to activate security "${field.title}", are you sure?`,
                    className: value ? "bg-gray-200 hover:bg-gray-300 text-gray-800" : null
                }}
              />
              :
              <DiscardModal 

              onConfirmAction={() => handleRole(role, value)}
              config={{
                    color: 'primary',
                    variant: 'solid',
                    icon: value ? <Close size='sm' className="text-sm hidden md:block"/> : <Check size='sm' className="text-sm hidden md:block"/>,
                    action: value ? `Deactivate role` :  `Activate role`,
                    prompt: value ? `You are about to revoke role "${field.title}", are you sure?` : `You are about to assign role "${field.title}", are you sure?`,
                    className: value ? "bg-gray-200 hover:bg-gray-300 text-gray-800" : null
                }}
              />
            }
         
    </ContentBetween>
  }

  export default AssignSettingWidget;