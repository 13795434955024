import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import Typography from '@mui/joy/Typography';
import Key from '@mui/icons-material/Key';
import { IconButton } from '@mui/joy';
import { LockOpen, LockOutlined } from '@mui/icons-material';

export default function PasswordMeterInput({ type, ...props }) {
  const minLength = 12;
  const [showPassword, setShowPassword] = React.useState(false)

  const ShowHideIcon = () => (<IconButton onClick={() => setShowPassword(e => !e)}>{showPassword ? <LockOpen/> : <LockOutlined/>}</IconButton>   )
  return (
    <Stack
      spacing={0.5}
      sx={{
        '--hue': Math.min(props.value.length * 10, 120),
      }}
    >
      <Input
        type={ showPassword ? "text": "password"} 
        startDecorator={<Key />}
        { ...props }
        endDecorator={<ShowHideIcon />}
      />
      <LinearProgress
        determinate
        size="sm"
        value={Math.min((props.value.length * 100) / minLength, 100)}
        sx={{
          bgcolor: 'background.level3',
          color: 'hsl(var(--hue) 80% 40%)',
        }}
      />
      <Typography
        level="body-xs"
        sx={{ alignSelf: 'flex-end', color: 'hsl(var(--hue) 80% 30%)' }}
      >
        {props.value.length < 3 && 'Very weak'}
        {props.value.length >= 3 && props.value.length < 6 && 'Weak'}
        {props.value.length >= 6 && props.value.length < 10 && 'Strong'}
        {props.value.length >= 10 && 'Very strong'}
      </Typography>
    </Stack>
  );
}