import { identityInformation, personalInfo, travellerPreferences } from "./travelWizard";

const inviteCreate = [
        { 
            title: "Title", 
            name: 'title', 
            required: true,
            type: 'select',
            choices: ["Mr", "Mrs", "Miss", "Ms", "Sir", "Prof", "Dr", "Hon", "Adv", "Rev", "Cllr", "Lady",],
            size: 2
        },
        { title: "First name", type: "text", name: 'first_name', size: 3, required: true, },
        { title: "Last name", type: "text", name: 'last_name', size: 3, required: true, },
        { title: "Email", type: "email", name: 'email', size: 4, required: true, },
]

const usersBase = [
    { title: "username", type: "text", name: 'username', size: 3, required: true, },
    { title: "First name", type: "text", name: 'first_name', size: 3, required: true, },
    { title: "Last name", type: "text", name: 'last_name', size: 3, required: true, },
    { title: "Email", type: "email", name: 'email', size: 3, required: true, },
    { title: "Phone number", type: "text", name: 'phone_number', size: 3, required: true, },
]

const usersCreate = [
    ...usersBase,
    { title: "Unique id", type: "text", name: 'slug', size: 3, required: false, read_only: true},
    { title: "Password", type: "text", name: 'hashed_password', size: 3, required: false, read_only: true},
    { title: "Last login", type: "datetime", name: 'last_login', size: 3, required: false, read_only: true},
    { title: "Email confirmed", type: "boolean", name: 'email_confirmed', size: 3, required: false, },
    { title: "Phone confirmed", type: "boolean", name: 'phone_confirmed', size: 3, required: false, },
    { title: "Is Account Admin", type: "boolean", name: 'is_account_admin', size: 3, required: false, },
    { title: "Is staff", type: "boolean", name: 'is_staff', size: 3, required: false, },
    { title: "Is admin", type: "boolean", name: 'is_admin', size: 3, required: false, },
    { title: "Is superuser", type: "boolean", name: 'is_superuser', size: 3, required: false, },
    { title: "Is disabled", type: "boolean", name: 'disabled', size: 3, required: false, },
]

const updateUser = [
    ...usersBase,
    { title: "Password", type: "password", name: 'password', size: 3, required: true, min_length: 8 },
    { title: "Confirm Password", type: "password", name: 'confirm_password', size: 3, required: true, equals: 'password', min_length: 8 },
]

const travellerCreate = [
    ...personalInfo,
    ...identityInformation
]

const preferencesView = [
    ...travellerPreferences
]

const createReminder = [
    { title: "Reminder name", type: "text", name: 'short_name', size: 3, required: true },
    { title: "Reminder date", type: "datetime", name: 'reminder_date', size: 3, required: true, },
    { title: "Reminder type", type: "select", name: 'reminder_type', choices: ['Reminder','Drop off','Pick up','Leave start','Leave end'], size: 3, required: true, },
    { title: "Owner Email", type: "text", name: 'owner_email', size: 3, required: true, },
    { title: "Owner", type: "related", name: 'owner', size: 3, required: true, related_url: 'users', search_field: 'first_name'},
    { title: "Description", type: "textarea", name: 'description', size: 9, required: true, },
]

const createMenuItem = [
    { title: "Menu name", type: "text", name: 'name', size: 3, required: true },
    { title: "Path", type: "text", name: 'path', size: 3, required: true },
    { title: "Manages", type: "text", name: 'manages', size: 3, required: true },
    { title: "Order", type: "number", name: 'order', size: 3, required: true },
    { title: "Has divider", type: "boolean", name: 'divider', size: 3, required: true },
    { title: "Icon", type: "select", name: 'icon', size: 3, required: true,
      choices: [
        'AlarmOn', 'AirportShuttle', 'AppRegistration', 'AppBlocking', 'Dvr', 'CarCrash', 'CarRepair','CarRental','ChevronLeft','ChevronRight',
        'Circle', 'Dashboard', 'DockSharp', 'GpsNotFixedRounded', 'HomeMaxRounded', 'HomeMini', 'Logout', 'Menu', 'Monitor', 'Notifications',
        'Password', 'Person', 'PersonSearch', 'Settings', 'Fingerprint', 'Shop', 'Shop2Outlined', 'SimCard', 'SpeedRounded', 'VerifiedUserSharp',
        'AddReaction', 'Apartment', 'CircleSharp', 'Receipt',
        'DescriptionOutlined','FileCopyOutlined', 'InsertDriveFile','RequestPageOutlined','TaskOutlined','PeopleAltOutlined', 'Widgets'
      ]
    },
    { title: "Required roles", type: "multiselect", name: 'required_roles', size: 7, related_url: 'permissions' },
]

const companyBase = [
    { name: 'name',  title: "Name", type: "text", required: true, size: 3 },
    { name: 'email',  title: "Email", type: "text", required: true, size: 3   },
    { name: 'contact_number',  title: "Contact number", type: "text", required: true, size: 3   },
    { name: 'number',  title: "Account number", type: "text", read_only: true, size: 3  },
    { name: 'registration_number',  title: "Registration number", type: "text", required: true, size: 3  },
    { name:'is_vat_registered', size: 2,  title: "Vat registered", type: "select", required: true, choices: [ { value: true, label: 'Yes'}, {value: false, label: 'No'} ] },
    { name: 'vat_number',size: 2,  title: "Vat number", type: "text",  depends: 'is_vat_registered'  },
    { name: 'currency',  title: "Currency", type: "select", required: true, choices: ['ZAR','BWP','USD'], size: 2  },
    { name: 'created_by',  title: "Created by", type: "text", read_only: true, size: 3  },
]

const tenants = [
        ...companyBase,
        { name: 'bank_name',  title: "Bank name", required: true, type: "text", size: 2, new_line: 12  },
        { name: 'bank_account_number',  title: "Bank account number", required: true, type: "text", size: 2  },
        { name: 'bank_account_type',  title: "Account type", type: "select", required: true, choices: ['Transmission','Cheque','Business Cheque','Savings','Bond'], size: 2  },
        { name: 'bank_branch_code',  title: "Branch code", required: true, type: "text", size: 2   },

        { name: 'address',  title: "Billing Address", type: "textarea", required: true, size: 4, new_line: 12  },

]

const invoice_items = [
    {
        "name": "quantity",
        "title": "Quantity",
        "type": "number"
    },
    {
        "name": "item",
        "title": "Item",
        "type": "string"
    },
    {
        "name": "description",
        "col_span": 3,
        "title": "Description",
        "type": "text"
    },
    {
        "name": "unit_price",
        "default": 0,
        "order": 1,
        "size": 12,
        "step": [
            0.01
        ],
        "subtype": "currency",
        "title": "Unit Price",
        "type": "number"
    },
    {
        "name": "discount",
        "default": 0,
        "order": 1,
        "size": 12,
        "step": [
            0.01
        ],
        "subtype": "currency",
        "title": "% Discount",
        "type": "number"
    },
    {
        "name": "created_by_id",
        "default": null,
        "title": "Created By Id"
    }
]

const customers = [
    ...companyBase,
    { name: 'outstanding_balance',  title: "Outstanding balance", type: "currency",subtype: 'currency', size: 3, read_only: true   },
    { name: 'delivery_address',  title: "Delivery Address", type: "textarea", required: true, new_line: 12  },
    { name: 'address',  title: "Address", type: "textarea", required: true },
]

const createOptions = {
    traveller_invites : inviteCreate,
    traveller_profiles: travellerCreate,
    traveller_preferences: preferencesView,
    users: usersCreate,
    reminders: createReminder,
    menu_items: createMenuItem,
    tenants,
    customers,
    invoice_items
}

export const updateOptions = {
    traveller_invites : inviteCreate,
    traveller_profiles: travellerCreate,
    traveller_preferences: preferencesView,
    users: updateUser,
    reminders: createReminder,
    menu_items: createMenuItem,
    tenants,
    customers,
    invoice_items
}

export default createOptions;
