import { Tooltip, Typography } from "@mui/joy";
import { ContentBetween, ContentStart } from "../Content";
import { BsBriefcase } from "react-icons/bs";
import { Button } from "@mui/joy";

const Reminder = ({ color, type="Reminder", count=1, startDecorator }) => {
    const display = ['red','green','blue','orange','purple','bluegray','yellow','pink','teal','gray']
    let number = Math.floor(Math.random()*(10))
    return <ContentBetween className={`bg-${color||display[number]}-100 border-round-md p-1 mt-1`} style={{minWidth: 110 }}>

        <ContentStart className={"align-items-center"}>
          {startDecorator ? startDecorator : <BsBriefcase className={`text-${color||display[number]}-700 text-lg`}/> }
          <Typography className="ml-1 text-gray-800">
                {type}
             </Typography>
        </ContentStart>
        <ContentStart className={`bg-${color||display[number]}-700 border-round-md text-white px-1 align-items-center max-w-1rem white-space-nowrap overflow-hidden`}>
        <Tooltip title={count}>
            <div className="">
               {count}
            </div>
        </Tooltip>
        </ContentStart>

    </ContentBetween>
  }

  export default Reminder;