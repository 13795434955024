
import { ContentBetween, ContentColumn, ContentEnd, ContentStart, PageHeader } from "../Content";
import moment from "moment";
import Reminder from "./Reminder";
import { useEffect, useMemo, useState } from "react";
import { IconButton } from "@mui/joy";
import { Link } from "react-router-dom";


const DayData = ({ currentDay, items, index, reminders, onDaySelect = f=>f, onItemSelect = f=>f}) => {

    const thisDay = moment(currentDay.momentDate)
    const [rows, setRows] = useState([])
    
    const days =['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
    const today = moment()

    const datefy = (date) => {
        return moment(date?.split(".")[0].replace("T"," "))
    }

    const equalDate = (date_1, date_2) => {
        return (date_1.date() === date_2.date())&&(date_1.month() === date_2.month())&&(date_1.year()===date_2.year())
    }

    useEffect(() => {
        let reminder_list = reminders.map(r => ({ ...r, reminder_date : datefy(r.reminder_date)}))
        let reminder_row = reminder_list.filter(r => equalDate(r.reminder_date, thisDay))
        setRows(reminder_row)

        return () => setRows([])
    },[reminders])

  
    
    if(!currentDay.momentDate)
         return <td className={`border-300 bg-gray-100 ${index===0 ? 'border-top-1 border-right-1' : 'border-bottom-1 border-right-1'}`} style={{ height: 130, minWidth: 130, flex: 1 }}>
            <ContentBetween>
                <span></span>
               {index==0 && <span className="text-gray-500 font-bold">{days[currentDay.day]}</span>}
            </ContentBetween>
         </td>

    return <td className={`border-300 ${index===0 ? 'border-top-1 border-right-1' : 'border-bottom-1 border-right-1'}`} style={{ height: 130, minWidth: 130, flex: 1 }}>
        <ContentBetween>
          <IconButton size="sm" className="border-round-2xl" onClick={() => onDaySelect(thisDay)}>
           { today.startOf('day').diff(thisDay.startOf('day'), 'days') === 0  ? <span className="text-white w-2rem h-2rem flex justify-content-center align-items-center font-bold bg-blue-600 border-round-2xl"> {thisDay.date()}</span> : <span className="font-bold"> {thisDay.date()}</span> }
          </IconButton>

           {index==0 && <span className="text-gray-500 font-bold">{days[thisDay.weekday()]}</span>}
        </ContentBetween>
        <ContentColumn className={""}>
              { rows.map((reminder, inded) => <Link href={`/manage/reminder/${reminder?.slug}`} className="no-underline" onClick={(e) => {e.preventDefault(); onItemSelect(reminder)}}><Reminder type={reminder.reminder_type} key={inded} count={reminder.reminder_date.fromNow()} /></Link> ) }
              {/* { thisDay.date() === 18 && <Reminder type="Leave Start" count={12} /> } */}
        </ContentColumn>
        </td>
  }

  export default DayData;

 