import Cookies from "js-cookie";
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { useApi } from "./useApi";

const Context = createContext();

const initialState = {
    loginClient : "wfe0wc3kXGsXtLmPtcrW0aT48fZ71uzPAEQKLyj8",
    loginClientSecret : "bZU4h4xZ9blWb7J2l7dAbBXy0KAIdfhSza8Mm3LhOzIfAseK5HAMlBCjMNDVdUfFH3wkpi9JXf6PyExxKMWZ9tqO5m3SV8826QtveKyleQLToUwluUOjCiHpcMPGU3xk",
    refreshToken: null,
    authKeyName: "oe0ohghohs3xai3eikjhjdwe6haphetha",
    initApp: true,
    currentUser: null,
    authToken: null,
    loading: false
}

export const localKeyName = "oe0ohghohs3xai3eikjhjdwe6haphetha";
export const refreshKeyName = "vah1heeshourppuddae1ieDaubeegeF0li"

const reducer = (state, action) => {
    switch(action.type){
        case 'setItem': return {
            ...state,
            [action.name]: action.value
        }
        case 'initApp': return {
            ...state,
            initApp: true
        }
        case 'readyApp': return {
            ...state,
            initApp: false
        }
        default: return state;
    }
}

const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const api = useApi();

    //Clears all the usernames and app data.
    const logout = () => {
       return new Promise((resolve) => {
        dispatch({ type: 'setItem', name:'currentUser', value: null })
        dispatch({ type: 'setItem', name:'authToken', value: null })
        
        localStorage.removeItem(localKeyName)
        Cookies.remove(refreshKeyName)

        resolve({ key: localKeyName, refresh: refreshKeyName }) //Returns the keyname for any calling method.
       })
       
    }

    const getUser = useCallback(() => {
       api.get(`users/current_user/`)
       .then((response) => {
            dispatch({ type: 'setItem', name:'currentUser', value: response.data })
            //console.log(response)
       }).catch(console.log)
    },[api])

    const initialize = (refreshToken) => {
       return new Promise((resolve) => {
        getUser()
        let token = localStorage.getItem(localKeyName)
        dispatch({ type: 'setItem', name:'authToken', value: token })
        dispatch({ type: 'readyApp' })
        if(refreshToken)
        {
            dispatch({ type: 'setItem', name:'refreshToken', value: refreshToken })
        }

        resolve(refreshToken)
       })
    }

    const value = useMemo(() => {
        return {
            state,
            localKeyName,
            refreshKeyName,
            logout,
            initialize,
            dispatch
        }
    },[state])

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export const useAppContext = () => {
    return useContext(Context)
}

export default Provider;