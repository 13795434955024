import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export default function DiscardModal({ data, config, onConfirmAction = f => f, onCancelAction = f => f }) {
  const [open, setOpen] = React.useState(false);
  const [iconfig, setIconfig] = React.useState({
    color: 'danger',
    variant: 'outline',
    icon: <DeleteForever />,
    action: "Delete item",
    prompt: "You are about to delete this item, are you sure?",
    className: null
  })

  React.useEffect(() => {
      setIconfig(e => ({
          ...e,
          ...config
      }));
  },[config])

  const doConfirm = () => {
    onConfirmAction(data)
    setOpen(false)
  }

  const doCancel = () => {
    onCancelAction(data)
    setOpen(false)
  }
  return (
    <React.Fragment>
      <Button
        variant={iconfig?.variant}
        className={'mx-1 white-space-nowrap overflow-hidden text-overflow-clip ' + iconfig?.className}
        size='sm'
        disabled={iconfig?.disabled}
        color={iconfig?.color}
        endDecorator={iconfig?.icon}
        onClick={() => setOpen(true)}
      >
        {iconfig?.action}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {iconfig?.action}
          </DialogTitle>
          <Divider />
          <DialogContent>
          {iconfig?.prompt}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={() => doConfirm()}>
              {iconfig?.action}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => doCancel()}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}