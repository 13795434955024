import { useState } from "react";
import { ContentBetween, ContentColumn, HeaderContent } from "../layout/Content";
import AppSnackBar from "../layout/AppSnackBar";
import * as React from 'react';
import { useApi } from "../../utils/useApi";

import { Api } from "@mui/icons-material";
import NoData from "../layout/NoData";
import useTools from "../../utils/useTools";
import { Button, ListItemButton, Typography } from "@mui/joy";
import TableFilter from "./TableFilter";
const PermissionList = ({ noFilters, noSearch, objectName, selectedPermission, onSelectPermission, serialNumber, page=1, page_size=10 }) => {
    
    const [rows, setRows] = useState([])
    const api = useApi()

    const [options, setOptions] = React.useState({ properties: {}})
    const [triggerToast, setTriggerToast] = React.useState({
        message: '',
        counter: 0,
        color: 'success'
    })

    const [pageObject, setPageObject] = React.useState({
        page: page,
        page_size: page_size,
        limit: page_size,
        skip: 0 ,
        ordering: 'id',
    })
    
    const showToast = (message, type='primary') => {
        setTriggerToast(prev => ({ message: message, counter: prev.counter+1, color: type }))
    }

    const hideToast = () => {
        setTriggerToast(prev => ({ message: null, counter: 0 }))
    }

    const doSearch = React.useCallback((term) => {
        setPageObject(e => ({
          ...e,
          query: term
        }))
    })
    
    const doFilter = (filter_object) => {
    let pageDefaults = {
        page: page,
        page_size: pageObject.page_size,
        limit: pageObject.limit,
        skip: 0,
        ordering: pageObject.ordering 
    }

    if(filter_object)
        setPageObject({
        ...pageDefaults,
        ...filter_object
        })
    else
        setPageObject(pageDefaults)
    }

    const getUserRoles = React.useCallback(() => {
         api.get(`${objectName}/?page_object=${JSON.stringify(pageObject)}`).then(response => {
            setRows(response.data.results)
         }).catch(console.log)
    },[api, serialNumber])

    React.useEffect(() => {
        getUserRoles()
    },[])

    React.useEffect(() => {
        getUserRoles()
    },[serialNumber, getUserRoles])

    return (
      <ContentColumn className={'p-3'}>
          <HeaderContent header={"User groups"} subheading={"You may select a user group to give permission on data groups."}/>
          { triggerToast?.message && <AppSnackBar
                   openCount={triggerToast.counter} 
                   message={triggerToast.message} 
                   type={triggerToast.color} 
                   onClose={hideToast}
         /> }

        <TableFilter properties={options?.properties} doFilter={doFilter} doSearch={doSearch} noFilters={noFilters} noSearch={noSearch}/> 

        { rows.map((userRole, index) => <PermissionItem  key={index} profile={userRole} selectedProfile={selectedPermission} onSelect={onSelectPermission} />) }
        { rows.length === 0 && <NoData objectName="user permissions" /> }

      </ContentColumn>
    )
}

export default PermissionList;

const PermissionItem = ({ profile, selectedProfile, onSelect = f=>f }) => {
    const tools = useTools()
    return <ListItemButton onClick={() => onSelect(profile)} className={`px-3 py-2 shadow-2 my-2 border-round-md ${ profile?.id == selectedProfile?.id ? 'bg-blue-600 text-white' : 'bg-white'}`}>
       <ContentColumn>
        <ContentBetween className={'font-semibold'}>
           {tools.makeLabel(profile.name)}
        </ContentBetween>
        <Typography className={`text-xs ${ profile?.id == selectedProfile?.id ? ' text-white' : ' bg-white'}`}>{profile.description}</Typography>
       </ContentColumn>

    </ListItemButton>

}