import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItemButton from '@mui/joy/ListItemButton';
import * as React from 'react';
import { useEffect, useState } from "react"


export default function NestedList({ label, choices=[], onChoicesUpdate = f => f }) {

    const [selected, setSelected] = useState([])

    useEffect(() => {
       //console.log(selected)
    },[selected])

    useEffect(() => {
      //console.log(choices)
    },[choices])

  
    const handleClick = (choice) => {
     const selectedIndex = selected.indexOf(choice.id)
     let newSelected = []

     if(selectedIndex === -1)
     {
          newSelected = newSelected.concat(selected, choice.id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
      onChoicesUpdate(newSelected);
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
      <div className="w-5">
        <List
          variant="outlined"
          size={'sm'}
          sx={{
            borderRadius: 'sm',
            height: 160,
            overflowY: 'scroll'
          }}
        >
          <ListItem nested>
            <ListSubheader>{label} {selected.length > 0 ? `(${selected.length})` : ''}</ListSubheader>
            <List>
              { choices.map((choice, index) => {
              const isItemSelected = isSelected(choice.id)

              return (<ListItem key={index}>
                <ListItemButton onClick={() =>  handleClick(choice)} selected={isItemSelected}>{choice.name||choice.label||choice.title||choice}</ListItemButton>
              </ListItem>)
              }

              )}
             
            </List>
          </ListItem>
        </List>
      </div>
    );
  }