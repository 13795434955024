import * as React from 'react';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography from '@mui/joy/Typography';
import { Check } from '@mui/icons-material';
import { Tooltip } from '@mui/joy';

export default function IconStepper({ wizardSteps, currentStep, wizardData, hasError }) {

  const conmpleted = (step) => {
    return wizardData[step] || (currentStep===wizardSteps.length - 1) //When at last step, all steps are completed - linear
  }

  return (
    <>
    <Stepper
      size="lg"
      sx={{
        width: '90%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '0px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >

     { wizardSteps?.map((screen, index) =>   <Tooltip title={screen.title  + `${conmpleted(index) ? " - Complete" : '' }` } key={index}>

      <Step
        completed={conmpleted(index)}
        orientation="vertical"
        active={currentStep===index}
        indicator={
          <StepIndicator className={conmpleted(index) ? 'bg-green-700 text-white':''} variant={currentStep===index ? 'solid':'outlined'} color={hasError ? 'danger' : 'primary'} >
            { conmpleted(index) ? <Check /> : screen.icon }
          </StepIndicator>
        }
      >
      {  currentStep===index && <Typography className="hidden md:block"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'lg',
            fontSize: '0.75rem',
            letterSpacing: '0.5px',
          }}
        >
          {screen.title}
        </Typography> }
     </Step>
     </Tooltip>


     )
    }


    </Stepper>
    </>
  );
}