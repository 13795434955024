

const rights = {
       dashboard: {
        label: "Dashboard",
        icon: "SpeedRounded",
        path: "/dashboard",
        manage: "dashboard",
        required_roles: [],
      },
      rentals: {
        label: "Rentals",
        icon: "CarRental",
        path: "/manage/rentals",
        manage: "rentals",
        required_roles: ["is_superuser"],
      },
      reminders: {
        label: "Reminders",
        icon: "AlarmOn",
        path: "/manage/reminders",
        manage: "reminders",
        required_roles: ["is_superuser"],
      },
      traveller_profiles: {
        label: "Travellers",
        icon: "PersonSearch",
        path: "/manage/traveller_profiles",
        manage: "travellers",
        required_roles: ["is_staff", "is_account_admin"],
      },
      traveller_invites : {
        label: "Invites",
        icon: "DockSharp",
        path: "/manage/traveller_invites",
        manage: "travellerinvites",
        required_roles: ["is_account_admin", "is_admin"],
      },
      users: {
        label: "Settings",
        icon: "Settings",
        path: "/manage/users",
        manage: "users",
        required_roles: ["is_superuser"],
      },
      permissions: {
        label: "Permissions",
        icon: "Fingerprint",
        path: "/manage/permissions",
        manage: "permissions",
        required_roles: ["is_superuser"],
      },
      menu_items: {
        required_roles: ["is_superuser"],
      }
}


const viewRights = (objectName, currentUser) => {
    let menuItem = rights[objectName]
    if(!menuItem) return false

    if (!currentUser) return false;
  
    if (currentUser.is_superuser) return true;
  
    if (!menuItem.required_roles) return true;
    if (menuItem.required_roles.length == 0) return true;
    let has_right = false;
    menuItem.required_roles.forEach((element) => {
      has_right = has_right || currentUser[element.name];
    });
    return has_right;
  };

export default viewRights;