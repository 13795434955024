import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import { useApi } from '../../utils/useApi';
import { ModalClose } from '@mui/joy';
import useTools from '../../utils/useTools';
import { FormField } from './FormControls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppContext } from '../../utils/AppContext';
import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { ContentColumn } from '../layout/Content';


const options = [
    {
        name: 'current_password',
        required: true
    },
    {
        name: 'password',
        required: true
    },
    {
        name: 'confirm_password',
        required: true
    }
]

const PasswordConfirmSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password too short!')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    confirm_password: Yup.string().required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
    current_password: Yup.string()
        .min(2, 'Password is too short')
        .required('The current password is required')
});

const initialShowPass = {
    password: false,
    current_password: false,
    confirm_password: false,
}


 
export default function ChangePasswordModal({ label, showModel, test_mode, onDismiss = f => f, buttonLabel=null, setToast = f => f }) {
  const [open, setOpen] = React.useState(false);

  const [showPass, setShowPass] = React.useState(initialShowPass)

  const api = useApi()
  const tools = useTools()

  const { state } = useAppContext()
  const { currentUser } = state;

  const formik = useFormik({
     initialValues: {
        password: '',
        current_password: '',
        confirm_password: '',
     },
     validationSchema: PasswordConfirmSchema,
     onSubmit: (data) => {
        //console.log(data)

        tools.postToUrl(`/users/${currentUser?.slug}/change_password/`, data)
        .then((data) => {
            setOpen(false);
            onDismiss(false);
            setToast('Password changed successfully', 'primary')
        })
        .catch((ed) => {
            console.log(ed)
            setToast(ed?.response?.data?.detail||"Unknown error occurred !!", 'danger')
        })
        .finally(() => {
            
        })
  

        
     }
  })

  React.useEffect(() => {
      setOpen(showModel)

      return () => {
        setShowPass(initialShowPass);
        formik.resetForm();
      }
  },[showModel])

  return (
    <>
     { test_mode && <Button
        variant="solid"
        color="success"
        size='sm'
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
        className='mr-2'
      > 
       { buttonLabel ? buttonLabel : tools.titleCase(label?.slice(0,-1))} 
      </Button> }
      <Modal open={open} onClose={() => setOpen(false)} slotProps={{ backdrop: { sx: { backgroundColor: "#3c3c3c", opacity: 0.2 }}}}>
        <ModalDialog className="md:w-3 xl:w-3">
        <ModalClose />
          <DialogTitle>Change password</DialogTitle>
          <DialogContent>Fill in the information of the new {label?.slice(0, -1)}.</DialogContent>
          <form
            onSubmit={formik.handleSubmit}
          >
            <Stack spacing={2}>

              { options.map((field, index) => { 
                    let visual_disabled = " w-full text-bluegray-600 border-bluegray-200"
                    let visual_error = formik?.errors[field.name] ? " border-red-600 " : ""
                    let visual_class = visual_disabled + visual_error

                    return  <ContentColumn className={"my-0"} key={index}>
                                <FormField
                                        title={tools.makeLabel(field.name)} 
                                        name={field.name}
                                        onChange={formik.handleChange}
                                        autoFocus={index==0}
                                        value={formik.values[field.name]}
                                        type={showPass[field.name] ? 'text' : 'password'}
                                        className={visual_class}
                                        endDecorator={<span className="clickable pt-1" onClick={(e) => setShowPass(prev => ({ ...prev, [field.name] : !showPass[field.name] }))}>{showPass[field.name] ? <LockOpenOutlined/> : <LockOutlined />}</span>}
                                         />
                                        <small className='text-red-600'>{formik.errors[field.name]}</small>
                                </ContentColumn>

                } )}

             { buttonLabel ? <Button type="submit">{buttonLabel}</Button>
             : <Button type="submit">Change Password</Button> }
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}