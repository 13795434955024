import { Skeleton } from "@mui/joy";
import { useState } from "react";
import * as React from 'react';
import { useApi } from "../../../utils/useApi";
import useTools from "../../../utils/useTools";
import Content, { ContentColumn, HeaderContent } from "../Content";
import OTPConfirmationModal from "../modals/OTPConfirmationModal";
import EditForm from "../../formcontrols/EditForm";
import StatusNotes from "./StatusNotes";
import appOptions from "../../../utils/models/appOptions";
import createOptions from "../../../utils/models/createOptions";
import Loading from "../../Loading";


const GeneralInformationTab = ({currentUser, objectProfile, objectName, objectSlug, objectPermissions, onObjectUpdate = f => f, updatedSerial }) => {

    const [options, setOptions] = useState(null)
    const [profile, setProfile] = useState(objectProfile)
    const [loading, setLoading] = useState(false) 

    const [serialNumber, setSerialNumber] = useState(0) //Notify related tabs of parent change

    const [OTP, setOTP] = useState(null)
    const api = useApi()


    const tools = useTools();

    const loadModelData = React.useCallback(() => {
      setLoading(true)
      tools.dataFromUrl(`/${objectName}/${objectSlug}`, setLoading)
      .then((data) => {
        setProfile(data)
        console.log(data)
    })
      .catch(console.log)
      .finally(() => setLoading(false))
    },[objectName, objectSlug, serialNumber])


    const buildOptions = (fields) =>{
      let props = fields.properties
      let field_list = []
      Object.keys(props).forEach((prop) => {
        field_list.push({ name: prop, ...props[prop] })
      })
      console.log(field_list.sort((a,b) => b.order > a.order))
      return [...field_list].sort((a,b) => a.order > b.order ? 1 : -1)
    }

    React.useEffect(() => {
    
        let opts = createOptions[objectName]
    //console.log(opts)
    if(!opts)
      api.options(`/${objectName}/`).then(res => {
          console.log(res.data)
          setOptions(buildOptions(res.data))
      }).catch(console.log)
     else {
        setOptions(opts)
     }

      return () => {
        setOptions({ properties: {}})
      }
    },[objectName, objectSlug, api])

    React.useEffect(() => {
        //console.log("Loading data called.")
        loadModelData()
    },[updatedSerial])

    React.useEffect(() => {
      onObjectUpdate(profile)
    },[profile])

    React.useEffect(() => {
       setSerialNumber(updatedSerial)
    },[updatedSerial])

    React.useEffect(() => {
      console.log(options)
    },[options])


    const otpConfirmed = () => {
        setOTP(null)
        loadModelData()
      }
  

  const modelLabel = tools.titleCase(objectName).slice(0, -1);

    return (
      profile?.id ?
      <Content>
      <ContentColumn className={"p-4 col border-right-1 border-200 bg-white shadow-1"} style={{ maxHeight: '50vh', overflowY: 'scroll'}}>
        <OTPConfirmationModal otp={OTP} onCompleted={otpConfirmed}  />
        <HeaderContent className={"p-2"} header={`Manage ${modelLabel} information` } subheading={`${modelLabel} details can be viewed and updated using the widgets below.`}/>

        <Skeleton loading={!options}>
           <EditForm permissions={objectPermissions} createMode={false} currentUser={currentUser} options={options} objectName={objectName} profile={profile} setProfile={setProfile} onComplete={() => setSerialNumber(e => e+=1)} />
        </Skeleton>

      </ContentColumn>
      
      {/* 
      <Content className={'hidden md:block md:flex flex-1'}>
             <StatusNotes objectName={objectName} object={profile} serialNumber={serialNumber} />
      </Content> 
      */}

      </Content>
      :
      <Loading height={500}/>

    )

  }


  export default GeneralInformationTab;