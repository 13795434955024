import { Lock, LockOpen, LockOpenOutlined, LockOutlined, PanoramaFishEye } from "@mui/icons-material";
import { Box, Button, IconButton, Input } from "@mui/joy";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useTools from "../utils/useTools";
import axios from "axios";
import * as Yup from 'yup';
import Content from "../components/layout/Content";

const PasswordConfirmSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Password too short!')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),
    confirm_password: Yup.string().required('Confirm password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

const initialShowPass = {
    pass: false,
    confirm: false
}

const PasswordResetPage = () => {

    const [status, setStatus] = useState(false); 
    const [username, setUsername] = useState(false); 

    const [loading, setLoading] = useState(false); 

    const [tokenValidity, setTokenValididty] = useState(false)
    const [showPass, setShowPass] = useState(initialShowPass)

    const navigate = useNavigate()
    const tools = useTools()
    const { validToken } = useParams()

    useEffect(() => {
        return () => {
            setUsername(null)
            setStatus(null)
        }
        
    },[])

    useEffect(() => {
        checkTokenValidity()
        return () => {
            setTokenValididty(null)
        }
        
    },[validToken])

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema:PasswordConfirmSchema,
        onSubmit: (data) => {
            setUsername(data.username)
            setLoading(true)
            tools.postToUrl(`users/do-reset-password/${validToken}/`, data)
            .then((data) => {
                setStatus({ completed: true })
            })
            .catch((e) => { 
                setStatus({ error: true })
                console.log(e)
            })
            .finally(() => setLoading(null))
        }
    })

    const isFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFieldErrorMessage = (name) => (
        isFieldInvalid(name) ? <small className="text-xs text-red-700">{formik.errors[name]}</small> : null
    )

    const checkTokenValidity = () => {
        setLoading(true)
           tools.dataFromUrl(`users/check-reset-token/${validToken}/`)
           .then((data) => {
              setTokenValididty(data?.is_valid)
              console.log(data)
           }).catch(console.log)
           .finally(() => setLoading(false))
    }

    const togglePass = (confirm) => {
        return showPass[confirm] ? <LockOpenOutlined className="clickable" onClick={() => setShowPass(prev => ({ ...prev, [confirm]: false}))} /> : <LockOutlined className="clickable" onClick={() => setShowPass(prev => ({ ...prev, [confirm]: true}))} />
    }


    return (
        <Content className="w-full d-flex justify-content-center align-items-center">

        { tokenValidity ? 
        <Box className="flex flex-column my-4 w-10 md:w-6 lg:w-3">
            <div>
            <p className="font-bold text-lg mb-0">Reset password</p>
           { status?.completed ? <p className="text-sm mb-0">Password was reset successfully.</p> : <p className="text-sm">Enter your new password and confirm it</p> }
            </div>

            { !status?.completed ?
            <form onSubmit={formik.handleSubmit}>

            <div className="my-1">
               <Input 
                  id="password" 
                  type={showPass.pass ? "text": "password" }
                  className={isFieldInvalid('password') ? "border-red-700":null} 
                  placeholder="New password" 
                  name="password" 
                  onChange={(e) => formik.setFieldValue('password', e.target.value)} 
                  endDecorator={togglePass('pass')}
                  />
               {getFieldErrorMessage('password')}
            </div>

            <div className="my-1">
               <Input 
                   id="confirm_password"  
                   type={showPass.confirm ? "text": "password" }
                   className={isFieldInvalid('confirm_password') ? "border-red-700":null} 
                   placeholder="Confirm new password" 
                   name="confirm_password" 
                   onChange={(e) => formik.setFieldValue('confirm_password', e.target.value)} 
                   endDecorator={togglePass('confirm')}
               />
               {getFieldErrorMessage('confirm_password')}
            </div>
           <p className="text-gray-600 text-xs">Password min length 8, with Uppercase, numbers and special characters</p>
            <div className="my-1 mt-3">
                 <Link className="text-blue-700 no-underline font-semibold mt-3" to={"/login"}>Back to Login?</Link>
            </div>

            { status?.error && <p className="text-sm text-red-600">An error occured while sending an email, please try again later.</p> }
            <div className="mt-3 mb-1 flex justify-content-between align-items-end">
                <div className="min-w-min w-14rem">
                    <Button type="submit" loading={loading}>Change password</Button>
                </div>
                <p className="text-xs mb-0">
                    By submitting this form to accept our <Link className="text-blue-700 no-underline font-semibold">terms & conditions</Link>
                </p>
            </div>


            

            </form>

            :  <div className="mt-1 mb-1 flex flex-column">
                <p>You can now go back to the login page.</p>
                <div className="min-w-min w-12rem">
                    <Button onClick={() => navigate("/login")}>Back to Login</Button>
                </div>
            </div> }



            <div className="text-xs my-5 text-gray-500">
            Copyright &copy; {new Date().getFullYear()} <Link target="_blank" to="https:///www.nelotec.co.za/" className="text-blue-700 no-underline font-semibold">Nelotec (Pty) Ltd</Link>
            </div>
        </Box>
        
        :
        !loading && <Box className="flex flex-column my-4 w-10 md:w-6 lg:w-3">
            <p className="text-sm mb-0">The token you have supplied could not be verified, or is invalid, please click the original link or request a new one.</p>
            <div className="my-1 mt-3">
                 <Link className="text-blue-700 no-underline font-semibold mt-3" to={"/login"}>Back to Login?</Link>
            </div>
            <div className="text-xs my-5 text-gray-500">
            Copyright &copy; {new Date().getFullYear()} <Link target="_blank" to="https:///www.nelotec.co.za/" className="text-blue-700 no-underline font-semibold">Nelotec (Pty) Ltd</Link>
            </div>
        </Box> 
        }
        </Content>
    )
  };
  
  export default PasswordResetPage;
  