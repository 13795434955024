import { List, ListDivider, ListItem, ListItemContent, ListItemDecorator, MenuItem, Typography } from "@mui/joy"
import { Fragment, useEffect, useState } from "react"
import { ContentColumn } from "./Content"

const NotificationList = ({ currentUser }) => {
     const [notifications, setNotifications] = useState([])

     useEffect(() => {

        setNotifications(currentUser?.notifications)
     
        return () => {
            setNotifications([])
        }
     },[currentUser])


     return (
        <ContentColumn className="w-25rem">
          <MenuItem>
                <ListItemContent>
                    <Typography className="font-bold text-sm">
                          Notifications
                    </Typography>
                </ListItemContent>
            </MenuItem>
            <ListDivider />
            <MenuItem>
                <ListItemContent>
                    <Typography>
                          {notifications.length} Notifications avalable
                    </Typography>
                </ListItemContent>
            </MenuItem>
        </ContentColumn>
     )
}

export default NotificationList;

const NotificationItem = ({ notifition}) => {
    return (
        <Fragment>
          { notifition.divided && <ListDivider /> }
          
          <MenuItem disabled={notifition.disabled} onClick={() => { notifition.action() }}>
              { notifition.decorator  ? <ListItemDecorator sx={{ color: 'inherit' }}>
                { notifition.decorator }
               </ListItemDecorator> : <ListItemDecorator /> }{' '}
               {notifition.label}
          </MenuItem> 
          
        </Fragment>
    )
}