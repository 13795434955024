import { Badge, Button, IconButton, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { useAppContext } from "../utils/AppContext";
import {  useState } from "react";
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FiList } from "react-icons/fi";
import Content, { ColumnValuePair, ContentBetween, ContentColumn, ContentEnd, ContentStart, HeaderContent, PageHeader, RowValuePair, Spacer } from "../components/layout/Content";
import GenericList from "../components/lists/GenericList";
import CreateObjectComponent from "../components/formcontrols/CreateObjectComponent";
import { useApi } from "../utils/useApi";
import useTools, { tabStyles } from "../utils/useTools";
import GeneralInformationTab from "../components/layout/tabs/GeneralInformationTab";
import appOptions from "../utils/models/appOptions";
import AppDropdown from "../components/layout/AppDropdown";
import { Check, Checklist, CopyAll, Email, EmailOutlined, MoreVert } from "@mui/icons-material";
import useExtraActions from "../utils/models/useExtraActions";
import CreateWorknote from "../components/layout/modals/CreateWorknote";
import Loading from "../components/Loading";
import { BsCardChecklist, BsFileCheck, BsList, BsListCheck, BsThreeDotsVertical } from "react-icons/bs";
import StatusNotes from "../components/layout/tabs/StatusNotes";
import { Logo } from "../components/layout/Layout";
import moment from "moment";
import { formatCurrency } from "../components/lists/DisplayTableRow";
import DiscardModal from "../components/layout/modals/DiscardModal";
import BillableContent from "../components/layout/BillableContent";
import { PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "../components/printable/PdfDocument";
import CustomerHeader from "../components/layout/CustomerHeader";
import CreateForm from "../components/formcontrols/CreateForm";

import createOptions, { updateOptions } from "../utils/models/createOptions";

const EditPage = () => {
    const [options, setOptions] = useState(null)
    const [profile, setProfile] = useState(null)

    const [loading, setLoading] = useState(false)
    const [serialNumber, setSerialNumber] = useState()

    const [expanded, setExpanded] = useState(false)

    const { state } = useAppContext()
    const { currentUser } = state;
    //const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
    const tools = useTools()
    const api = useApi()

    const navigate = useNavigate()

    const { objectName, objectSlug } = useParams();

    //Working with permissions from the Database
    const [objectPermissions, setObjectPermissions] = React.useState({})

    const loadModelData = React.useCallback(() => {
      setLoading(true)
      tools.dataFromUrl(`/${objectName}/${objectSlug}`, setLoading)
      .then((data) => {
        setProfile(data)
        console.log(data)
    })
      .catch(console.log)
      .finally(() => setLoading(false))
    },[objectName, objectSlug, serialNumber])

    const getObjectPermissions = React.useCallback(() => {
      api.get(`modeltables/${objectName}`)
      .then(res => {
          setObjectPermissions(res.data)
          console.log(res.data)
      })
      .catch(console.log)

  },[api, objectName])

  React.useEffect(() => {
      getObjectPermissions();
  },[objectName, objectSlug, serialNumber])

  React.useEffect(() => {
    loadModelData()
  },[serialNumber, objectSlug, objectName])

  const hasPermissions = React.useMemo(() => {
      //The rights here are explicit 
      

      let permission_relations = objectPermissions.permission_relations;

      let has_permission = { read: false, create: false, update: false, remove: false }

      if(!currentUser)
          return has_permission

      permission_relations?.forEach(element => {
          if(currentUser[element.role]) {
              ['read', 'create', 'update', 'remove'].forEach((permission) => {
                  has_permission[permission] = has_permission[permission] || element[permission] //Will favor true
              })
          }
      })

      return has_permission
  }, [objectPermissions?.id, objectName, profile])

  //End of permissions

    React.useEffect(() => {
    let opts = appOptions[objectName]
    //console.log(opts)
    if(!opts)
      api.options(`/${objectName}/`).then(res => {
          console.log(res.data)
          setOptions(res.data)
      }).catch(console.log)
     else {
        setOptions(opts)
     }
  
      return () => {
        setOptions({ properties: {}})
      }
    },[objectName, objectSlug, api])

    const relatedModels = React.useMemo(() => {
         let local_props = appOptions[objectName]
         
         return options?.related_models ? options?.related_models : local_props?.related_models ? local_props?.related_models  : []
    },[options, objectName])

    let modelName = React.useMemo(() => {
        return  objectName ? objectName.slice(0, -1) : ''
    },[objectName, objectSlug])

    const [extraActions] = useExtraActions()

    const actionList = React.useMemo(() => {
        return extraActions[objectName]
    }, [objectName, objectSlug])

    const handleUpdate = (data) => {
        api.post(`systemlogs/`, data).then(console.log).catch(console.log)
        setSerialNumber(Math.random())
    }

    const handleWorknote = (data) => {
      api.post(`worknotes/`, { ...data })
       .then((e) => {
        console.log(e)
       }).catch((e) => {
           console.log(e)
       })
    }

    const billables = ['invoices','quotations','credit_notes','statements','journals']
  

    return (
        <div className="w-full">
       
       { profile?.id ? 
            <PageHeader
                  label={tools.makeLabel(objectName)}
                  subtitle={`${tools.titleCase(objectName).slice(0,-1)} - ${profile?.number||profile?.slug||profile?.id}`}
                //   startDecorator={<Button startDecorator={<BarChart />} variant="plain" color="neutral">Table Statictics</Button>}
                endDecorator={
                  <Content className="flex justify-content-center align-items-center">
                      { profile?.id && objectName && <CreateWorknote 
                      variant="solid" 
                      buttonLabel={"Add comment"} 
                      loading={loading} onComplete={() => handleUpdate({ relation_table_id: profile?.id, relation_table: objectName, action_name: 'commented'})} 
                      profile={profile} 
                      objectName={objectName}/> }
                      <Spacer className={"mx-1"} />
                     { profile?.id ? <AppDropdown
                        emptyMessage = "No actions defined"
                        label={""}
                        startIcon={<BsThreeDotsVertical fontSize={18} className="" />}
                        menuItems={actionList}
                        selected={[profile?.id]}
                        confirm_action={true}
                        onActionComplete={(e) =>  { 
                          console.log(e)
                          if(e)  {
                            setSerialNumber(Math.random())
                            handleUpdate({ relation_table_id: profile?.id, relation_table: objectName, action_name: e.item.label })
                            handleWorknote({ relation_table_id: profile?.id, relation_table: objectName, work_notes: `Action "${e.item.label}" completed on ${tools.makeLabel(objectName.slice(0,-1))}` })
                          }
                        } }
                        className={"mr-2 text-white"}
                        /> : <div className='h-2rem pb-1'>&nbsp;</div> }
                       <IconButton className="mx-1" size="sm" color="neutral" onClick={() => navigate(`/manage/${objectName}`)}><BsListCheck fontSize={24} /></IconButton>
                  </Content>
                  }
              /> : <Loading /> }
        <ContentColumn>

      { ['customers','tenants'].includes(objectName) && <CustomerHeader profile={profile} objectName={objectName} /> }
          
          <Tabs aria-label="Basic tabs" defaultValue={0} className="pt-0">
                <TabList className="max-w-screen overflow-x-scroll" sx={tabStyles}>
                    <Tab className="min-w-min white-space-nowrap">Overview</Tab>
                    { relatedModels.map((tab, index) => 
                      <Tab className="min-w-min white-space-nowrap" key={index}>{tools.makeLabel(tab)}</Tab>
                    )}
                </TabList>
                
                <TabPanel value={0} className="p-0 col-12 ">
                     <GeneralInformationTab objectProfile={profile} currentUser={currentUser} objectName={objectName} objectSlug={objectSlug} onObjectUpdate={setProfile} updatedSerial={serialNumber} objectPermissions={hasPermissions}/>
                </TabPanel>
               
                { profile &&
                 relatedModels.map((tab, index) => 
                    <ReltatedTab 
                       key={index} 
                       index={index} 
                       objectName={objectName} 
                       modelName={modelName} 
                       options={options}
                       profile={profile} 
                       serialNumber={serialNumber} 
                       loading={loading} 
                       tab={tab} 
                       billables={billables}
                       hasPermissions={hasPermissions}
                       setSerialNumber={setSerialNumber} />
                )}

            
            </Tabs> 

            <Content className={'hidden md:block md:flex flex-1'}>
             <StatusNotes objectName={objectName} object={profile} serialNumber={serialNumber} />
            </Content>

        </ContentColumn>
          
</div>

    )
  };
  
export default EditPage;
  // TODO: Screen Resize Adaptation

const ReltatedTab = ({ index, objectName, options, hasPermissions, modelName, profile, billables, serialNumber, loading, tab, setSerialNumber= f => f}) => {
  const [expanded, setExpanded] = useState(false);
  const [relatedOptions, setRelatedOptions] = useState(false);

  const tools = useTools()
  const api = useApi();

  const buildOptions = (fields) =>{
    let props = fields.properties
    let field_list = []
    Object.keys(props).forEach((prop) => {
      field_list.push({ name: prop, ...props[prop] })
    })
    console.log(field_list.sort((a,b) => b.order > a.order))
    return [...field_list].sort((a,b) => a.order > b.order ? 1 : -1)
  }

  React.useEffect(() => {
    //Get the options for the related model
    let opts = updateOptions[tab];
    if (!opts) {
      api
        .options(`/${tab}/create`)
        .then((res) => {
          console.log(res)
          setRelatedOptions(buildOptions(res.data));
        })
        .catch(console.log);
    } else {
      console.log(opts)
      setRelatedOptions(opts);
    }

    return () => {
      setRelatedOptions(null);
      setExpanded(false)
    };
  }, [tab, api]);

  React.useEffect(() => {
     if(!relatedOptions)
     return

      let opts = relatedOptions?.find(e => e.name === modelName)
      let all_opts = relatedOptions.filter(e => e.name !== modelName)
      if(opts && !opts.read_only) {
        opts = { ...opts, read_only : true }
        setRelatedOptions([opts, ...all_opts])
      }

      console.log(relatedOptions)

  },[relatedOptions])

  if(options)
  return (
<TabPanel value={index+1} className="p-0 w-full" key={index}>
                    { tab==='preview' ? 
                    <Content className='flex flex-1'>

                    <PDFViewer className='h-100 flex flex-1' style={{ height: '90vh'}} fileName={`${profile?.number}`}>
                          <PdfDocument objectName={objectName} profile={profile} />
                    </PDFViewer>
                    </Content>

                  :<>
                  { billables.includes(objectName) &&
                     <BillableContent profile={profile} loading={loading} onDataChanges={setSerialNumber}/> }
                     <Content className="bg-red-300">

                     <CreateForm
                            objectName={tab}
                            options={relatedOptions}
                            onComplete={(e) =>  { setSerialNumber(e); setExpanded(false) }}
                            expanded={expanded}
                            onCollapse={setExpanded}
                            profile={{ [`${modelName}`] : profile, [`${modelName}_id`]: profile.id }}
                        />

                     </Content>
                     <ContentBetween className={'align-items-center px-3'}>
                        <HeaderContent className={"col-6 p-2"} header={`${tools.makeLabel(tab)}`} subheading={`You can manage related ${tools.makeLabel(tab)} from here`}/>
                        { options.create_related === 'modal' ? <CreateObjectComponent label={tab} buttonLabel={`Create new`} /> :
                        <Button onClick={() => setExpanded(true)}>{`Add ${tab.slice(0,-1)}`}</Button> }
                     </ContentBetween>
                    
                     <GenericList serialNumber={serialNumber} onDataChanges={setSerialNumber} page_size={10} url={tab} objectName={tab} related_field={`${modelName}_id`} parent_id={profile?.id} parent_name={objectName.slice(0,-1)} createRelated={options?.create_related} permissions={hasPermissions} /> 
                  </>}
                    
                </TabPanel>
  )
}
