import Content, { ContentAround, ContentBetween, ContentColumn } from "../layout/Content"
import aircraft from '../../assets/aircraft.svg';
import { Typography } from "@mui/joy";
import { CheckCircle } from "@mui/icons-material";

const CompletedScreen = () => {
    return <Content className={"row row-cols-1 row-cols-md-2 py-7 justify-content-center"}>
             <img src={aircraft} className={"col-12 col-md-5 md:col-5 text-center mb-4"}/>
             <ContentColumn className={"col-12 col-md-5 md:col-5 text-center"}>
                <Typography className="font-semibold">
                   You have successfully completed your traveller profile
                </Typography>
                <Typography className="">
                   For any further assistance, you may contact us on <b>info@reatswelelatravel.co.za.</b>
                </Typography>

                <CheckCircle className="mx-auto text-green-700 text-8xl my-6"/>
             </ContentColumn>
    </Content>
}

export default CompletedScreen;