import { Button, IconButton, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Content, { ContentBetween, ContentColumn, ContentEnd, HeaderContent, PageHeader, RowValuePair, Spacer } from "../layout/Content";
import { useApi } from "../../utils/useApi";
import { Check, Checklist, CopyAll, CreditCard, Email, EmailOutlined, MoreVert } from "@mui/icons-material";
import moment from "moment";
import { formatCurrency } from "../lists/DisplayTableRow";
import DiscardModal from "../layout/modals/DiscardModal";
import { Logo } from "./Layout";
import useTools from "../../utils/useTools";

const BillableContent = ({ profile, loading, onDataChanges = f => f }) => {

    const api = useApi()
    const tools = useTools()

    const navigate = useNavigate()

    const { objectName } = useParams()
  
    const handleDuplicate = (action='duplicate') => {
        let navigateTo = action === 'make-invoice' ? 'invoices' : objectName;

        api.get(`${objectName}/${action}/${profile?.slug||profile?.id}`)
        .then((response) => {
          console.log(response)
          navigate(`/manage/${navigateTo}/${response.data.slug}`)
          onDataChanges(Math.random)
        }).catch(console.log)
    } 
  
    const handleEmailReminder = () => {
  
    }
    
    const tableName = objectName.slice(0,-1)
    const billableType = profile.is_proforma ? `Proforma ${tools.makeLabel(tableName)}`.toUpperCase() : tools.makeLabel(tableName).toUpperCase()
  
    return(
      <Content className="p-3">
                        <Content className="m-0 row row-cols-2 shadow-1 p-2">
                             <Content className="col border-right-1 border-300 px-3">
                                <ContentBetween className={'align-items-center'}> 
                                    <Logo />
                                </ContentBetween>

                                 <RowValuePair label={billableType} value={profile?.number} loading={loading}valueSize="text-sm"  />
                                 <RowValuePair label={"To"} value={profile?.customer?.name} loading={loading} valueSize="text-sm"/>
                                 <RowValuePair label={"Billing address"} value={profile?.customer?.address} loading={loading} vsx={{ width: 180 }} valueSize="text-sm"/>
                                 <RowValuePair label={"VAT number"} value={profile?.customer?.vat_number||'NA'} loading={loading} valueSize="text-sm" />
                             </Content>
                             <Content className="col px-3">
                                 <ContentBetween className={'align-items-center'}>
                                     <HeaderContent header={"GRAND TOTAL"} subheading={`${profile?.customer?.currency} ${formatCurrency(profile?.grand_total)||'0.00'}`} />
                                     <ContentEnd>

                                     { objectName==='quotations' &&  ( profile.invoice_id ? 
                                        <Button size='sm' variant='soft' onClick={() =>  { navigate(`/manage/invoices/${profile?.invoice?.slug}`); onDataChanges(Math.random) }}>View related invoice</Button>
                                     :
                                     <DiscardModal
                                              onConfirmAction={() => handleDuplicate('make-invoice')}
                                              config={{
                                                color: 'primary',
                                                variant: 'soft',
                                                icon: <CreditCard size='sm' className="hidden md:block"/>,
                                                disabled: false,
                                                action: 'Invoice',
                                                prompt: `You are about to "Invoice ${tools.makeLabel(objectName).slice(0,-1)} ${profile.number}", are you sure?`,
                                                className: ""
                                              }}/> )}
                                         <DiscardModal
                                              onConfirmAction={handleDuplicate}
                                              config={{
                                                color: 'primary',
                                                variant: 'soft',
                                                icon: <CopyAll size='sm' className="hidden md:block"/>,
                                                disabled: false,
                                                action: 'Duplicate',
                                                prompt: `You are about to "Duplicate ${tools.makeLabel(objectName).slice(0,-1)} ${profile.number}", are you sure?`,
                                                className: ""
                                              }}/>
                                              <DiscardModal
                                              onConfirmAction={handleEmailReminder}
                                              config={{
                                                color: 'danger',
                                                variant: 'soft',
                                                icon: <EmailOutlined size='sm' className="hidden md:block"/>,
                                                disabled: false,
                                                action: 'Send Reminder',
                                                prompt: `You are about to "Send email reminder for ${profile.number}", are you sure?`,
                                                className: ""
                                              }}/>
                                     </ContentEnd>
                                 </ContentBetween>
                                 <RowValuePair className={'mt-2'} label={"Invoice date"} value={moment(profile?.created_at).format('MMM DD, YYYY HH:mm:ss')} loading={loading} valueSize="text-sm" />
                                 <RowValuePair label={"Due date"} value={moment(profile?.due_date).format('MMM DD, YYYY')} loading={loading} valueSize="text-sm" />
                                 <RowValuePair label={"VAT number"} value={profile?.tenant?.vat_number||'NA'} loading={loading} valueSize="text-sm" />
                                 <RowValuePair label={"Status"} value={profile?.status} loading={loading} valueSize="text-sm" />
                             </Content>
                        </Content>
                       </Content>
    )
  }

export default BillableContent;