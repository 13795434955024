import { Lock, LockOpen, LockOutlined, PanoramaFishEye } from "@mui/icons-material";
import { Box, Button, IconButton, Input } from "@mui/joy";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTools from "../utils/useTools";
import axios from "axios";
import Content from "../components/layout/Content";

const ForgotPasswordPage = () => {

    const [status, setStatus] = useState(false); 
    const [username, setUsername] = useState(false); 
    const [loading, setLoading] = useState(false); 

    const navigate = useNavigate()
    const tools = useTools()

    useEffect(() => {
        return () => {
            setUsername(null)
            setStatus(null)
        }
        
    },[])

    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validate: (data) => {
            let errors = {};
            if(!data.username)
            {
                errors.username = 'Email address is required.'
            }
            return errors
        },
        onSubmit: (data) => {
            setUsername(data.username)
            setLoading(true)
            axios.post('api/v1/users/forgot-password/', { email: data.username })
            .then((data) => {
                setStatus({ completed: true })
            })
            .catch((e) => { 
                setStatus({ error: true })
                console.log(e)
            })
            .finally(() => setLoading(null))
        }
    })

    const isFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFieldErrorMessage = (name) => (
        isFieldInvalid(name) ? <small className="text-xs text-red-700">{formik.errors[name]}</small> : null
    )


    return (
        <Content className="w-full d-flex justify-content-center align-items-center">
 <Box className="flex flex-column my-4 w-10 md:w-6 lg:w-3">
            <div>
            <p className="font-bold text-lg mb-0">Forgot password</p>
           { status?.completed ? <p className="text-sm mb-0">Password reset email sent.</p> : <p className="text-sm">Enter your email address to reset your password</p> }
            </div>

            { !status?.completed ?
            <form onSubmit={formik.handleSubmit}>

            <div className="my-1">
               <Input id="username" className={isFieldInvalid('username') ? "border-red-700":null} placeholder="Email address" name="username" onChange={(e) => formik.setFieldValue('username', e.target.value)} />
               {getFieldErrorMessage('username')}
            </div>
           
            <div className="my-1">
                 <Link className="text-blue-700 no-underline font-semibold" to={"/login"}>Back to Login?</Link>
            </div>

            { status?.error && <p className="text-sm text-red-600">An error occured while sending an email, please try again later.</p> }
            <div className="mt-3 mb-1 flex justify-content-between align-items-end">
                <div className="min-w-min w-12rem">
                    <Button type="submit" loading={loading}>Reset password</Button>
                </div>
                <p className="text-xs mb-0">
                    By submitting this form to accept our <Link className="text-blue-700 no-underline font-semibold">terms & conditions</Link>
                </p>
            </div>


            

            </form>

            :  <div className="mt-1 mb-1 flex flex-column">
                <p>An email has been sent to <b>{username}</b>, if the account exists, you will receive an email with the password reset instructions.</p>
                <div className="min-w-min w-12rem">
                    <Button onClick={() => navigate("/login")}>Back to Login</Button>
                </div>
            </div> }



            <div className="text-xs my-5 text-gray-500">
            Copyright &copy; {new Date().getFullYear()} <Link target="_blank" to="https:///www.nelotec.co.za/" className="text-blue-700 no-underline font-semibold">Nelotec (Pty) Ltd</Link>
            </div>
        </Box>
        </Content>
       
    )
  };
  
  export default ForgotPasswordPage;
  