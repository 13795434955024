import { Typography } from "@mui/joy"
import Content, { ContentAround, ContentBetween, ContentColumn } from "../layout/Content"
import { Link } from "react-router-dom"
import { useApi } from "../../utils/useApi"
import { useEffect, useState } from "react"

export const LargeNumberLabel = ({ label, value, variant, loading }) => {
      return <ContentColumn className={"align-items-center"}>
                <Typography className={`${variant||'text-blue-700'} text-4xl font-light`}>
                    {value}
                </Typography>
                <Typography>
                    {label}
                </Typography>
      </ContentColumn>
}

const colors = [
    'text-red-700',
    'text-green-700',
    'text-blue-700',
    'text-orange-700',
    'text-purple-700'
]

const DualCard = ({ label, dataSource=[], link={}, dataUrl,  loading }) => {
    const api = useApi()
    const [profile, setProfile] = useState({})

    useEffect(() => {
       if(dataUrl) {
          api.get(`${dataUrl}`).then((response) => {
            setProfile(response.data)
          }).catch(console.log)
       }
    },[dataUrl])
    
    return <ContentBetween className={"flex-1 flex-column shadow-2 p-3 bg-white h-full"}>

        <ContentBetween className={"font-bold"}>
            <Typography className="text-sm">{label}</Typography>
            <Link className="no-underline" to={link?.href}>{link?.label}</Link>
        </ContentBetween>
        <ContentAround>
          { dataSource.map((source, index)  => <LargeNumberLabel key={index} label={source?.label} value={source?.value ?? profile[source?.key] ?? 0}  variant={source?.variant||colors[index]} loading={loading}/> ) }
        </ContentAround>
             
    </ContentBetween>
}

export default DualCard;