import * as React from 'react';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from '../../../utils/useApi';
import { ContentColumn } from '../Content';
import { FormControl, FormHelperText, FormLabel, Textarea } from '@mui/joy';

export default function CreateWorknote({ buttonLabel, variant="outlined", objectName, profile,  loading, disabled, onComplete = f=>f, onCancel = f=>f }) {
  const [open, setOpen] = React.useState(false);
  const api = useApi()

  const control = {
    title: 'Worknotes',
    name: 'work_notes',
    required: true,
    size: 12
  }

  const validationSchema = Yup.object().shape({
            work_notes: Yup.string()
            .min(10, `Notes too short`)
            .max(140, `Please summarise notes, 140 chars or less`)
            .required(`Notes are required`),
  })

  const initialValues = { work_notes: '', relation_table: objectName, relation_table_id: profile.id };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (data) => {
       api.post(`worknotes/`, { ...data, relation_table: objectName })
       .then(() => {
           setOpen(false)
           onComplete(true)
       }).catch((e) => {
           console.log(e)
       })
    }
  })

  const visual_error = React.useMemo(() => {
    return formik?.errors && formik?.errors[control?.name] ? " border-red-600 " : ""
  }, [formik?.errors])

  React.useEffect(() => {
     formik.resetForm()
  },[open])

  return (
    <React.Fragment>
      <Button
        variant={variant}
        size="sm"
        color="primary"
        onClick={() => setOpen(true)}
        disabled={disabled} 
        loading={loading}
      >
        {buttonLabel}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit} noValidate>

        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Reason for update
          </DialogTitle>
          <Divider />
          <DialogContent>
            Please provide a reason for this update ?
            <ContentColumn className={"flex flex-1 pr-3"} style={{ width: 600 }}>

              <FormControl className={`mt-2 col-12 col-md-${control?.size||4} md:col-${control?.size||4}`}  >
                <FormLabel className="font-semibold">{control?.title}<span className={control?.required ? 'text-red-600' : 'hidden'}>*</span></FormLabel>
                <Textarea
                size="sm"
                    className={visual_error}
                    name={control?.name}
                    placeholder={control?.title}
                    required={control?.required}
                    value={formik.values[control?.name]||''} 
                    onChange={formik.handleChange}
                    minRows={3}
                    maxRows={5}
                />
                { control?.helptext && <FormHelperText>{control?.helptext}</FormHelperText> }
                {formik?.errors[control?.name] ? (<small className="text-xs py-0 w-full text-red-600">{formik?.errors[control?.name]}</small>) : null}
            </FormControl> 

            </ContentColumn>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" type="submit" color="primary" onClick={() => { 
                console.log(formik.values)
            }}>
              {buttonLabel}
            </Button>
            <Button variant="plain" color="neutral" onClick={() => { 
                setOpen(false)
                onCancel(true)
            }}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
        </form>

      </Modal>
    </React.Fragment>
  );
}