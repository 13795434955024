import * as React from 'react';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import DiscardModal from './modals/DiscardModal';

export default function AppDropdown({ menuItems=[], emptyMessage ="Empty Dropdown", className, startIcon, label, endIcon, contents, selected, confirm_action=false, onActionComplete = f => f }) {

  const handleMethod = (item) => {
    item.method(selected).then((response) => {
      console.log(response)
      onActionComplete({ item, profile: response })
    }).catch((e) => {
      console.log(e)
      onActionComplete(false)
    })
  }

  return (
    <div className={className}>
    <Dropdown>
      <MenuButton
        className="text-sm mx-1"
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
       { startIcon || <MoreVert /> }
        <span className='hidden md:block'>{label}</span> 
        {endIcon}
      </MenuButton>
      <Menu placement="bottom-end">

       { menuItems.map((item, index) => <React.Fragment key={index}>
          { item.divided && <ListDivider /> }
          
          <MenuItem disabled={item.disabled} onClick={() => { item.action ? item.action() : handleMethod(item) }}>
              { item.decorator  ? <ListItemDecorator sx={{ color: 'inherit' }}>
                { item.decorator }
               </ListItemDecorator> : null }{' '}
               {item.label}
          </MenuItem> 
          
        </React.Fragment> )}

       { menuItems.length==0 && !contents && <MenuItem variant="soft">
          {' '}
          {emptyMessage}
        </MenuItem> }
       { contents }
      </Menu>
    </Dropdown>
    </div>

  );
}