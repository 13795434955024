import { useMemo } from "react"
import { useApi } from "../useApi"
import useTools from "../useTools"

const useExtraActions = () => {

   const api = useApi()
   const tools = useTools()

   const userActions = [
    {
        label : "Suspend user",
        method: (selected) => { return tools.postToUrl(`users/multi-update/update/`, { data : { disabled : true }, selected }) }
    },
    {
        label : "Make admin",
        method: (selected) => { return tools.postToUrl(`users/multi-update/update/`, { data : { is_admin : true }, selected }) }
    }
   ]

   const inviteActions = [
    {
        label : "Mark used",
        method: (selected) => { return tools.postToUrl(`traveller_invites/multi-update/update/`, { data : { is_used : true }, selected }) }
    },
    {
        label : "Mark unused",
        method: (selected) => { return tools.postToUrl(`traveller_invites/multi-update/update/`, { data : { is_used : false }, selected }) }
    },
   ]

   const invoices = [
    {
        label : "Send to customer",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/send-email/`, { data : { selected }}) },
        required_roles: ['is_superuser']
    },
    {
        label : "Move next",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/move-next/`, { data : { is_used : false }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Convert to invoice",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/update/`, { data : { is_proforma : false }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Make pending",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/update/`, { data : { status : "Pending" }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Mark paid",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/update/`, { data : { status : "Paid", is_paid: true }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Cancel",
        method: (selected) => { return tools.postToUrl(`invoices/multi-update/update/`, { data : { status : "Cancelled" }, selected }) },
        required_roles: ['is_superuser']
    }
   ]

   const quotations = [
    {
        label : "Send to customer",
        method: (selected) => { return tools.postToUrl(`quotations/multi-update/send-email/`, { data : { selected }}) },
        required_roles: ['is_superuser']
    },
    {
        label : "Mark submitted",
        method: (selected) => { return tools.postToUrl(`quotations/multi-update/update/`, { data : { status : "Submitted" }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Mark accepted",
        method: (selected) => { return tools.postToUrl(`quotations/multi-update/update/`, { data : { status : "Accepted" }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Mark rejected",
        method: (selected) => { return tools.postToUrl(`quotations/multi-update/update/`, { data : { status : "Rejected" }, selected }) },
        required_roles: ['is_superuser']
    }
   ]

   const credit_notes = [
    {
        label : "Send to customer",
        edit_only: true,
        method: (selected) => { return tools.postToUrl(`credit_notes/multi-update/send-email/`, { data : { selected }}) },
        required_roles: ['is_superuser']
    },
    {
        label : "Request Approval",
        method: (selected) => { return tools.postToUrl(`credit_notes/multi-update/update/`, { data : { status : "Pending Approval", is_approved: false }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Approve",
        method: (selected) => { return tools.postToUrl(`credit_notes/multi-update/update/`, { data : { status : "Approved", is_approved: true }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Reject",
        method: (selected) => { return tools.postToUrl(`credit_notes/multi-update/update/`, { data : { status : "Rejected", is_approved: false }, selected }) },
        required_roles: ['is_superuser']
    },
    {
        label : "Cancel",
        method: (selected) => { return tools.postToUrl(`credit_notes/multi-update/update/`, { data : { status : "Cancelled", is_approved: false }, selected }) },
        required_roles: ['is_superuser']
    }
   ]

   
    const extraActions = {
        users: userActions,
        traveller_invites: inviteActions,
        invoices,
        quotations,
        credit_notes
    }


    const hasExtra = (model_name) => extraActions[model_name] != null;
    return [extraActions, hasExtra]
}

export default useExtraActions;