import { Checkbox, CircularProgress, FormControl, FormLabel, IconButton, Input, ListDivider, Switch, Typography, colors } from "@mui/joy"
import { useEffect, useRef, useState } from "react"
import Autocomplete, { createFilterOptions } from '@mui/joy/Autocomplete';
import { Link, useNavigate } from "react-router-dom";
import { FiMaximize2 } from "react-icons/fi";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useApi } from "../../utils/useApi";
import Content, { ContentBetween, ContentStart, FieldDisplay, HeaderContent } from "../layout/Content";
import useTools from "../../utils/useTools";
import { Apartment, Circle, LibraryAdd, LibraryAddCheck, Person } from "@mui/icons-material";

const pageDefaults = {
  page: 1,
  page_size: 6,
  limit: 6,
  skip: 0,
  operator: 'contains'
}

const AppAutocomplete = ({onChange = f => f, ...props}) => {

    const [options, setOptions] = useState(null);
    const [value, setValue] = useState(null);

    const [loading, setLoading] = useState(false)
    const field = props.field
    
    const api = useApi()

    const navigate = useNavigate()

    const createOptions = () => {
         let value = props.value
         let opts = value ? [value] : []

         setOptions(opts)
         setValue(opts.length ? opts[0] : null)
    }

    useEffect(() => {
      createOptions()
    }, [props.value])

    useEffect(() => {
    // console.log(value)
    },[value])

    const fetchURLoptions = (searchTerm) => {
        let obj = pageDefaults;

        obj = {
           filter_field: field.search_field,
           value: searchTerm,
           ordering: field.search_field,
           ...obj
        }

        if(!field.related_url)
        return

        api.get(`${field.related_url}/?page_object=${JSON.stringify(obj)}`)
        .then((res) => { 
         let opts = value ? [value] : []
         setOptions([ ...res.data.results ])
        })
        .catch(console.log)
    }

    const handleSearch = (event, searchTerm) => {
     console.log(searchTerm)
     fetchURLoptions(searchTerm)
    }

    const handleChange = (event, newValue) => {
       setValue(newValue)
       onChange(event, newValue)

       console.log(event)
    }

    const getIcon = (icon) => {
      switch(icon) {
        case 'users': return <Person />;
        case 'tenants': 
        case 'customers': 
        return <Apartment />;
        default: return <LibraryAddCheck />

      }
    }

    


    return (options && <ContentBetween className={"align-items-center"}>
         <Content className="flex-1">
         <Autocomplete 
          {...props } 
          options={options}
          value={value}
          onChange={handleChange}
          onInputChange={handleSearch}
          getOptionLabel={option => option.full_name||option.name||option.display||option.email||option}
          isOptionEqualToValue={option => option.id||option}
          endDecorator={
           loading ? (
             <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
           ) : null
          }
          />
         </Content>
         { value &&  <HTMLToolTip title={<RelatedObject label={props.placeholder} profile={value} options={field.related_url} />} enterDelay={500} leaveDelay={200}>
                <IconButton variant="outlined" size="sm" style={{ marginBottom: -5 }} className="ml-1 text-gray-600 py-0 m-0" onClick={() => navigate(`/manage/${field?.related_url}/${value?.slug}/`)}>
                    {getIcon(field.related_url)}  
                </IconButton>
          </HTMLToolTip> 
          }
         </ContentBetween>)
}

export default AppAutocomplete;



const filter = createFilterOptions();

const HTMLToolTip = styled(({ className, ...props})  => (
  <Tooltip {...props} classes={{ popper: className }} arrow  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`] : { 
    backgroundColor: theme.palette.common.white,
    color: colors.grey[700],
    boxShadow: colors.grey[300],
    fontSize: 10,
  }
}))

const RelatedObject = ({ profile, options, label }) => {
  const api = useApi()
  const tools = useTools()

  const [fields, setFields] = useState()

  useEffect(() => {
       api.options(`${options}/`)
       .then((response) => {
        setFields(response.data)
       }).catch(console.log)
  },[options])

  return ( fields && <Content className={"border-1 border-200 row row-cols-2 bg-white border-round-md shadow-6"} style={{ minWidth: 600, maxHeight: 400, overflowY: 'scroll' }}>  
            <HeaderContent header={label} className={"w-full mb-3 bg-bluegray-50 px-4 py-2"} subheading={`This is a preview of the ${label}.`}/>
            <Content className="row row-cols-3 w-full p-4" style={{ minWidth: 600 }}>
            { Object.keys(fields.properties).map((field, index) => profile[field] && <FieldDisplay key={index} fieldName={tools.makeLabel(field)} value={`${profile[field]}`}  className={"col-6"}/> )}
            </Content>
  </Content>)
}
