import { useMemo} from "react";
import DayData from "./DayData";


const WeekData = ({ weekName, items, index, dayList, reminders, onDaySelect = f=>f, onItemSelect = f=>f }) => {
    const rows = useMemo(() => {
        let dataList = [];
        let day = 0
        for(let i=1; i < (8 - dayList.length); i++)
        {
            dataList.push({ day })
            day+=1
        }
        if(index===0)
            return [...dataList, ...dayList]
        return [...dayList, ...dataList]
    },[dayList])

    return <tr className="w-full flex"> 
             { rows.map((day, i) => <DayData key={i} index={index} currentDay={day} reminders={reminders} onDaySelect={onDaySelect} onItemSelect={onItemSelect}  /> )}
          </tr>
  }

  export default WeekData;

