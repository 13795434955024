import { Typography } from "@mui/joy";
import { ContentColumn } from "./Content";

const PermissionDenied = ({ currentUser }) => {
    return <ContentColumn className={"align-items-center py-7"}>
          <Typography className="text-2xl text-red-700">
          403 Permission Denied
          </Typography>

          <Typography>
              You do not have enough permissions to view this page, this action will be reported.
          </Typography>
    </ContentColumn>
}

export default PermissionDenied;