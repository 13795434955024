import { Chip, List, ListDivider, ListItem, ListItemContent, Typography } from "@mui/joy"
import { ContentBetween, ContentColumn } from "../layout/Content"
import { Link } from "react-router-dom";
import { Fragment } from "react";
import useTools from "../../utils/useTools";

const ListCard = ({ label, dataSource=[], link={}, loading }) => {
    const { camelCase } = useTools();

    return (<ContentColumn className={"flex-1 flex-column shadow-2 p-3 bg-white h-full"}>
        <ContentBetween className={"font-bold"}>
            <Typography className="text-sm">{label}</Typography>
            <Link className="no-underline" to={link?.href}>{link?.label}</Link>
        </ContentBetween>

        <List component="nav">
                    { dataSource?.data?.map((item, index) => <Fragment key={index}>
                    <ListItem key={index}>
                        {item.icon}
                        <ListItemContent>{camelCase(item.category)}</ListItemContent>
                       { item?.nochip ? <Typography className="font-bold text-right">{item.total}</Typography> : 
                        <Chip variant="solid" color={item.indicator} size="sm" className="px-2" onClick={() => {}}>{item.total}</Chip>
                        }
                    </ListItem> 

                    { index != dataSource.data.length-1 && <ListDivider/> }
                    </Fragment>
                    )}
        </List>

    </ContentColumn>)
}

export default ListCard;