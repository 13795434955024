import { Box, FormControl, FormLabel, IconButton, Option, Select, Typography } from "@mui/joy";
import * as React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const PaginateComponents = ({ rows, mobile=false, pageObject, dataObject,  handleChangePage = f => f, handleChangeRowsPerPage = f => f, onSlideDirection = f=>f }) => {
    const getLabelDisplayedRowsTo = () => {
        let value = parseInt(pageObject?.page-1)*parseInt(pageObject?.page_size) + parseInt(pageObject?.page_size)
        return value > dataObject?.count ? dataObject?.count : value
    };
   

    function labelDisplayedRows({ from, to, count }) {
        return `${from}–${to} of ${count !== -1 ? count||0 : `more than ${to}`}`;
      }

    return (
        
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
             { !mobile &&   <FormControl orientation="horizontal" size="sm">
                   <FormLabel>Rows per page:</FormLabel> 
                   <Select onChange={handleChangeRowsPerPage} value={parseInt(pageObject?.page_size)||10}>
                      <Option value={5}>5</Option>
                      <Option value={10}>10</Option>
                      <Option value={25}>25</Option>
                      <Option value={50}>50</Option>
                  </Select> 
                </FormControl> }

                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rows?.length === 0 ? 0 : (parseInt(pageObject?.page)-1) * parseInt(pageObject?.page_size) + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rows?.length === -1 ? -1 : dataObject?.count,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={!(parseInt(pageObject?.page) > 1)}
                    onClick={() => { handleChangePage((parseInt(pageObject?.page) - 1)||1); ; onSlideDirection("right")}}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      (parseInt(pageObject?.page)*parseInt(pageObject?.page_size)) >= dataObject?.count
                    }
                    onClick={() => { handleChangePage(parseInt(pageObject?.page) + 1); onSlideDirection("left") }}
                    sx={{ bgcolor: 'background.surface' }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
    )
}


export default PaginateComponents;