import real_logo from "../../assets/logo.png";
import {  Outlet, useLocation, useNavigate } from "react-router-dom";
import Content, { ContentBetween, ContentColumn } from "./Content";
import { useAppContext } from "../../utils/AppContext";
import { useApi } from "../../utils/useApi";
import LinearProgress from "@mui/joy/LinearProgress";
import { useEffect, useMemo, useState } from "react";
import { AppBlocking } from "@mui/icons-material";
import NotificationList from "./NotificationList";
import AppDropdown from "./AppDropdown";
import {
  Avatar,
  Box,
  Link,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ModalClose,
  Tooltip,
} from "@mui/joy";
import useTools from "../../utils/useTools";
import AppSnackBar from "./AppSnackBar";
import ChangePasswordModal from "../formcontrols/ChangePasswordModal";
import * as React from "react";

import {
  AlarmOn,
  AirportShuttle,
  AppRegistration,
  CarCrash,
  CarRepair,
  CarRental,
  ChevronLeft,
  ChevronRight,
  Circle,
  Dvr,
  Dashboard,
  DockSharp,
  GpsNotFixedRounded,
  HomeMaxRounded,
  HomeMini,
  Logout,
  Menu,
  Monitor,
  Notifications,
  Password,
  Person,
  PersonSearch,
  Settings,
  Search,
  Fingerprint,
  Shop,
  Shop2Outlined,
  SimCard,
  SpeedRounded,
  VerifiedUserSharp,
  AddReaction,
  Apartment,
  CircleSharp,
  CircleRounded,
  Receipt,
  DescriptionOutlined,
  FileCopyOutlined,
  InsertDriveFile,
  RequestPageOutlined,
  TaskOutlined,
  PeopleAltOutlined,
  Wallet,
  Widgets
} from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import useMeasure from "react-use-measure";

const APP_VERSION = "1.7.0";
export const logo = ["localhost", "127.0.0.1"].includes(
  window.location.hostname
)
  ?  "https://www.afrihost.com/images/logo.png"//"https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c3"
  : real_logo;

export const appIcons = {
  AlarmOn,
  AirportShuttle,
  AppRegistration,
  AppBlocking,
  Dvr,
  Search,
  CarCrash,
  CarRepair,
  CarRental,
  ChevronLeft,
  ChevronRight,
  Circle,
  Dashboard,
  DockSharp,
  GpsNotFixedRounded,
  HomeMaxRounded,
  HomeMini,
  Logout,
  Menu,
  Monitor,
  Notifications,
  Password,
  Person,
  PersonSearch,
  Settings,
  Fingerprint,
  Shop,
  Shop2Outlined,
  SimCard,
  SpeedRounded,
  VerifiedUserSharp,
  AddReaction,
  Apartment,
  CircleSharp,
  Receipt,
  DescriptionOutlined,
  FileCopyOutlined,
  InsertDriveFile,
  RequestPageOutlined,
  TaskOutlined,
  PeopleAltOutlined,
  Widgets
};

const getIconByName = (iconName) => {
  switch (iconName) {
    case "SpeedRounded":
      return SpeedRounded;
    default:
      return appIcons[iconName] || CircleRounded;
  }
};

export const checkUserLinkRights = (menuItem, currentUser) => {
    if (!currentUser) return false;
  
    if (currentUser.is_superuser) return true;
  
    if (!menuItem.required_roles) return true;
    if (menuItem.required_roles.length == 0) return true;
    let has_right = false;
    menuItem.required_roles.forEach((element) => {
      has_right = has_right || currentUser[element.name];
    });
    return has_right;
  };


const SideMenu = ({ showToast = (f) => f, navLinks=[], onLinksChanged = f => f }) => {
    const { state, dispatch } = useAppContext();
    const { currentUser, routeObject } = state;
  
    const [menuItemRows, setMenuItemRows] = useState([])
    const [collapsed, setCollapsed] = useState(null);
  
    const navigate = useNavigate();
    const api = useApi()

    const [ref, { width }] = useMeasure();
    const props = useSpring({
      width: !collapsed ? width : 0,
      config: { duration: "20" },
    });
  
    const setRoute = (manage) => {
      dispatch({ type: "setItem", name: "routeObject", value: manage });
    };
  
    const hasRight = (menuItem) => {
      return checkUserLinkRights(menuItem, currentUser);
    };

    const getMenuItemRows = React.useCallback(() => {
        api.get(`menu_items/?page_object=${JSON.stringify({ limit: 100, skip: 0, ordering: 'order' })}`)
        .then((response) => {
          setMenuItemRows(response.data.results)
          onLinksChanged(response.data.results)
          console.log(response.data.results)
        })
        .catch(console.log)
    },[api])
  
  
    useEffect(() => {
           let menuState = localStorage.getItem("menuopenstate")
           if(menuState)
           {
              setCollapsed(JSON.parse(menuState).sideMenu)
           }
           getMenuItemRows()
    },[])
  
    useEffect(() => {
      if(collapsed!==null) {
         localStorage.setItem("menuopenstate", JSON.stringify({ sideMenu: collapsed }))
      }
    },[collapsed])
  
  
    return (
      <Content
        className="hidden md:flex flex-column border-right-1 border-200 bg-white pb-5 pt-7 px-1 z-4"
        style={{ minHeight: "96.7vh", maxHeight: "99.9vh" }}
      >
        {navLinks.map((nav, index) => {
          const CustomIcon = getIconByName(nav.icon);
  
          return (
            <React.Fragment key={index}>
              {nav.divider && <Divider  className="my-3" />}
              {nav.label && (
                <IconButton
                  className={
                    routeObject == nav.manage
                      ? "bg-blue-100 text-white-100 my-1 px-3"
                      : "text-gray-800 my-1 px-3"
                  }
                  key={index}
                  onClick={() => {
                    hasRight(nav) && setRoute(nav.manage);
                    hasRight(nav)
                      ? navigate(nav.path)
                      : showToast("Permission denied", "danger");
                  }}
                >
                  <Tooltip 
                  placement="right"
                  variant="outlined"
                  arrow
                  title={nav.label}>
                    <div
                      className="flex justify-content-start"
                      ref={ref}
                      style={{ maxWidth: 180, overflowX: "hidden" }}
                    >
                      <CustomIcon />
                      <animated.div style={props}>
                        {collapsed || (
                          <div className="text-left ml-2 white-space-nowrap text-overflow-clip">
                            {nav.label}
                          </div>
                        )}
                      </animated.div>
                    </div>
                  </Tooltip>
                </IconButton>
              )}
            </React.Fragment>
          );
        })}
  
        {menuItemRows.map((nav, index) => {
          const CustomIcon = getIconByName(nav.icon);
  
          return (
            <React.Fragment key={index}>
              {nav.divider && <Divider className="my-4"/>}
              {nav.name && (
                <IconButton
                  className={
                    routeObject == nav.manages
                      ? "bg-blue-100 text-white-100 my-1 px-3"
                      : "text-gray-800 my-1 px-3"
                  }
                  key={index}
                  onClick={() => {
                    hasRight(nav) && setRoute(nav.manages);
                    hasRight(nav)
                      ? navigate(nav.path)
                      : showToast("Permission denied", "danger");
                  }}
                >
                  <Tooltip 
                  placement="right"
                  variant="outlined"
                  arrow
                  title={nav.name}
                  >
                    <div
                      className="flex justify-content-start"
                      ref={ref}
                      style={{ maxWidth: 180, overflowX: "hidden" }}
                    >
                      <CustomIcon />
                      <animated.div style={props}>
                        {collapsed || (
                          <div className="text-left ml-2 white-space-nowrap text-overflow-clip">
                            {nav.name}
                          </div>
                        )}
                      </animated.div>
                    </div>
                  </Tooltip>
                </IconButton>
              )}
            </React.Fragment>
          );
        })}
  
        <Content className="mt-auto mb-5 flex">
          <IconButton
            className="ml-auto px-3"
            onClick={() => setCollapsed((e) => !e)}
          >
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </Content>
      </Content>
    );
  };

  export default SideMenu;