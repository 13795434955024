import { Lock, LockOpen, LockOutlined, PanoramaFishEye } from "@mui/icons-material";
import { Box, Button, IconButton, Input } from "@mui/joy";
import { useFormik } from "formik";
import { useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { refreshKeyName, useAppContext } from "../utils/AppContext";
import axios from "axios";
import Cookies from "js-cookie";
import Content from "../components/layout/Content";

const LoginPage = () => {

    const [loginError, setLoginError] = useState(false); 

    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setShowPassword] = useState(false); 
    const [loading, setLoading] = useState(false); 

    const { state, logout, initialize, dispatch } = useAppContext();
    const { loginClient, loginClientSecret, authKeyName } = state;

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const login = (creds) => {
        return new Promise((resolve, reject) => {
        logout().then((result) => {
            //let authKey = result.key; // logout return authKeyName from App State
            
            // let data = {
            //     grant_type: "password",
            //     username : creds.username,
            //     password : creds.password,
            //     client_id: loginClient,
            //     client_secret: loginClientSecret,
            //     scopes: 'read write'
            // }

            axios.post("/oauth/token/", {
                username: creds.username,
                password: creds.password
            }, { headers: { 'Content-Type':'application/x-www-form-urlencoded'}}).then((response) => {
               let _token = response.data.access_token;
               localStorage.setItem(authKeyName, _token);
               Cookies.set(refreshKeyName, response.data.refresh_token )

               initialize(response.data.refresh_token); //Set current user and User Data
               resolve(response.data); //Resolve the response data
            }).catch((e) => {
               reject(e); //Return the error as is to calling function
            })

        }).catch(console.log)

        })

    }


    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};
            setErrorMessage('')
            if(!data.username)
            {
                errors.username = 'Username is required.'
            }
            if(!data.password)
            {
                errors.password = 'Password is required.'
            }
            else if(data.password && data.password.length < 8)
            {
                errors.password = 'Must be minimum of 8 characters.'
            }
            return errors
        },
        onSubmit: (data) => {
            setLoading(true)
           login(data).then((data) => {
              console.log(data)
              dispatch({ type: 'setItem', name:'refreshToken', value: data.refresh_token })

                let next = searchParams.get('next')
                let authToken = localStorage.getItem(authKeyName)

                next = next != '/login' ? next : '/dashboard'
                let pathTo = location?.state?.pathTo || next || '/dashboard'
                if(authToken) {
                    navigate(pathTo)
                }

           }).catch((e) => {
               console.log(e)
               setLoginError(true);
               setErrorMessage(e?.response?.data?.detail)
           }).finally(() => {
               setLoading(false);
           })
        }
    })

    const isFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFieldErrorMessage = (name) => (
        isFieldInvalid(name) ? <small className="text-xs text-red-700">{formik.errors[name]}</small> : null
    )

    const ShowHideIcon = () => (<IconButton onClick={() => setShowPassword(e => !e)}>{showPassword ? <LockOpen/> : <LockOutlined/>}</IconButton>   )


    return (
        <Content className="w-full d-flex justify-content-center align-items-center">

        <Box className="flex flex-column my-4 w-10 md:w-6 lg:w-4 xl:w-3 max-w-30rem">
            <div>
            { loginError && <p className="text-center text-xs text-red-700">{errorMessage}</p>  }
            <p className="font-bold text-lg mb-0">Login</p>
            <p className="text-sm">Enter your username and password</p>
            </div>

            <form onSubmit={formik.handleSubmit}>

            <div className="my-1">
               <Input id="username" className={isFieldInvalid('username') ? "border-red-700":null} placeholder="Username or Email" name="username" onChange={(e) => formik.setFieldValue('username', e.target.value)} />
               {getFieldErrorMessage('username')}
            </div>
            <div className="my-1">
               <Input 
                   className={isFieldInvalid('password') ? "border-red-700":null} 
                   placeholder="Password" type={ showPassword ? "text": "password"} 
                   name="password" 
                   onChange={(e) => formik.setFieldValue('password', e.target.value)} 
                   endDecorator={<ShowHideIcon />}
                />
               {getFieldErrorMessage('password')}
            </div>
            <div className="my-1">
                 <Link className="text-blue-700 no-underline font-semibold" to={"/forgot-password"}>Forgot password?</Link>
            </div>
            <div className="mt-3 mb-1 flex justify-content-between align-items-end">
                <div className="min-w-min w-8rem">
                    <Button type="submit" loading={loading}>Sign in</Button>
                </div>
                <p className="text-xs mb-0">
                    By signing in, you accept our <Link className="text-blue-700 no-underline font-semibold">terms & conditions</Link>
                </p>
            </div>
            </form>

        </Box>

        </Content>

    )
  };
  
  export default LoginPage;
  


