import { FormControl, FormLabel, Input } from "@mui/joy"
import Typography from '@mui/joy/Typography';
import Switch from '@mui/joy/Switch';
import * as React from 'react';
import { useEffect, useState } from "react"
import AppAutocomplete from "./AppAutocomplete";
import { ContentBetween } from "../layout/Content";
import useTools from "../../utils/useTools";
import SlushBucket from "./SlushBucket";


export const AppSwitch = ({ onCheckChange = f => f, checked=false, name, options=['On','Off'] }) => {
    const [inner_checked, setChecked] = useState(checked)

    const onChange = (value) => {
        onCheckChange(value)
        setChecked(value)
    }

    useEffect(() => {
        setChecked(checked)
    },[checked])

    return (
            <Switch
                checked={inner_checked}
                onChange={(event) => {onChange(event.target.checked)}}
                color={inner_checked ? 'success' : 'neutral'}
                variant={inner_checked ? 'solid' : 'outlined'}
                name={name}
                endDecorator={inner_checked ? options[0] : options[1]}
                slotProps={{
                  endDecorator: {
                    sx: {
                      minWidth: 100,
                    },
                  },
                }}
              />
    )
}


export const FormField = ({ field = {}, onFocus = f => f, className, autoFocus, title, name, required, value, disabled, type, onSetField = f => f, onChange = f => f,  ...props }, options=['On','Off']) => {
    const [innerValue, setInnerValue] = useState(value)
   
    useEffect(() => {
        setInnerValue(value)
        //console.log(value)
    },[value])

    const { fieldType } = useTools()
    return (
      <>
      {/* { ['boolean'].includes(fieldType(field)) &&
      <FormControl className="mt-4 pt-2">
         <Checkbox label={title} checked={innerValue==true} disabled={disabled} onChange={(e) => setInnerValue(e.target.checked)}/>
      </FormControl> } */}

      { ['boolean'].includes(fieldType(field)) && <FormControl>
      <FormLabel className="font-bold">&nbsp;</FormLabel>
      <ContentBetween className={"bg-bluegray-100 p-1 px-2 border-round-md"}>
                  <Typography className="font-bold text-sm">{title}</Typography>
                  <Switch
                            checked={innerValue||false}
                            onChange={(event) => {setInnerValue(event.target.checked)}}
                            color={innerValue ? 'success' : 'neutral'}
                            variant={innerValue ? 'solid' : 'outlined'}
                            name={name}
                            endDecorator={innerValue ? options[0] : options[1]}
                            slotProps={{
                              endDecorator: {
                                sx: {
                                  minWidth: 100,
                                },
                              },
                            }}
                          />
      </ContentBetween>
      </FormControl>
       }
      
      { ['string','email','number','integer','date-time','password','text'].includes(fieldType(field)||type) &&
      <FormControl className="w-full">
            <FormLabel className="font-bold">{title}{ required && <span className='text-red-600'>*</span>}</FormLabel>
            <Input type={type} className={className} onFocus={onFocus} placeholder={title||field.title} name={name} onChange={onChange} value={innerValue} disabled={disabled} {...props } />
      </FormControl> 
      }

      {
         
        ['related','autocomplete'].includes(fieldType(field)||type) &&
        <FormControl className="w-full">
            <FormLabel className="font-bold">{title}{ required && <span className='text-red-600'>*</span>}</FormLabel>
            <AppAutocomplete
                getOptionLabel={option => option.name||option}  
                type={type} 
                field={field}
                className={className} 
                onFocus={onFocus} 
                placeholder={title||field.title} 
                name={name} 
                onChange={(e,v) => onSetField(name, v)} 
                value={innerValue} 
                disabled={disabled} 
                {...props } 
            />
         </FormControl>
      }




     {
        ['multiselect'].includes(fieldType(field)||type) && 
        <SlushBucket onChange={(v) => onSetField(name, v)} field={field} profile={props.profile} objectName={props.objectName} value={value}/>

      }



      </>
    )
  }


