import real_logo from '../../assets/logo.png'
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Content from "./Content";
import { useAppContext } from "../../utils/AppContext";
import { useApi } from '../../utils/useApi';
import { useEffect } from 'react';
import { LinearProgress } from '@mui/joy';

const APP_VERSION = "1.6.9 Beta"
export const logo = ['localhost','127.0.0.1'].includes(window.location.hostname) 
? "https://www.afrihost.com/images/logo.png"//"https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c3" 
: real_logo

const Public = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const { state, initialize, dispatch } = useAppContext();
    const { authToken, currentUser, routeObject, authKeyName, loading } = state;

    const api = useApi();
    const location = useLocation();

    useEffect(() => {
        dispatch({ type: 'setItem', name:'loading', value: true })

        let authToken = localStorage.getItem(authKeyName);
        if(!authToken){
            dispatch({ type: 'setItem', name:'loading', value: false })
            return
        }
      
        let next = searchParams.get('next')
        next = next != '/login' ? next : '/dashboard'
        let pathTo = location?.state?.pathTo || next || '/dashboard'
        if(authToken) {
            navigate(pathTo)
        }
      
      },[])

    return (
       <Content className="max-w-screen m-0 p-0 h-screen bg-bluegray-50 overflow-scroll">

         { !loading && (  currentUser ? <Content className="flex border-bottom-1 border-300 bg-white absolute top-0 left-0 w-full z-5">
               <img src={logo} alt="Reatswelela travel - Webdesk" className='p-2 w-8rem'/>
         </Content> : 
         <Content className="flex shadow-1 bg-white fixed top-0 left-0 w-full z-5">
               <img src={logo} alt="Reatswelela travel - Webdesk" className='p-2 w-8rem mx-auto'/>
         </Content>
         )}

        { loading ? <Content className="w-screen h-full flex justify-content-center align-items-center flex-column" >
                            <img src={logo} alt="Reatswelela travel - Webdesk" className='p-2 w-8rem'/>
                            <Content className="w-20rem mt-3">
                            <LinearProgress variant="soft"  thickness={5} />
                            </Content>
                    </Content>
         :
            <Content className="py-6 flex flex-1 overflow-y-scroll" style={{ maxHeigh: '40vh', maxWidth: '100vw' }}>
                <Outlet />
            </Content>
         }


       { !loading &&  <Content className="text-xs flex justify-content-center align-items-center mt-auto">
                <p>Powered by <b><Link target="_blank" to="https:///www.nelotec.co.za/" className="text-gray-900 no-underline font-semibold">Nelotec (Pty) Ltd</Link></b> Copyright &copy; {new Date().getFullYear()} | All rights reserved</p>
         </Content> }

       </Content>

    )
}

export default Public;