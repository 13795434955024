import { Button, Typography } from "@mui/joy";
import Content from "../components/layout/Content";
import { logo } from "../components/layout/Layout";
import { ErrorRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
    const navigate = useNavigate()
    return (
        <Content className="w-screen h-screen flex justify-content-center align-items-center flex-column">
                <img src={logo} alt="Reatswelela travel - Webdesk" className='p-2 w-8rem'/>
                <Typography className="text-8xl text-bluegray-500 my-0 font-bold">404</Typography>
                <Typography className="text-3xl my-0 font-bold text-gray-600">Page not found</Typography>
                <Typography className="text-lg text-gray-600">The requested page could not be found</Typography>
                <Button size="sm" onClick={() => navigate(-1)} className="my-3 ">Go back</Button>
                <Content className="w-40rem">
                   <ErrorRounded className="mx-auto text-red-700 text-5xl my-2"/>
                </Content>
        </Content>
    )
}

export default NotFoundPage;