import real_logo from "../../assets/logo.png";
import {  Outlet, useLocation, useNavigate } from "react-router-dom";
import Content, { ContentBetween, ContentColumn } from "./Content";
import { useAppContext } from "../../utils/AppContext";
import { useApi } from "../../utils/useApi";
import LinearProgress from "@mui/joy/LinearProgress";
import { useEffect, useMemo, useState } from "react";
import { AppBlocking } from "@mui/icons-material";
import NotificationList from "./NotificationList";
import AppDropdown from "./AppDropdown";
import {
  Avatar,
  Box,
  Link,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ModalClose,
  Tooltip,
} from "@mui/joy";
import useTools from "../../utils/useTools";
import AppSnackBar from "./AppSnackBar";
import ChangePasswordModal from "../formcontrols/ChangePasswordModal";
import * as React from "react";

import {
  AlarmOn,
  AirportShuttle,
  AppRegistration,
  CarCrash,
  CarRepair,
  CarRental,
  ChevronLeft,
  ChevronRight,
  Circle,
  Dvr,
  Dashboard,
  DockSharp,
  GpsNotFixedRounded,
  HomeMaxRounded,
  HomeMini,
  Logout,
  Menu,
  Monitor,
  Notifications,
  Password,
  Person,
  PersonSearch,
  Settings,
  Fingerprint,
  Shop,
  Shop2Outlined,
  SimCard,
  SpeedRounded,
  VerifiedUserSharp,
  AddReaction,
  Apartment,
  CircleSharp,
  CircleRounded,
} from "@mui/icons-material";
import { useSpring, animated } from "react-spring";
import useMeasure from "react-use-measure";
import SideMenu, { appIcons as IconMan, checkUserLinkRights } from "./SideMenu";

const APP_VERSION = "1.7.0";
export const logo = ["localhost", "127.0.0.1"].includes(
  window.location.hostname
)
  ?  "https://www.afrihost.com/images/logo.png"//"https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE1Mu3b?ver=5c3"
  : real_logo;


export const appIcons = IconMan;

const getIconByName = (iconName) => {
  switch (iconName) {
    case "SpeedRounded":
      return SpeedRounded;
    default:
      return appIcons[iconName] || CircleRounded;
  }
};

export const navLinks = [
  
  {
    label: "Dashboard",
    icon: "SpeedRounded",
    path: "/dashboard",
    manage: "dashboard",
    required_roles: [],
  },
  // {
  //   label: "Rentals",
  //   icon: "CarRental",
  //   path: "/manage/rentals",
  //   manage: "vehicles",
  //   required_roles: ["is_superuser"],
  // },
  // {
  //   label: "Reminders",
  //   icon: "AlarmOn",
  //   path: "/manage/reminders",
  //   manage: "reminders",
  //   required_roles: ["is_superuser"],
  // },

  // {
  //   label: "Travellers",
  //   icon: "PersonSearch",
  //   path: "/manage/traveller_profiles",
  //   manage: "travellers",
  //   required_roles: ["is_staff", "is_account_admin", "is_superuser"],
  // },
  // {
  //   label: "Invites",
  //   icon: "DockSharp",
  //   path: "/manage/traveller_invites",
  //   manage: "travellerinvites",
  //   required_roles: ["is_account_admin", "is_account_admin"],
  // },
  // {
  //   divider: true,
  // },
  // {
  //   label: "Settings",
  //   icon: "Settings",
  //   path: "/manage/users",
  //   manage: "users",
  //   required_roles: ["is_superuser"],
  // },
  // {
  //   label: "Permissions",
  //   icon: "Fingerprint",
  //   path: "/manage/permissions",
  //   manage: "permissions",
  //   required_roles: ["is_superuser"],
  // },
  // {
  //   label: "Menu Items",
  //   icon: "AddReaction",
  //   path: "/manage/menuitems",
  //   manage: "menuitems",
  //   required_roles: ["is_superuser"],
  // },
];

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, initialize, dispatch, logout } = useAppContext();
  const { currentUser, routeObject, authKeyName, loading } = state;

  const [open, setOpen] = useState(false);

  const [openChangePassword, setOpenChangePassword] = useState(false);

  const [menuWidth, setMenuWidth] = useState([]);

  const [navLinkRows, setNavLinkRows] = useState([]);

  const api = useApi();
  const sideMenuRef = React.useRef();

  const { avatarize, dataFromUrl } = useTools();
  const [triggerToast, setTriggerToast] = useState({
    message: "",
    counter: 0,
    color: "success",
  });

  const hasRight = (menuItem) => {
    return checkUserLinkRights(menuItem, currentUser);
  };

  const showToast = (message, type = "primary") => {
    setTriggerToast((prev) => ({
      message: message,
      counter: prev.counter + 1,
      color: type,
    }));
  };

  const hideToast = () => {
    setTriggerToast((prev) => ({ message: null, counter: 0 }));
  };

  const getUser = React.useCallback(() => {
    api
      .get(`users/current_user/`)
      .then((response) => {
        dispatch({
          type: "setItem",
          name: "currentUser",
          value: response.data,
        });
        //console.log(response);
      })
      .catch(console.log);
  }, [api]);

  useEffect(() => {
    let authToken = localStorage.getItem(authKeyName);
    //console.log(authToken);
    if (authToken) {
      dispatch({ type: "setItem", name: "loading", value: false });
    } else {
      navigate("/login", { state: { pathTo: location.path } });
    }
  }, []);

  useEffect(() => {
    initialize()
      .then(() => {
        //console.log("Done initializing page");
      })
      .catch(console.log);
  }, []);


  const buildAuthList = useMemo(() => {
    let version = APP_VERSION;
    return currentUser
      ? [
          {
            label: <small>{"Version " + version}</small>,
            decorator: <AppBlocking />,
            action: () => {
              console.log(version);
            },
          },
          {
            label: (
              <div className="flex flex-column">
                {currentUser.full_name}
                <small className="text-gray-400">{currentUser.email}</small>
              </div>
            ),
            decorator: (
              <Avatar size="sm">{avatarize(currentUser.full_name)}</Avatar>
            ),
            action: () => {
              navigate(`/manage/users/${currentUser?.slug}`);
            },
          },
          {
            label: "Change password",
            decorator: <Password />,
            action: () => {
              setOpenChangePassword(true);
            },
          },
          {
            label: "Logout",
            divided: true,
            decorator: <Logout />,
            action: () => {
              logout().then(() => navigate("/login"));
            },
          },
        ]
      : [
          {
            label: "Refresh App",
            decorator: <Logout />,
            action: () => {
              logout().then(() => navigate("/login"));
            },
          },
        ];
  }, [currentUser]);

  useEffect(() => {
    if (!sideMenuRef?.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (sideMenuRef?.current?.offsetWidth !== menuWidth) {
        setMenuWidth(sideMenuRef?.current?.offsetWidth);
      }
    });

    resizeObserver.observe(sideMenuRef?.current);
  }, [sideMenuRef?.current]);

  return (
    <Content className="h-screen overflow-hidden">
      <Content className="relative" style={{ height: "99.9vh" }}>
        {!loading &&
          (currentUser ? (
            <Content className="absolute top-0 left-0 flex align-items-center shadow-1 border-300 bg-white absolute top-0 left-0 w-full z-5 h-3rem">
              <IconButton
                className="ml-2 md:hidden"
                onClick={() => setOpen(true)}
              >
                <Menu />
              </IconButton>
              <img
                src={logo}
                alt="Reatswelela travel - Webdesk"
                className="p-2 w-8rem m-2"
              />
              <Content className="ml-auto flex px-3">
                <AppDropdown
                  label={"Notifications"}
                  startIcon={<Notifications className="md:mr-2" />}
                  className={"mr-2"}
                  contents={<NotificationList currentUser={currentUser} />}
                />
                <AppDropdown
                  label={"Settings"}
                  startIcon={<Settings className="mr-2" />}
                  menuItems={buildAuthList}
                />
              </Content>
            </Content>
          ) : (
            <Content className="absolute align-items-center  top-0 left-0 flex shadow-1 bg-white w-full z-5 h-5rem">
              <IconButton
                className="mr-auto md:hidden"
                onClick={() => setOpen(true)}
              >
                <Menu />
              </IconButton>
              <img
                src={logo}
                alt="Reatswelela travel - Webdesk"
                className="p-2 w-8rem mx-auto"
              />
            </Content>
          ))}
        <Content className="grid m-0 max-w-full" style={{ width: "100vw" }}>
          <div ref={sideMenuRef}>
            <SideMenu showToast={showToast} className="col" navLinks={navLinks} onLinksChanged={setNavLinkRows} />
          </div>

          <Content
            className="col px-0 pt-0 flex-1 absolute overflow-auto surface-overlay mt-6"
            style={{
              maxHeight: "99.9vh",
              marginLeft: menuWidth,
              width: `calc(100vw - ${menuWidth}px)`,
            }}
          >
            <ContentColumn className={"overflow-y-scroll w-full  pb-7"}>
              <Outlet />
              {}
              <Footer loading={loading} />
            </ContentColumn>
          </Content>
        </Content>
      </Content>

      {triggerToast?.message && (
        <AppSnackBar
          openCount={triggerToast.counter}
          message={triggerToast.message}
          type={triggerToast.color}
          onClose={hideToast}
        />
      )}

      <Box className="flex border-200 align-items-center">
        <Content className="flex align-items-center">
          <Box className="flex">
            <Drawer
              open={open}
              onClose={() => setOpen(false)}
              slotProps={{ ModalBackdrop: false }}
            >
              <ModalClose />
              <DialogTitle>Menu</DialogTitle>
              <Box
                role="presentation"
                onClick={() => setOpen(false)}
                onKeyDown={() => setOpen(false)}
              >
                <List>
                  {navLinks.map((nav, index) => {
                    const CustomIcon = getIconByName(nav.icon);
                    return (
                      <React.Fragment key={index}>
                        {nav.divider && <ListDivider />}

                        {nav.label && (
                          <ListItem key={index} className="py-2">
                            <Tooltip
                              placement="right"
                              variant="outlined"
                              title={nav.label}
                              enterDelay={1500}
                              enterNextDelay={3000}
                              disableInteractive
                            >
                              <ListItemButton
                                onClick={() =>
                                  hasRight(nav)
                                    ? navigate(nav.path)
                                    : showToast("Permission denied", "danger")
                                }
                              >
                                <CustomIcon />
                                {nav.label}
                              </ListItemButton>
                            </Tooltip>
                          </ListItem>
                        )}
                      </React.Fragment>
                    );
                  })}

{navLinkRows.map((nav, index) => {
                    const CustomIcon = getIconByName(nav.icon);
                    return (
                      <React.Fragment key={index}>
                        {nav.divider && <ListDivider />}

                        {nav.name && (
                          <ListItem key={index} className="py-2">
                            <Tooltip
                            placement="right"
                            variant="outlined"
                              title={nav.name}
                              enterDelay={1500}
                              enterNextDelay={3000}
                              disableInteractive
                            >
                              <ListItemButton
                                onClick={() =>
                                  hasRight(nav)
                                    ? navigate(nav.path)
                                    : showToast("Permission denied", "danger")
                                }
                              >
                                <CustomIcon />
                                {nav.name}
                              </ListItemButton>
                            </Tooltip>
                          </ListItem>
                        )}
                      </React.Fragment>
                    );
                  })}


                </List>
              </Box>
            </Drawer>
          </Box>
        </Content>
      </Box>

      <ChangePasswordModal
        showModel={openChangePassword}
        onDismiss={setOpenChangePassword}
        setToast={showToast}
      />
    </Content>
  );
};

export default Layout;

const Footer = ({ loading }) => {
  return (
    !loading && (
      <Content className="text-xs flex justify-content-center align-items-center mt-auto">
        <p>
          Powered by{" "}
          <b>
            <Link
              target="_blank"
              to="https:///www.nelotec.co.za/"
              className="text-gray-900 no-underline font-semibold text-xs"
            >
              Nelotec (Pty) Ltd
            </Link>
          </b>{" "}
          Copyright &copy; {new Date().getFullYear()} | All rights reserved
        </p>
      </Content>
    )
  );
};


export const Logo = ({ className }) => {
   return (
          <img
                src={logo}
                alt="Reatswelela travel - Webdesk"
                className={`w-8rem ${className}`}
              />
   )
} 
